/*eslint-disable*/
import React from "react";
import { Link } from "react-router-dom";

import withStyles from "@material-ui/core/styles/withStyles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
//import Drawer from '@material-ui/core/Drawer';
import Typography from "@material-ui/core/Typography";

import Divider from "@material-ui/core/Divider";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Icon from "@material-ui/core/Icon";
//import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Grid from "@material-ui/core/Grid";

import CustomDropdown from "components/CustomDropdown/CustomDropdown.jsx";

import headerLinksStyle from "assets/jss/material-kit-react/components/headerLinksStyle.jsx";

function HeaderLinks({ ...props }) {
  const { classes } = props;
  const [state, setState] = React.useState({
    top: false,
  });

  const toggleDrawer = (side, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [side]: open });
  };
  const fullList = (side) => (
    <div
      className={classes.fullList}
      role="presentation"
      onClick={toggleDrawer(side, false)}
      onKeyDown={toggleDrawer(side, false)}
    >
      <Typography variant="h6" color="primary" style={{ marginLeft: "15px" }}>
        Κατηγορία
      </Typography>
      <Divider />
      <Link to="/en/solution/business" className={classes.navLink}>
        <ListItemIcon>
          <Icon className="fa fa-building" />
        </ListItemIcon>
        <ListItemText primary="Επιχείρηση" />
      </Link>
      <Link to="/en/solution/startup" className={classes.navLink}>
        <ListItemIcon>
          <Icon className="fa fa-rocket" />
        </ListItemIcon>
        <ListItemText primary="Start-ups" />
      </Link>
      <Link to="/en/solution/individuals" className={classes.navLink}>
        <ListItemIcon>
          <Icon className="fa fa-home" />
        </ListItemIcon>
        <ListItemText primary="Οικίες - Ιδιώτες" />
      </Link>
      <Link to="/en/solution/callshop" className={classes.navLink}>
        <ListItemIcon>
          <Icon className="fa fa-industry" />
        </ListItemIcon>
        <ListItemText primary="Call Shop" />
      </Link>
      <Link to="/en/solution/cards" className={classes.navLink}>
        <ListItemIcon>
          <Icon className="fa fa-industry" />
        </ListItemIcon>
        <ListItemText primary="ΧΡΟΝΟ ΚΑΡΤΕΣ" />
      </Link>
      <Typography variant="h6" color="primary" style={{ marginLeft: "15px" }}>
        Eιδικές Κατηγορίες
      </Typography>
      <Divider />
      <Link to="/en/solution/shippingcomapnies" className={classes.navLink}>
        <ListItemIcon>
          <Icon className="fa fa-ship" />
        </ListItemIcon>
        <ListItemText primary="Ναυτιλιακές εταιρίες" />
      </Link>
      <Link to="/en/solution/callcenter" className={classes.navLink}>
        <ListItemIcon>
          <Icon className="fa fa-phone-volume" />
        </ListItemIcon>
        <ListItemText primary="Call Center" />
      </Link>
      <Link to="/en/solution/hotels" className={classes.navLink}>
        <ListItemIcon>
          <Icon className="fa fa-bed" />
        </ListItemIcon>
        <ListItemText primary="Ξενοδοχεία" />
      </Link>
      <Link to="/en/solution/taxi" className={classes.navLink}>
        <ListItemIcon>
          <Icon className="fa fa-taxi" />
        </ListItemIcon>
        <ListItemText primary="Ραδιοταξί" />
      </Link>

      <Typography variant="h6" color="primary" style={{ marginLeft: "15px" }}>
        ΣΕΝΑΡΙΟ ΧΡΗΣΗΣ
      </Typography>
      <Divider />
      <GridContainer style={{ margin: "0px" }}>
        <Grid item xs={12} sm={12} lg={3}>
          <Link to="/en/solution/telephonecenter" className={classes.navLink}>
            <ListItemIcon>
              <Icon className="fa fa-industry" />
            </ListItemIcon>
            <ListItemText primary="ΔΕΝ ΕΧΩ ΤΗΛΕΦΩΝΙΚΟ ΚΕΝΤΡΟ" />
          </Link>
        </Grid>
        <Grid item xs={12} sm={12} lg={4}>
          <Link to="/en/solution/remote" className={classes.navLink}>
            <ListItemIcon>
              <Icon className="fa fa-headphones" />
            </ListItemIcon>
            <ListItemText primary="Υποκαταστηματα / Απομακρυσμενη εργασια" />
          </Link>
          <Link to="/en/solution/videoconference" className={classes.navLink}>
            <ListItemIcon>
              <Icon className="fa fa-users" />
            </ListItemIcon>
            <ListItemText primary="Επιχειρησεις με αναγκες τηλεδιασκεψης" />
          </Link>
          <Link to="/en/solution/callnocost" className={classes.navLink}>
            <ListItemIcon>
              <Icon className="fa fa-microphone" />
            </ListItemIcon>
            <ListItemText primary="Καταγραφη κλησεων χωρις κοστος" />
          </Link>
        </Grid>
        <Grid item xs={12} sm={12} lg={3}>
          <Link
            to="/en/solution/internationalbusiness"
            className={classes.navLink}
          >
            <ListItemIcon>
              <Icon className="fa fa-chart-area" />
            </ListItemIcon>
            <ListItemText primary="International Business" />
          </Link>

          <Link to="/en/solution#12" className={classes.navLink}>
            <ListItemIcon>
              <Icon className="fa fa-euro-sign" />
            </ListItemIcon>
            <ListItemText primary="Δεν έχω τηλεφωνικo κεντρο" />
          </Link>
          <Link to="/en/solution/nocallcenter" className={classes.navLink}>
            <ListItemIcon>
              <Icon className="fa fa-window-maximize" />
            </ListItemIcon>
            <ListItemText primary="Υποστηριξη / online πωλησεις" />
          </Link>
          <Link to="/en/solution/pcphones" className={classes.navLink}>
            <ListItemIcon>
              <Icon className="fa fa-database" />
            </ListItemIcon>
            <ListItemText primary="Τηλεφωνια με Η/Υ" />
          </Link>
        </Grid>
      </GridContainer>
    </div>
  );
  return (
    <List className={classes.list}>
      <ListItem className={classes.listItem}>
        <CustomDropdown
          noLiPadding
          buttonText="Services"
          buttonProps={{
            className: classes.navLink,
            color: "transparent",
          }}
          dropdownList={[
            <Link to="/en/retail" className={classes.dropdownLink}>
              For Individuals
            </Link>,
            <Link to="/en/cloudpbx" className={classes.dropdownLink}>
              Cloud PBX
            </Link>,
            <Link to="/en/siptrunk" className={classes.dropdownLink}>
              DID Number & SIP Trunk
            </Link>,
			
          ]}
        />
      </ListItem>
      {/*
      <ListItem className={classes.listItem}>
        <Link onClick={toggleDrawer('top', true)} className={classes.navLink}>
          ΛΥΣΕΙΣ
        </Link>
        <Drawer anchor="top" open={state.top} onClose={toggleDrawer('top', false)}>
          {fullList('top')}
        </Drawer>
      </ListItem>
*/}
	<ListItem className={classes.listItem}>
        <CustomDropdown
          noLiPadding
          buttonText="MOBILE"
          buttonProps={{
            className: classes.navLink,
            color: "transparent",
          }}
          dropdownList={[
            <Link to="/en/travelsim" className={classes.dropdownLink}>
              eSIM
            </Link>,
            <Link to="/en/iotsim" className={classes.dropdownLink}>
              IoT SIM card
            </Link>
          ]}
        />
    </ListItem>
      <ListItem className={classes.listItem}>
        <CustomDropdown
          noLiPadding
          buttonText="Solutions"
          buttonProps={{
            className: classes.navLink,
            color: "transparent",
          }}
          dropdownList={[
            <Link
              to="/en/solution/work_from_home"
              className={classes.dropdownLink}
            >
              Teleworking - Teleconference
            </Link>,
            <Link to="/en/solution/startup" className={classes.dropdownLink}>
              Start Up Business
            </Link>,
			<Link to="/en/solution/call-inmarsat" className={classes.dropdownLink}>
              Shipping Companies
            </Link>,
			<Link to="/en/solution/callcenter" className={classes.dropdownLink}>
              Call Centers
            </Link>,
			<Link to="/en/solution/eshop" className={classes.dropdownLink}>
              Ε-Shops
            </Link>,
			<Link to="/en/solution/hotel" className={classes.dropdownLink}>
              Hotels
            </Link>,
			
          ]}
        />
      </ListItem>
      <ListItem className={classes.listItem}>
      <CustomDropdown
          noLiPadding
          buttonText="Price Catalogs"
          buttonProps={{
            className: classes.navLink,
            color: "transparent",
          }}
          dropdownList={[
            <Link to="/en/rates" className={classes.navLink}>
            Rates 
        </Link> ,
            <Link to="/en/didnumbers" className={classes.dropdownLink}>
             DID Numbers
            </Link>
          ]}
        />
        
      </ListItem>
      <ListItem className={classes.listItem}>
        <CustomDropdown
          noLiPadding
          buttonText="F.A.Q."
          buttonProps={{
            className: classes.navLink,
            color: "transparent",
          }}
          dropdownList={[
            <Link to="/en/faq" className={classes.dropdownLink}>
              For Individuals
            </Link>,
            <Link to="/en/faqoffice" className={classes.dropdownLink}>
              Business - Companies
            </Link>
          ]}
        />
      </ListItem>
      <ListItem className={classes.listItem}>
        <Link to="/en/contact" className={classes.navLink}>
          Contact Us
        </Link>
      </ListItem>
      <ListItem className={classes.listItem}>
        <CustomDropdown
          noLiPadding
          buttonText="MY ACCOUNT"
          buttonProps={{
            className: classes.navLink,
            color: "transparent",
          }}
          dropdownList={[
            <Link
              to=""
              className={classes.dropdownLink}
              onClick={() => {window.location.href = "https://myaccount.hellasfon.com/HF/"}}
            >
              For Individuals
            </Link>,
            <Link
              to=""
              onClick={() => {window.location.href = "https://myaccount.hellasfon.com/vuc4/"}}
              className={classes.dropdownLink}
            >
              Cloud PBX
            </Link>,
            <Link
              to=""
              onClick={() => {window.location.href = "https://myaccount.hellasfon.com/HF/"}}
              className={classes.dropdownLink}
            >
              DID Numbers & SIP Trunk
            </Link>,
			<Link
              to=""
              onClick={() => {window.location.href = "https://myaccount.easyclicksms.com/"}}
              className={classes.dropdownLink}
            >
              Send Bulk SMS
            </Link>,
          ]}
        />
      </ListItem>
    </List>
  );
}

export default withStyles(headerLinksStyle)(HeaderLinks);
