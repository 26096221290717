import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import withStyles from "@material-ui/core/styles/withStyles";
import { TinyButton as ScrollUpButton } from "react-scroll-up-button";
import Header from "components/Header/Header.jsx";
import Parallax from "components/Parallax/Parallax.jsx";
import Footer from "components/Footer/Footer.jsx";
import HeaderLinks from "components/Header/HeaderLinks.jsx";
import HeaderTopLinks from "components/Header/HeaderTopLinks.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import Button from "components/CustomButtons/Button.jsx";
import landingPageStyle from "assets/jss/material-kit-react/views/landingPage.jsx";
import AppSection from "./Sections/AppSection.jsx";
import VisaSection from "./Sections/VisaSection.jsx";
import CookieConsent from "react-cookie-consent";
import TuneIcon from "@material-ui/icons/Tune";

import DialerSipIcon from '@material-ui/icons/DialerSip';
import PhoneAndroidIcon from '@material-ui/icons/PhoneAndroid';
import VoicemailIcon from '@material-ui/icons/Voicemail';
import ContactsIcon from '@material-ui/icons/Contacts';
import PanToolIcon from '@material-ui/icons/PanTool';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import InsertCommentIcon from '@material-ui/icons/InsertComment';
import CameraFrontIcon from '@material-ui/icons/CameraFront';
import BuildIcon from '@material-ui/icons/Build';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import PeopleIcon from '@material-ui/icons/People';
import BrightnessAutoIcon from '@material-ui/icons/BrightnessAuto';
import PublicIcon from '@material-ui/icons/Public';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';
import SettingsPhoneIcon from '@material-ui/icons/SettingsPhone';
import SettingsVoiceIcon from '@material-ui/icons/SettingsVoice';
import FaceIcon from '@material-ui/icons/Face';
//import LanguageIcon from '@material-ui/icons/Language';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import AllInclusiveIcon from '@material-ui/icons/AllInclusive';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import LibraryMusicIcon from '@material-ui/icons/LibraryMusic';
import MarkunreadMailboxIcon from '@material-ui/icons/MarkunreadMailbox';
import HomeWorkIcon from '@material-ui/icons/HomeWork';
import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver';

import CardContent from "@material-ui/core/CardContent";


class Features extends React.Component {
  constructor(props) {
    window.scrollTo(0, 0);
    super(props);
  }
  render() {
    const { classes, ...rest } = this.props;
    return (
      <div>
        <Header
          topLinks={<HeaderTopLinks />}
          rightLinks={<HeaderLinks />}
          fixed
          logoUrl="/img/hellasfon_logo_white.jpg"
          color="primary"
          changeColorOnScroll={{
            height: 50,
            color: "white"
          }}
          {...rest}
        />
        <Parallax color small />
        <div className={classNames(classes.main, classes.mainRaised)}>
          <h2
            style={{
              textAlign: "center",
              backgroundColor: "#005FB4",
              color: "white"
            }}
          >
            {"Features of EVCOFFICE PBX"}
          </h2>
		  
		  <div className={classes.container}>
            <GridContainer justify="center">
              <GridItem cs={12} sm={12} md={4}> 
                <Card style={{ height: 330 }}> 
                  <CardContent style={{ textAlign: "center" }}>
                    <FaceIcon
                      style={{ color: "#005FB4", fontSize: "65px" }}
                    />
                    <Typography variant="h5" style={{ color: "#005FB4" }}>
                      User Friendly
   				  </Typography>
					
                    <br />
                    <Typography variant="body1" color="textSecondary">
                      Call Management with Online Configuration.
                      <br />
                      <br />
                      With the push of a button set everything for your PBX in a beautiful & graphical environment. Easy!
					  </Typography>
                  </CardContent>
                </Card>
              </GridItem>
              <GridItem cs={12} sm={12} md={4}>
                <Card style={{ height: 330 }}>
                  <CardContent style={{ textAlign: "center" }}>
                    <AllInclusiveIcon
                      style={{ color: "#07a8b3", fontSize: "65px" }}
                    />
                    <Typography variant="h5" style={{ color: "#0c696d" }}>
                      Unlimited IVR Scenarios
                    </Typography>
                    
				    <Button href="/faqoffice" color="primary">
					    READ MORE
					 </Button> 
					<br />
                    <Typography variant="body1" color="textSecondary">
                      Setup your PBX with multi level menu options that your customers will hear.
                      <br />
                      <br />
                      According to TIME, Users GROUP, or single USER according your company needs.
                      <br />
                      <br />
                    </Typography>
                  </CardContent>
                </Card>
              </GridItem>
              <GridItem cs={12} sm={12} md={4}>
                <Card style={{ height: 330 }}>
                  <CardContent style={{ textAlign: "center" }}>
                    <AccountTreeIcon
                      style={{ color: "#005FB4", fontSize: "65px" }}
                    />
                    <Typography variant="h5" style={{ color: "#005FB4" }}>
                      Multiple Branches
				                     
                    </Typography>
						<br />
                    
                      <Typography variant="body1" color="textSecondary">
                        Set multiple branches or locations. Call your colleagues to other branches using different prefixes.
                        <br />
						<br />
                        Connecting Branches is like a breeze!
                        
                      </Typography>
                    
                  </CardContent>
                </Card>
              </GridItem>
            </GridContainer>
          </div>
		  
		   <div className={classes.container}>
            <GridContainer justify="center">
              <GridItem cs={12} sm={12} md={4}>
                <Card style={{ height: 300 }}>
                  <CardContent style={{ textAlign: "center" }}>
                    <DialerSipIcon
                      style={{ color: "#07a8b3", fontSize: "65px" }}
                    />
                    <Typography variant="h5" style={{ color: "#0c696d" }}>
                      Support ALL IP Phones
                    </Typography>
                    <br />
                    <Typography variant="body1" color="textSecondary">
                      We support ALL phones from all vendors.
                      <br />
                      <br />
                      Grandstream,Yealink,Snom,Fanvil etc.
                    </Typography>
                  </CardContent>
                </Card>
              </GridItem>
              <GridItem cs={12} sm={12} md={4}>
                <Card style={{ height: 300 }}>
                  <CardContent style={{ textAlign: "center" }}>
                    <PhoneAndroidIcon
                      style={{ color: "#005FB4", fontSize: "65px" }}
                    />
                    <Typography variant="h5" style={{ color: "#005FB4" }}>
                      Mobile Unified  
                    </Typography>
                    <br />
                    <Typography variant="body1" color="textSecondary">
                      EVCOFFICE is a unified application for your smartphone (Android or IOS)
                      <br />
                      <br />
                      Take your office extension with you wherever you are!
                      <br />
                      <br />
                    </Typography>
                  </CardContent>
                </Card>
              </GridItem>
              <GridItem cs={12} sm={12} md={4}>
                <Card style={{ height: 300 }}>
                  <CardContent style={{ textAlign: "center" }}>
                    <VoicemailIcon
                      style={{ color: "#07a8b3", fontSize: "65px" }}
                    />
                    <Typography variant="h5" style={{ color: "#0c696d" }}>
                      Voicemail 
                    </Typography>
                    <br />
                
                    <Typography variant="body1" color="textSecondary">
                        Listen your voicemail messages from any extension, no matter where you are.
						<br />
                      <br />
                      Just enter your special calling number and your personal pin code.
                        
                      </Typography>
                    
                  </CardContent>
                </Card>
              </GridItem>
            </GridContainer>
          </div>
		  
		  
		   <div className={classes.container}>
            <GridContainer justify="center">
              <GridItem cs={12} sm={12} md={4}>
                <Card style={{ height: 300 }}>
                  <CardContent style={{ textAlign: "center" }}>
                    <AccountBoxIcon
                      style={{ color: "#005FB4", fontSize: "65px" }}
                    />
                    <Typography variant="h5" style={{ color: "#005FB4" }}>
                      Easy Central Management 
                    </Typography>
                    <br />
                    <Typography variant="body1" color="textSecondary">
                      Manage your PBX easily with your administrative user.
                      <br />
                      <br />
                      Add users / lines & setup all PBX parameters to change the way it works. Easy-to-use simple interface.
                    </Typography>
                  </CardContent>
                </Card>
              </GridItem>
              <GridItem cs={12} sm={12} md={4}>
                <Card style={{ height: 300 }}>
                  <CardContent style={{ textAlign: "center" }}>
                    <TuneIcon
                      style={{ color: "#07a8b3", fontSize: "65px" }}
                    />
                    <Typography variant="h5" style={{ color: "#0c696d" }}>
                      Collaboration Channels
                    </Typography>
                    <br />
                    <Typography variant="body1" color="textSecondary">
                      
                      <br />
                      Chat with up to 100 participants!
					  
                      <br />
                      <br />
					  All Chat conversations take place in a secure environment.
                    </Typography>
                  </CardContent>
                </Card>
              </GridItem>
              <GridItem cs={12} sm={12} md={4}>
                <Card style={{ height: 300 }}>
                  <CardContent style={{ textAlign: "center" }}>
                    <QuestionAnswerIcon
                      style={{ color: "#005FB4", fontSize: "65px" }}
                    />
                    <Typography variant="h5" style={{ color: "#005FB4" }}>
                      Messenger
				                     
                    </Typography>
						<br />
                    
                      <Typography variant="body1" color="textSecondary">
                        Free video calls with other PBX Users.
                        <br />
						<br />
                        Exchange Documents, Photos, Locations and Contacts.
                        
                      </Typography>
                    
                  </CardContent>
                </Card>
              </GridItem>
            </GridContainer>
          </div>
		  
		  
		   <div className={classes.container}>
            <GridContainer justify="center">
              <GridItem cs={12} sm={12} md={4}>
                <Card style={{ height: 300 }}>
                  <CardContent style={{ textAlign: "center" }}>
                    <ContactsIcon
                      style={{ color: "#07a8b3", fontSize: "65px" }}
                    />
                    <Typography variant="h5" style={{ color: "#0c696d" }}>
                      Mobile Contacts Sync 
                    </Typography>
                    <br />
                    <Typography variant="body1" color="textSecondary">
                      Syncronize your mobile contacts with your PBX

					  
                      <br />
                      <br />
                      There is no need , to type your phonebook again.
                    </Typography>
                  </CardContent>
                </Card>
              </GridItem>
              <GridItem cs={12} sm={12} md={4}>
                <Card style={{ height: 300 }}>
                  <CardContent style={{ textAlign: "center" }}>
                    <CameraFrontIcon
                      style={{ color: "#005FB4", fontSize: "65px" }}
                    />
                    <Typography variant="h5" style={{ color: "#005FB4" }}>
                      Caller ID
                    </Typography>
                    <br />
                    <Typography variant="body1" color="textSecondary">
                      Set up easily the number to display on your outgoing calls.
                      <br />
                      <br />
                      It can be your mobile number, a phone number you already have or the company's main number.
                      <br />
                      <br />
                    </Typography>
                  </CardContent>
                </Card>
              </GridItem>
              <GridItem cs={12} sm={12} md={4}>
                <Card style={{ height: 300 }}>
                  <CardContent style={{ textAlign: "center" }}>
                    <PanToolIcon
                      style={{ color: "#07a8b3", fontSize: "65px" }}
                    />
                    <Typography variant="h5" style={{ color: "#0c696d" }}>
                      Answering Rules
                    </Typography>
                    <br />
                      <Typography variant="body1" color="textSecondary">
                        Features : Follow me & Do not Distrurb
                          <br />
						  <br />
						  Transfer your calls to your voicemail or send your calls to an other colleague who is available.
                      </Typography>
                   
                  </CardContent>
                </Card>
              </GridItem>
            </GridContainer>
          </div>
		  
		  
		  		   <div className={classes.container}>
            <GridContainer justify="center">
              <GridItem cs={12} sm={12} md={4}>
                <Card style={{ height: 300 }}>
                  <CardContent style={{ textAlign: "center" }}>
                    <QueryBuilderIcon
                      style={{ color: "#005FB4", fontSize: "65px" }}
                    />
                    <Typography variant="h5" style={{ color: "#005FB4" }}>
                      Time Conditions
                    </Typography>
                    <br />
                    <Typography variant="body1" color="textSecondary">
                      Set up how your incoming calls will be routed at specific times or days.
                      <br />
                      <br />
                      e.g. After 17.00 that company is closed all calls should be routed to voicemail.
                    </Typography>
                  </CardContent>
                </Card>
              </GridItem>
              <GridItem cs={12} sm={12} md={4}>
                <Card style={{ height: 300 }}>
                  <CardContent style={{ textAlign: "center" }}>
                    <BuildIcon
                      style={{ color: "#07a8b3", fontSize: "65px" }}
                    />
                    <Typography variant="h5" style={{ color: "#0c696d" }}>
                      Provisioning
                    </Typography>
                    <br />
                    <Typography variant="body1" color="textSecondary">
                      Set up all your IP Phone devices directly through PBX Administration Portal.
                      <br />
                      <br />
                      Set softkeys, speedials or other pbx extensions on the same device.
                      <br />
                      <br />
                    </Typography>
                  </CardContent>
                </Card>
              </GridItem>
              <GridItem cs={12} sm={12} md={4}>
                <Card style={{ height: 300 }}>
                  <CardContent style={{ textAlign: "center" }}>
                    <InsertCommentIcon
                      style={{ color: "#005FB4", fontSize: "65px" }}
                    />
                    <Typography variant="h5" style={{ color: "#005FB4" }}>
                      Greetings
                    </Typography>
                    <br />
                    
                      <Typography variant="body1" color="textSecondary">
                       Record your company Greetings or upload the audio file that will be heard by your customers.					   .
                         <br />
                      <br />
                      There is no limit on how many Greetings audio messages you will have!
                      <br />
                      <br />
                      </Typography>
                    
                  </CardContent>
                </Card>
              </GridItem>
            </GridContainer>
          </div>
		  
		  
		  
		  		   <div className={classes.container}>
            <GridContainer justify="center">
              <GridItem cs={12} sm={12} md={4}>
                <Card style={{ height: 300 }}>
                  <CardContent style={{ textAlign: "center" }}>
                    <PeopleIcon
                      style={{ color: "#07a8b3", fontSize: "65px" }}
                    />
                    <Typography variant="h5" style={{ color: "#0c696d" }}>
                      User Groups 
                    </Typography>
                    <br />
                    <Typography variant="body1" color="textSecondary">
                      Send your calls to a group of users. Simultaneously or Sequentially.
                      <br />
                      <br />
                      E.g. Customer Service Team, Sales, Technical Support, etc.
                    </Typography>
                  </CardContent>
                </Card>
              </GridItem>
              <GridItem cs={12} sm={12} md={4}>
                <Card style={{ height: 300 }}>
                  <CardContent style={{ textAlign: "center" }}>
                    <BrightnessAutoIcon
                      style={{ color: "#005FB4", fontSize: "65px" }}
                    />
                    <Typography variant="h5" style={{ color: "#005FB4" }}>
                      Automatic Attendant
                    </Typography>
                    <br />
                    <Typography variant="body1" color="textSecondary">
                      Create unlimited Auto Attendants for your PBX.
                      <br />
                      <br />
                      Handle your calls professionally and flexibly.
					  Create as many scenarios as you wish!
					  
                      <br />
                      <br />
                    </Typography>
                  </CardContent>
                </Card>
              </GridItem>
              <GridItem cs={12} sm={12} md={4}>
                <Card style={{ height: 300 }}>
                  <CardContent style={{ textAlign: "center" }}>
                    <SettingsVoiceIcon
                      style={{ color: "#07a8b3", fontSize: "65px" }}
                    />
                    <Typography variant="h5" style={{ color: "#0c696d" }}>
                      Voice Recording
                    </Typography>
                    
                    <br />
                      <Typography variant="body1" color="textSecondary">
                        Record incoming or outgoing calls from and to your customers.
						<br />
                        <br />
						Listen to them on the web or download the mp3 audio file.
                        
                      </Typography>
                    
                  </CardContent>
                </Card>
              </GridItem>
            </GridContainer>
          </div>
		  
		  <div className={classes.container}>
            <GridContainer justify="center">
              <GridItem cs={12} sm={12} md={4}>
                <Card style={{ height: 300 }}>
                  <CardContent style={{ textAlign: "center" }}>
                    <PublicIcon
                      style={{ color: "#005FB4", fontSize: "65px" }}
                    />
                    <Typography variant="h5" style={{ color: "#005FB4" }}>
                      Web RTC 
                    </Typography>
                    <br />
                    <Typography variant="body1" color="textSecondary">
                      Make calls from your PC using the built-in phone included in every PBX account.
					  
                      <br />
                      <br />
                      All you need are headphones and a microphone.
                    </Typography>
                  </CardContent>
                </Card>
              </GridItem>
              <GridItem cs={12} sm={12} md={4}>
                <Card style={{ height: 300 }}>
                  <CardContent style={{ textAlign: "center" }}>
                    <DoneAllIcon
                      style={{ color: "#07a8b3", fontSize: "65px" }}
                    />
                    <Typography variant="h5" style={{ color: "#0c696d" }}>
                      Multi Use
                    </Typography>
                    <br />
                    <Typography variant="body1" color="textSecondary">
                      Now your calls are never lost. You receive them on your desktop IP phone device, on your mobile or your PC.
                      <br />
                      <br />
                      All calls and chats are automatically synced across ALL your devices.
                      <br />
                      <br />
                    </Typography>
                  </CardContent>
                </Card>
              </GridItem>
              <GridItem cs={12} sm={12} md={4}>
                <Card style={{ height: 300 }}>
                  <CardContent style={{ textAlign: "center" }}>
                    <SettingsPhoneIcon
                      style={{ color: "#005FB4", fontSize: "65px" }}
                    />
                    <Typography variant="h5" style={{ color: "#005FB4" }}>
                      Phone Numbers
                    </Typography>
                    <br />
                      <Typography variant="body1" color="textSecondary">
                        With every EVCOFFICE plan we provide you <b>FREE</b> Greek Phone Number.
                          <br />
						  <br />
                          If you want an additional Greek number, it cost only €2/month.
                      </Typography>
                   
                  </CardContent>
                </Card>
              </GridItem>
            </GridContainer>
          </div>
		  
		  <div className={classes.container}>
            <GridContainer justify="center">
              <GridItem cs={12} sm={12} md={4}>
                <Card style={{ height: 300 }}>
                  <CardContent style={{ textAlign: "center" }}>
                    <GroupAddIcon
                      style={{ color: "#07a8b3", fontSize: "65px" }}
                    />
                    <Typography variant="h5" style={{ color: "#0c696d" }}>
                      Conference Rooms 
                    </Typography>
                    <br />
                    <Typography variant="body1" color="textSecondary">
                      Easily organize phone meetings with your colleagues by creating Conference Rooms.
                      <br />
                      <br />
                      Increase company productivity without having to travel or leave your office.
                    </Typography>
                  </CardContent>
                </Card>
              </GridItem>
              <GridItem cs={12} sm={12} md={4}>
                <Card style={{ height: 300 }}>
                  <CardContent style={{ textAlign: "center" }}>
                    <InsertDriveFileIcon
                      style={{ color: "#005FB4", fontSize: "65px" }}
                    />
                    <Typography variant="h5" style={{ color: "#005FB4" }}>
                      Fax 
                    </Typography>
                    <br />
                    <Typography variant="body1" color="textSecondary">
                      Send and receive faxes using MY ACCOUNT Customer Portal.
                      <br />
                      <br />
                      We also support the following services : email-to-fax and fax-to-email.
                      <br />
                      <br />
                    </Typography>
                  </CardContent>
                </Card>
              </GridItem>
              <GridItem cs={12} sm={12} md={4}>
                <Card style={{ height: 300 }}>
                  <CardContent style={{ textAlign: "center" }}>
                    <LibraryMusicIcon
                      style={{ color: "#07a8b3", fontSize: "65px" }}
                    />
                    <Typography variant="h5" style={{ color: "#0c696d" }}>
                      Custom Music on Hold
                    </Typography>
                    
                    <br />
                      <Typography variant="body1" color="textSecondary">
                        Upload your favorite music that your customers will listen to while waiting for an answer.
						<br />
                        <br />
						The music on hold can be set individually and for each user!
                        
                      </Typography>
                    
                  </CardContent>
                </Card>
              </GridItem>
            </GridContainer>
          </div>
		  
		  <div className={classes.container}>
            <GridContainer justify="center">
              <GridItem cs={12} sm={12} md={4}>
                <Card style={{ height: 330 }}>
                  <CardContent style={{ textAlign: "center" }}>
                    <HomeWorkIcon
                      style={{ color: "#005FB4", fontSize: "65px" }}
                    />
															
                    <Typography variant="h5" style={{ color: "#005FB4" }}>
                      Work from Home 
                    </Typography>
                    <br />
                    <Typography variant="body1" color="textSecondary">
                      Receive & Make calls using your extension number wherever you are.
					  
                      <br />
                      <br />
                      Tele working is really easy!
                    </Typography>
                  </CardContent>
                </Card>
              </GridItem>
              <GridItem cs={12} sm={12} md={4}>
                <Card style={{ height: 330 }}>
                  <CardContent style={{ textAlign: "center" }}>
                    <MarkunreadMailboxIcon
                      style={{ color: "#07a8b3", fontSize: "65px" }}
                    />
                    <Typography variant="h5" style={{ color: "#0c696d" }}>
                      Voicemail to Email
                    </Typography>
                    <br />
                    <Typography variant="body1" color="textSecondary">
                      In addition to the voicemail feature for each of your PBX extensions, with this feature you will receive all voicemail messages left for you in your email.
                      <br />
                      <br />
                      You will receive an email with an mp3 audio file.
                      <br />
                      <br />
                    </Typography>
                  </CardContent>
                </Card>
              </GridItem>
              <GridItem cs={12} sm={12} md={4}>
                <Card style={{ height: 330 }}>
                  <CardContent style={{ textAlign: "center" }}>
                    <RecordVoiceOverIcon
                      style={{ color: "#005FB4", fontSize: "65px" }}
                    />
                    <Typography variant="h5" style={{ color: "#005FB4" }}>
                      TTS (Text to Speech)
                    </Typography>
                    <br />
                      <Typography variant="body1" color="textSecondary">
                        We provide you with TTS (Text to Speech) feature in several languages.Create professional Greeting messages.
                          <br />
						  <br />
                          If your business deals with overseas customers, you will find this feature really useful!
                      </Typography>
                   
                  </CardContent>
                </Card>
              </GridItem>
            </GridContainer>
          </div>
		  
		  
          <div className={classes.container}>
		  <div class="container">
				
		</div>
          
		  <GridItem cs={12} sm={12} md={12} style={{ padding: 0 }}>
              <h2 style={{ color: "#005FB4" }}> </h2>
              <Typography variant="body1" align="center" style={{ color: "#005FB4" }}>
                
				<br></br>
				<strong>Hellasfon now offers you FREE SETUP & PROGRAMMING of the EVCOFFICE PBX you choose! You simply inform us of the usage scenario you desire and we will program it based on your needs providing:
				<p></p>
				<li>HD Sound Quality</li>
				<li>Lower call rates without intermediaries!</li></strong>
				<br /><p></p> 
				
				<Button
					color="primary"
					size="lg"
					href="../cloudpbx#pricing"
					
				>
				View all EVCOFFICE PBX plans
				</Button>
              </Typography>
			  
            </GridItem>
          </div>
          <AppSection />
          <VisaSection />
          <Footer />
        </div>
        <ScrollUpButton />
        <CookieConsent
            location="bottom"
            buttonText="ΟΚ συμφωνώ"
            cookieName="hellasfonCookie"
            style={{ background: "rgb(1, 120, 226)" }}
            buttonStyle={{ background: "#f50057", color: "white", fontSize: "15px", marginRight: "130px" }}
            expires={150}
        >
            Χρησιμοποιούμε cookies σε αυτόν τον ιστότοπο για να βελτιώσουμε την εμπειρία των χρηστών.{" "}
            <span style={{ fontSize: "12px" }}>
            Κάνοντας κλικ σε οποιονδήποτε σύνδεσμο σε αυτή τη σελίδα δίνετε τη συγκατάθεσή σας για να ορίσουμε cookies.{" "}
            <a style={{color: "white", textDecoration: "underline"}} href="/privacy">Περισσότερες πληροφορίες</a>
            </span>
            
        </CookieConsent>
      </div>
    );
  }
}

Features.propTypes = {
  classes: PropTypes.object
};

export default withStyles(landingPageStyle)(Features);
