import { primaryColor } from "assets/jss/material-kit-react.jsx";

const pricingStyle = {
  content: {
    display: 'flex', 
    justifyContent: 'center', 
    alignItems: 'baseline',
  },
  header : {
    backgroundColor: primaryColor,
    color: 'white'
  },
  headerPlans : {
    color: 'black'
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto'
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  }
  };
  export default pricingStyle;