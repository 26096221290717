import React from "react";
import PropTypes from "prop-types";
import CardMedia from '@material-ui/core/CardMedia';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import withStyles from "@material-ui/core/styles/withStyles";

import landingPageStyle from "assets/jss/material-kit-react/views/landingPage.jsx";


class VisaSection extends React.Component {
  render() {
    const { classes } = this.props;

    return (
      <div className={classes.section}>
        <div className="body" style={{color:"white"}}>
          <GridContainer justify="center" style={{marginLeft:"15px", marginRight:"15px", marginTop: "0px"}}>
            <GridItem cs={12} sm={8} md={5}>
              <CardMedia
                component="img"
                alt="Hellasfon Κινητή, Σταθερή Τηλεφωνία. Ολοκληρωμένες Υπηρεσίες Τηλεπικοινωνιών"
                image={require('../../assets/img/alphabank_payment_ecommerce.png')}
                title="Hellasfon Κινητή, Σταθερή Τηλεφωνία. Ολοκληρωμένες Υπηρεσίες Τηλεπικοινωνιών"
              />
            </GridItem>
          </GridContainer>
        </div>
      </div>
    );
  }
}

VisaSection.propTypes = {
  classes: PropTypes.object
};

export default withStyles(landingPageStyle)(VisaSection);
