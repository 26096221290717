import React from "react";
import Typography from "@material-ui/core/Typography";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Grid from "@material-ui/core/Grid";
import Button from "components/CustomButtons/Button.jsx";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import withStyles from "@material-ui/core/styles/withStyles";
import Divider from "@material-ui/core/Divider";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CircularProgress from "@material-ui/core/CircularProgress";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import CustomInput from "components/CustomInput/CustomInput.jsx";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import { TextField } from "@material-ui/core";
//import scrollIntoView from "scroll-into-view-if-needed";

import workStyle from "assets/jss/material-kit-react/views/landingPageSections/workStyle.jsx";

import { connect } from "react-redux";
import { createOrderPBX, updateDirectOrderPBX } from "actions/pbxActions";

//import axios from "axios";

class Review extends React.Component {
  constructor(props) {
    window.scrollTo(0, 0);
    super(props);
    const {
      local_areas,
      classes,
      pbx_plan_pack,
      plans,
      email,
      phonemobile,
      address,
      tk,
      city,
      local_area,
    } = props;

    const did = local_areas.filter((local) => local.id === local_area);

    const plan_product = plans.map((plan) => ({
      id: plan.id_plan,
      name: plan.name,
      quantity: 0,
      desc:
        "Αρχικό κόστος: " +
        plan.startup_cost +
        " Μηνιαίο κόστος: " +
        plan.startup_cost * 0,
      price: parseFloat(plan.startup_cost * 0).toFixed(2),
      cost: parseFloat(plan.startup_cost).toFixed(2),
    }));

    const product_did = [
      {
        id: 3,
        name: "Τηλεφωνικό νούμερο: Greece",
        quantity: 1,
        desc:
          "Αρχικό κόστος: " +
          parseFloat(did[0].setup_fee * 0).toFixed(2) +
          " Μηνιαίο κόστος: " +
          parseFloat(did[0].monthly_fee * 0).toFixed(2),
        price: parseFloat(did[0].setup_fee * 0).toFixed(2),
      },
    ];

    const product_free = [
      {
        id: 1,
        name: pbx_plan_pack.name + " άδειες: " + pbx_plan_pack.users,
        quantity: 1,
        desc: " Μηνιαίο κόστος: " + pbx_plan_pack.startup_cost,
        price: parseFloat(pbx_plan_pack.startup_cost).toFixed(2),
      },
    ];

    const product = [
      {
        id: 1,
        name: pbx_plan_pack.name + " άδειες: " + pbx_plan_pack.users,
        quantity: 1,
        desc:  "Αρχικό κόστος: " +
        parseFloat(pbx_plan_pack.startup_cost).toFixed(2) + " Μηνιαίο κόστος: " + pbx_plan_pack.startup_cost,
        price: parseFloat(pbx_plan_pack.startup_cost * 2).toFixed(2),
      },
      {
        id: 2,
        name: "επιπλέον χρήστες",
        quantity: 0,
        desc:
          " Μηνιαίο κόστος: " +
          parseFloat(pbx_plan_pack.user_cost * 0).toFixed(2),
        price: parseFloat(pbx_plan_pack.user_cost * 0).toFixed(2),
      },
    ];

    this.state = {
      affiliate: "",
      submited: false,
      created: false,
      local_area: 1,
      classSnackbar: classes.hideSnackbar,
      classSnackbarPay: classes.hideSnackbar,
      response: "",
      response_payment: "",
      value_radio: "alphabank",
      loading_order: false,
      marchantEnable: true,
      paymentID: "",
      paymentToken: "",
      plans: plans
        ? plans.map((plan) => ({ id: plan.id_plan, quantity: 0 }))
        : [],
      users: 0,
      errors_plans: [],
      errors: {
        users: false,
      },
      products:
        pbx_plan_pack.id_plan_pack !== 128
          ? product.concat(product_did).concat(plan_product)
          : product_free,
      addresses: [address, city, tk, email, phonemobile],
    };
  }
  onChangePlan = async (e) => {
    e.persist();
    if (
      e.target.value === "" ||
      e.target.value > 200 ||
      e.target.value < 0 ||
      !e.target.value.match(/^[0-9\b]+$/)
    ) {
      let newErrors = this.state.errors_plans.concat({ id: e.target.id });
      this.setState({ errors_plans: newErrors });
    } else {
      let newState = Object.assign({}, this.state);
      for (let i = 0; i < this.state.plans.length; i++) {
        if (this.state.plans[i].id.toString() === e.target.id) {
          newState.plans[i] = {
            id: this.state.plans[i].id,
            quantity: parseInt(e.target.value),
          };
        }
      }
      let newErrors = this.state.errors_plans.filter(
        (error) => error.id !== e.target.id
      );
      this.setState({ plans: newState.plans, errors_plans: newErrors });
      for (let i = 0; i < this.state.products.length; i++) {
        if (this.state.products[i].id.toString() === e.target.id) {
          newState.products[i] = {
            id: this.state.products[i].id,
            name: this.state.products[i].name,
            quantity: parseInt(e.target.value),
            desc:
              "Αρχικό κόστος: " +
              parseFloat(this.state.products[i].cost).toFixed(2) +
              " Μηνιαίο κόστος: " +
              (
                parseFloat(this.state.products[i].cost) *
                parseFloat(e.target.value)
              ).toFixed(2),
            price: (
              parseFloat(this.state.products[i].cost) *
              parseFloat(e.target.value)
            ).toFixed(2),
            cost: this.state.products[i].cost,
          };
        }
      }
      this.setState({ products: newState.products });
    }
  };
  onChangeUsers = async (e) => {
    e.persist();
    const { pbx_plan_pack } = this.props;
    await this.setState(
      (e.target.value === "" && e.target.id === "users") ||
        (e.target.id === "users" && e.target.value > 200) ||
        (e.target.id === "users" && e.target.value < 0) ||
        (e.target.id === "users" && !e.target.value.match(/^[0-9\b]+$/))
        ? {}
        : {
            [e.target.id]: e.target.value,
            errors: {
              [e.target.id]: false,
            },
          }
    );
    if (
      (e.target.value === "" && e.target.id === "users") ||
      (e.target.id === "users" && e.target.value > 200) ||
      (e.target.id === "users" && e.target.value < 0) ||
      (e.target.id === "users" && !e.target.value.match(/^[0-9\b]+$/))
    ) {
      return;
    } else {
      let newState = Object.assign({}, this.state);
      newState.products[1] = {
        id: 2,
        name: "επιπλέον χρήστες",
        desc:
          " Μηνιαίο κόστος: " +
          parseFloat(pbx_plan_pack.user_cost * e.target.value).toFixed(2),
        price: parseFloat(pbx_plan_pack.user_cost * e.target.value).toFixed(2),
      };
      this.setState(newState);
    }
  };
  onChange = async (e) => {
    const { local_areas } = this.props;
    await this.setState(
      e.target.name === "local_area"
        ? {
            [e.target.name]: e.target.value,
          }
        : null
    );
    let did = local_areas.filter((local) => local.id === e.target.value);
    let newState = Object.assign({}, this.state);
    newState.products[2] = {
      id: 3,
      name: "Τηλεφωνικό νούμερο: Greece",
      quantity: 1,
      desc:
        "Αρχικό κόστος: " +
        parseFloat(did[0].setup_fee * 0).toFixed(2) +
        " Μηνιαίο κόστος: " +
        parseFloat(did[0].monthly_fee * 0).toFixed(2),
      price: parseFloat(did[0].setup_fee * 0).toFixed(2),
    };
    this.setState(newState);
  };

  handleChange = (e) => {
    this.setState(
      e.target.id === "affiliate" && e.target.value.length < 3
        ? {
            [e.target.id]: e.target.value,
            errors: {
              [e.target.id]: true,
            },
          }
        : {
            [e.target.id]: e.target.value,
            errors: {
              [e.target.id]: false,
            },
          }
    );
  };
  handleRadioChange = (e) => {
    this.setState({ value_radio: e.target.value });
  };
  onClick = async (e) => {
    e.preventDefault();

    const {
      company,
      vat,
      firstName,
      lastName,
      email,
      phonemobile,
      phone,
      address,
      tk,
      accept,
      city,
      aoy,
    } = this.props;

    this.props.handleBack(
      company,
      vat,
      firstName,
      lastName,
      email,
      phonemobile,
      phone,
      address,
      tk,
      accept,
      city,
      aoy
    );
  };
  submitOrder = async (e) => {
    const {
      company,
      firstName,
      lastName,
      email,
      local_areas,
      pbx_plan_pack,
      verify_mobile,
      classes,
    } = this.props;

    const {
      plans,
      users,
      local_area,
      value_radio,
      products,
      affiliate,
    } = this.state;

    let total = 0;
    for (let num of products) {
      total = total + parseFloat(num.price);
    }
   /* if (affiliate.length < 3 || affiliate.length > 100) {
      this.setState({
        errors: { affiliate: true },
        classSnackbar: classes.errorSnackbar,
        response: "λάθος κωδικός συνεργάτη",
      });
      const node = document.getElementById("affiliate");
      scrollIntoView(node, { behavior: "smooth", scrollMode: "if-needed" });
      return;
    }
    */

    const did = local_areas.filter((local) => local.id === local_area);

    const didList = [
      {
        isFree: true,
        countryId: "1",
        countryCode: "GR",
        areaName: did[0].name,
        areaCode: did[0].area_code,
        localAreaId: local_area,
        voxboneGroupId: "-1",
      },
    ];
    const password = Math.random()
      .toString(36)
      .substr(2, 10);
    let content = {};
    if (pbx_plan_pack.id_plan_pack !== 128) {
      content = {
        subscription: true,
        company: company,
        email: email,
        name: firstName,
        lastName: lastName,
        password: password,
        planPackId: pbx_plan_pack.id_plan_pack,
        quantity: users,
        dids: didList,
        plans: plans,
        referralCode: affiliate,
      };
    } else {
      content = {
        company: company,
        dids: [],
        email: email,
        lastName: lastName,
        name: firstName,
        password: password,
        planPackId: pbx_plan_pack.id_plan_pack,
        quantity: 0,
        plans: [],
        subscription: true,
        referralCode: affiliate,
      };
    }
    if (!this.state.loading_order) {
      this.setState({ loading_order: true, marchantEnable: false });

      /*
       const header = {
        Accept: "application/json",
        "Content-Type": "application/json",
      };
      let result = "";
      try {
        result = await axios.post(
          `https://sip1.easyvoipcall.com/vuc4/api/Account/Register`,
          content
        );
      } catch (e) {
        this.setState({
          loading_order: false,
          classSnackbar: classes.errorSnackbar,
          response:
            "Κάτι πήγε στραβά Παραγγελία - #" +
            verify_mobile.order_id +
            " επικοινωνήστε με την υποστήριξη.",
        });
      }
      */
      let res = "";
      if (value_radio === "paypal") {
        res = await this.props.createOrderPBX(
          verify_mobile,
          users,
          plans,
          local_area,
          content,
          value_radio
        );
      } else if (value_radio === "alphabank") {
        res = await this.props.createOrderPBX(
          verify_mobile,
          users,
          plans,
          local_area,
          content,
          value_radio
        );
      } else {
        const result_direct = await this.props.updateDirectOrderPBX(
          verify_mobile,
          plans,
          users,
          local_area
        );
        if (result_direct) {
          this.setState({
            loading_order: false,
            classSnackbar: classes.errorSnackbar,
            response:
              "Κάτι πήγε στραβά Παραγγελία - #" +
              verify_mobile.order_id +
              " επικοινωνήστε με την υποστήριξη.",
          });
          return;
        } else {
          // this.setState({ submited: true,});
          //link to thank you page
          window.location =
            "/thankyou/" +
            verify_mobile.order_id +
            "/EUR/" + total + "/pbx/callcentre/" +
            pbx_plan_pack.id_plan_pack +
            "/banktransfer";
          //window.scrollTo(0, 0);
          //return;
        }
      }
      if (res) {
        this.setState({
          loading_order: false,
          classSnackbar: classes.errorSnackbar,
          response:
            "Κάτι πήγε στραβά Παραγγελία - #" +
            verify_mobile.order_id +
            " επικοινωνήστε με την υποστήριξη.",
        });
      } else {
        //this.setState({ created: true,});
        //link to thank you page
        window.location =
          "/thankyou/" +
          verify_mobile.order_id +
          "/EUR/" + total + "/pbx/callcentre/" +
          pbx_plan_pack.id_plan_pack +
          "/" + value_radio;
        //return;
      }
    }
  };

  render() {
    const {
      affiliate,
      products,
      addresses,
      value_radio,
      loading_order,
      response,
      response_payment,
      classSnackbar,
      classSnackbarPay,
      marchantEnable,
      users,
      local_area,
      submited,
      created,
      errors,
      errors_plans,
    } = this.state;
    const {
      verify_mobile,
      activeStep,
      classes,
      firstName,
      lastName,
      company,
      vat,
      aoy,
      local_areas,
      pbx_plan_pack,
    } = this.props;
    let total = 0;
    for (let num of products) {
      total = total + parseFloat(num.price);
    }
    return (
      <React.Fragment>
        {submited ? (
          <React.Fragment>
            <Typography variant="h5" gutterBottom>
              Σας ευχαριστούμε που μας επιλέξατε
            </Typography>
            <Typography variant="subtitle1">
              <ul>
                <li>
                  Κωδικός Παραγγελίας:{" "}
                  <strong>#{verify_mobile.order_id}</strong>
                </li>
                <li>Κατάσταση: Σε αναμονή εξόφλησης</li>
                <li>
                  Ποσό:<strong>€ {total.toFixed(2)}</strong>
                </li>
              </ul>
            </Typography>
            <Typography variant="h5" gutterBottom>
              EUROBANK: IBAN: <strong>GR60 0260 2760 0002 5020 0715 959</strong><br />
              ALPHA BANK: IBAN: <strong>GR09 0140 6640 6640 0233 0002 230</strong>
            </Typography>
            <Typography variant="h5" gutterBottom>
              Σημειώστε τον κωδικό παραγγελίας και το ονομά σας στο καταθετήριο.
              Στείλτε την απόδειξη κατάθεσης στο{" "}
              <a href="mailto:billing@hellasfon.com">billing@hellasfon.com</a>
              <br />
              <br /> η με Φαξ: <a href="tel:2811300308">2811300308</a>
              <br />
              <br />
              <strong>
                Σας ευχαριστούμε για την παραγγελία και περιμένουμε την πληρωμή
                σας.
              </strong>
            </Typography>
          </React.Fragment>
        ) : created ? (
          <React.Fragment>
            <Typography variant="h5" gutterBottom>
              Σας ευχαριστούμε που μας επιλέξατε
            </Typography>
            <Typography variant="subtitle1">
              <ul>
                <li>
                  Κωδικός Παραγγελίας:{" "}
                  <strong>#{verify_mobile.order_id}</strong>
                </li>
                <li>
                  {pbx_plan_pack.id_plan_pack !== 128
                    ? "Κατάσταση: Σε αναμονή εξόφλησης"
                    : "ΚΑΤΑΣΤΑΣΗ: ΔΩΡΕΑΝ"}
                </li>
                <li>
                  Ποσό:<strong>€ {total.toFixed(2)}</strong>
                </li>
              </ul>
            </Typography>
            <Typography variant="h5" gutterBottom>
              Ελέγξτε το email σας για τον σύνδεσμο επιβεβαίωσης.
            </Typography>
            <Typography variant="h5" gutterBottom>
              Για οποιεσδήποτε ερωτήσεις επικοινωνήστε μαζί μας{" "}
              <a href="mailto:billing@hellasfon.com">billing@hellasfon.com</a>
              <br />
              <br /> τηλέφωνο: <a href="tel:2811300307">2811300307</a>
              <br />
              <br />
              <strong>
                {pbx_plan_pack.id_plan_pack !== 128
                  ? "Σας ευχαριστούμε για την παραγγελία και περιμένουμε την πληρωμή σας."
                  : null}
              </strong>
            </Typography>
          </React.Fragment>
        ) : (
          <form>
            <Grid container spacing={2} style={{ paddingLeft: "50px" }}>
              <Grid item xs={12} sm={12}>
                <Typography variant="h6" gutterBottom className={classes.title}>
                  Προσωπικα στοιχεια
                </Typography>
                <Typography gutterBottom>{company}</Typography>
                <Typography gutterBottom>
                  {firstName} {lastName}
                </Typography>
                <Typography gutterBottom>{addresses.join(", ")}</Typography>
                <Typography gutterBottom>{vat}</Typography>
                <Typography gutterBottom>{aoy}</Typography>
              </Grid>
            </Grid>
            <Typography variant="h6" gutterBottom className={classes.title}>
              ΠΕΡΙΛΗΨΗ Εγγραφή
            </Typography>
            <TableContainer>
              <Table>
                <TableBody>
                  {products.map((product) => (
                    <TableRow key={product.id}>
                      <TableCell style={{ width: "600px" }}>
                        <ListItemText
                          primary={product.name}
                          secondary={product.desc}
                        />
                      </TableCell>
                      {product.id === 3 ? (
                        <TableCell align="left">
                          <FormControl>
                            <InputLabel htmlFor="local_area">
                              Περιοχή
                            </InputLabel>
                            <Select
                              required
                              disabled={loading_order}
                              value={local_area}
                              onChange={this.onChange}
                              inputProps={{
                                name: "local_area",
                                id: "local_area",
                              }}
                            >
                              {local_areas.map((area) => (
                                <MenuItem key={area.id} value={area.id}>
                                  {area.name} ({area.area_code})
                                </MenuItem>
                              ))}
                            </Select>
                            <FormHelperText>
                              Διαλέξτε Κωδ.Περιοχής τηλεφώνου
                            </FormHelperText>
                          </FormControl>
                        </TableCell>
                      ) : product.id === 2 ? (
                        <TableCell align="left">
                          <TextField
                            style={{ width: "100px" }}
                            required
                            error={errors.users ? true : false}
                            id="users"
                            name="users"
                            label="ποσότητα"
                            type="number"
                            value={users}
                            onChange={this.onChangeUsers}
                            onFocus={this.onChangeUsers}
                            onBlur={this.onChangeUsers}
                            disabled={loading_order}
                            inputProps={{ min: "0", max: "200", step: "1" }}
                          />
                        </TableCell>
                      ) : product.id >= 4 ? (
                        <TableCell align="left">
                          <TextField
                            style={{ width: "100px" }}
                            required
                            error={
                              errors_plans.filter(
                                (error) => error.id === product.id
                              ).length >= 1
                                ? true
                                : false
                            }
                            id={product.id.toString()}
                            name={product.id.toString()}
                            label="ποσότητα"
                            type="number"
                            value={product.quantity}
                            onChange={this.onChangePlan}
                            onFocus={this.onChangePlan}
                            onBlur={this.onChangePlan}
                            disabled={loading_order}
                            inputProps={{ min: "0", max: "200", step: "1" }}
                          />
                        </TableCell>
                      ) : (
                        <TableCell align="center"></TableCell>
                      )}
                      <TableCell align="right" style={{ width: "200px" }}>
                        <Typography variant="h6">€ {product.price}</Typography>
                      </TableCell>
                    </TableRow>
                  ))}
                  <TableRow>
                    <TableCell align="left">
                      <ListItemText primary="Σύνολο" />
                    </TableCell>
                    <TableCell align="center"></TableCell>
                    <TableCell align="right">
                      {" "}
                      <Typography variant="h5" className={classes.total}>
                        € {total.toFixed(2)}
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            {pbx_plan_pack.id_plan_pack !== 128 ? (
              <React.Fragment>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12}>
                    <Typography
                      variant="h6"
                      gutterBottom
                      className={classes.title}
                    >
                      Τροπος πληρωμης
                    </Typography>
                    <RadioGroup
                      aria-label="payment"
                      id="payment"
                      value={value_radio}
                      onChange={this.handleRadioChange}
                    >
                      {" "}
                  {/*
                  <FormControlLabel
                    value="paypal"
                    control={<Radio />}
                    label={
                      <img src="/img/paypal-logo.svg" alt="Paypal" height="30" />
                    }
                  />

                      <FormControlLabel
                        value="alphabank"
                        control={<Radio />}
                        label={
                          <img
                            src="/img/AlphaBank.png"
                            alt="AlphaBank"
                            height="30"
                          />
                        }
                      />
                    */}
                      <FormControlLabel
                        style={{ color: "#495057" }}
                        value="banktransfer"
                        control={<Radio />}
                        label={"Τραπεζική κατάθεση"}
                      />
                    </RadioGroup>
                  </Grid>
                </Grid>
                <Divider />
                <ListItem className={classes.listItem}>
                  <ListItemText primary="Σύνολο" />
                  <Typography variant="h6" className={classes.total}>
                    € {total.toFixed(2)}
                  </Typography>
                </ListItem>
              </React.Fragment>
            ) : null}
            <Divider />
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <Typography variant="h6" gutterBottom className={classes.title}>
                  ΣΥΝΕΡΓΑΤΕΣ
                </Typography>
              </Grid>
              <GridItem xs={12} sm={6} md={3}>
                <CustomInput
                  error={errors.affiliate ? true : false}
                  required
                  type="affiliate"
                  labelText="Κωδικός Συνεργατών"
                  id="affiliate"
                  name="affiliate"
                  value={affiliate}
                  onChange={this.handleChange}
                  onFocus={this.handleChange}
                  onBlur={this.handleChange}
                  disabled={loading_order}
                  helperText="Εισαγάγετε τον κωδικό συνεργατών"
                  formControlProps={{
                    fullWidth: true,
                  }}
                />
              </GridItem>
            </Grid>
            <GridContainer className={classes.buttons}>
              <GridItem xs={12} sm={12} md={12} className={classes.textEnd}>
                {activeStep !== 0 && marchantEnable && (
                  <Button
                    onClick={this.onClick}
                    className={classes.button}
                    disabled={loading_order}
                  >
                    Πίσω
                  </Button>
                )}
                {value_radio === "alphabank" && marchantEnable ? (
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    onClick={this.submitOrder}
                    disabled={loading_order}
                  >
                    Υποβάλετε την παραγγελία
                  </Button>
                ) : value_radio === "banktransfer" && marchantEnable ? (
                  <Button
                    onClick={this.submitOrder}
                    className={classes.button}
                    disabled={loading_order}
                    color="primary"
                  >
                    υποβάλετε την παραγγελία
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    onClick={this.submitOrder}
                    disabled={loading_order}
                  >
                    Υποβάλετε την παραγγελία
                  </Button>
                )}

                {loading_order && (
                  <React.Fragment>
                    <b>
                      {" "}
                      <Typography variant="h6" style={{ textAlign: "left" }}>
                        Παρακαλώ περιμένετε ...
                      </Typography>
                    </b>
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  </React.Fragment>
                )}
                <SnackbarContent
                  className={classSnackbarPay}
                  aria-describedby="client-snackbar_payment"
                  message={response_payment}
                />
                <SnackbarContent
                  className={classSnackbar}
                  aria-describedby="client-snackbar"
                  message={response}
                />
              </GridItem>
            </GridContainer>
          </form>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  pbx_plan_pack: state.pbx_reducer.pbx_plan_pack,
  plans: state.pbx_reducer.plans,
  verify_mobile: state.pbx_reducer.verify_mobile,
  local_areas: state.pbx_reducer.local_areas,
  create_pbx: state.pbx_reducer.pbx_retail,
});

export default connect(
  mapStateToProps,
  { createOrderPBX, updateDirectOrderPBX }
)(withStyles(workStyle)(Review));
