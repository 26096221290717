import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import withStyles from "@material-ui/core/styles/withStyles";
import { TinyButton as ScrollUpButton } from "react-scroll-up-button";
import Header from "components/Header/Header.jsx";
import Footer from "components/Footer/FooterEN.jsx";
import HeaderLinks from "components/Header/HeaderLinksEN.jsx";
import HeaderTopLinks from "components/Header/HeaderTopLinksEN.jsx";
import Parallax from "components/Parallax/Parallax.jsx";

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import Typography from "@material-ui/core/Typography";

import CardMedia from "@material-ui/core/CardMedia";
import Button from "components/CustomButtons/Button.jsx";

import landingPageStyle from "assets/jss/material-kit-react/views/landingPage.jsx";

import VisaSection from "../Sections/VisaSection.jsx";

import AppSection from "../Sections/AppSectionBusinessEN.jsx";

import CookieConsent from "react-cookie-consent";

class CallCenterPage extends React.Component {
  constructor(props) {
    window.scrollTo(0, 0);
    super(props);
  }
  render() {
    const { classes, ...rest } = this.props;

    return (
      <div>
        <Header
          topLinks={<HeaderTopLinks />}
          rightLinks={<HeaderLinks />}
          fixed
          logoUrl="/img/hellasfon_logo_white.jpg"
          color="primary"
          changeColorOnScroll={{
            height: 50,
            color: "white",
          }}
          {...rest}
        />
        <Parallax color small />
        <div className={classNames(classes.main, classes.mainRaised)}>
          <h1
            style={{
              textAlign: "center",
              backgroundColor: "#005FB4",
              color: "white",
              marginBottom: "0px",
            }}
          >
            {"Solutios for Call Centers"}
          </h1>
          <GridContainer
            justify="center"
            style={{
              width: "100%",
              marginLeft: "0px",
              marginRight: "0px",
              marginTop: "0px",
            }}
          >
		  
		  
		  
            <GridItem cs={12} sm={12} md={6} style={{ padding: 50 }}>
              <h2>SIP Trunk Solution</h2>
              <Typography variant="body1">
                <li>Unlimited Availability (Voice channels)</li>
				<li>High Connectivity</li>
				<li> HD Voice Quality</li>
				<li> High call success rate</li>
				<li> All pbx's are supported </li>
				<br></br>
				<strong>Hellasfon offers you :  
				<li>HD Sound Quality</li>
				<li>Lower call rates without intermediaries!</li></strong>
				<br /><p></p> 
              </Typography>
			  <Button href="/contact" color="primary">
                GET AN OFFER
              </Button>
            </GridItem>
            <GridItem
              cs={12}
              sm={12}
              md={6}
              style={{ paddingRight: "0px", paddingLeft: "0px" }}
            >
              <CardMedia
                component="img"
                image="/img/callcenter.jpg"
                alt="Solutions Hellasfon for call centers"
                title="Hellasfon Solutions for pbx and call centers"
              />
            </GridItem>
          </GridContainer>
          <GridContainer
            justify="center"
            style={{
              width: "100%",
              marginLeft: "0px",
              marginRight: "0px",
              marginTop: "0px",
            }}
          >
            <GridItem cs={12} sm={12} md={6} style={{ background: "#009bff" }}>
              <CardMedia
                style={{ padding: 70 }}
                component="img"
                image="/img/call_center.jpg"
                alt="Solutions for Call Centers"
                title="Hellasfon - Solutions for Call Centers "
              />
            </GridItem>
            <GridItem
              cs={12}
              sm={12}
              md={6}
              style={{ background: "#009bff", padding: 70 }}
            >
              <h2 style={{ color: "#fff" }}>Solution through a PBX</h2>
              <Typography variant="body1" style={{ color: "#fff" }}>
                Forget about Buying/Installing/Configuring an expensive Call Center <br></br>as well as it's increased monthly operating cost. <br /><p></p> 
				<li> Register as many <b>LOCAL or OTHER Country Numbers</b> you desire and start accepting <b>incoming calls </b> or running <b>outbound calls</b> campaigns.</li>
				<li> Create your <b>USER GROUPS</b> </li>
				<li> Record your <b>Audio messages</b> that your customers will hear and <b>define how calls will be handled.</b></li>
				
				<br></br>Save up to 75% on all your calls.
				<br /><p></p> <br></br>
				<strong>Want to add NEW Users ? EASY ! 0,75 €/month per user! </strong>
				<br></br>
				
                <Button href="/contact" color="secondary">
                GET AN OFFER
              </Button>
			  <Button href="/didnumbers" color="warning">
                  AVAILABLE NUMBERS
              </Button>
              </Typography>
			 
            </GridItem>
          </GridContainer>
          <GridContainer
            justify="center"
            style={{
              width: "100%",
              marginLeft: "0px",
              marginRight: "0px",
              marginTop: "0px",
            }}
          >
            <GridItem cs={12} sm={12} md={6} style={{ padding: 30 }}>
              <h2>Voice Recording Feature </h2>
              <Typography variant="body1">
			    <li> On every EVCOFFICE Call Center we have the option to activate Audio Recording of calls for better customer service.</li>
                <li> Select users whose conversation will be recorded.</li>
                <li> Monthly cost €12/month regardless of the number of users. </li>
                <br></br>
				<strong>ECONOMICALLY AND WITHOUT THE NEED TO PURCHASE ADDITIONAL EQUIPMENT</strong>
				<br></br>
				<h3><strong>Serving YOUR customers is OUR personal business!</strong></h3>
				<Button href="/contact" color="primary">
                GET AN OFFER
              </Button>
			  <Button href="/didnumbers" color="secondary">
                AVAILABLE NUMBERS
              </Button>
		      </Typography>
            </GridItem>
            <GridItem
              cs={12}
              sm={12}
              md={6}
              style={{ paddingRight: "0px", paddingLeft: "0px" }}
            >
              <CardMedia
                component="img"
                image="/img/voicerec.jpg"
                alt="Hellasfon Voice Recording Services"
                title="Hellasfon Voice Recording Services for Call Centers"
              />
            </GridItem>
						
		  
          </GridContainer>
          <GridContainer
            justify="center"
            style={{
              width: "100%",
              marginLeft: "0px",
              marginRight: "0px",
              marginTop: "0px",
            }}
          >
           
            
          </GridContainer>
          <GridContainer
            justify="center"
            style={{
              width: "100%",
              marginLeft: "0px",
              marginRight: "0px",
              marginTop: "0px",
            }}
          >
            
            
          </GridContainer>
          <GridContainer
            justify="center"
            style={{
              width: "100%",
              marginLeft: "0px",
              marginRight: "0px",
              marginTop: "0px",
            }}
          >
                        
          </GridContainer>
          <GridContainer
            justify="center"
            style={{
              width: "100%",
              marginLeft: "0px",
              marginRight: "0px",
              marginTop: "0px",
            }}
          >
            
          </GridContainer>
          <GridContainer
            style={{
              width: "100%",
              marginLeft: "0px",
              marginRight: "0px",
              marginTop: "0px",
              display: "grid",
            }}
          >
		  
		  <AppSection />
		  </GridContainer>
		  
		  <VisaSection />
          <Footer />
        </div>
        <ScrollUpButton />
        <CookieConsent
          location="bottom"
          buttonText="ΟΚ I aggree"
          cookieName="hellasfonCookie"
          style={{ background: "rgb(1, 120, 226)" }}
          buttonStyle={{
            background: "#f50057",
            color: "white",
            fontSize: "15px",
            marginRight: "130px",
          }}
          expires={150}
        >
          We use cookies on this website to improve
          users experience.{" "}
          <span style={{ fontSize: "12px" }}>
            By clicking on any link on this page you give 
            your consent to set up cookies.{" "}
            <a
              style={{ color: "white", textDecoration: "underline" }}
              href="/privacy"
            >
              More information
            </a>
          </span>
        </CookieConsent>
      </div>
    );
  }
}

CallCenterPage.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(landingPageStyle)(CallCenterPage);
