import React from "react";
import { Router, Route, Switch } from "react-router-dom";
import { createBrowserHistory } from "history";
import { Redirect } from 'react-router'

import RetailPage from "./views/RetailPage";
import RetailPageEN from "./views/RetailPageEN";
import CloudPBXPage from "./views/CloudPBXPage";
import CloudPBXPageEN from "./views/CloudPBXPageEN";
import SipTrunkPage from "./views/SipTrunkPage";
import SipTrunkPageEN from "./views/SipTrunkPageEN";
import TravelSimPage from "./views/TravelSimPage";
import TravelSimPageEN from "./views/TravelSimPageEN";
import IotSimPage from "./views/IotSimPage";
import IotSimPageEN from "./views/IotSimPageEN";
import CompanyPage from "./views/CompanyPage";
import FAQPage from "./views/FAQPage";
import FAQPageEN from "./views/FAQPageEN";
import FAQOffice from "./views/FAQOFFICE";
import FAQOfficeEN from "./views/FAQOFFICEEN";
import ContactPage from "./views/ContactPage";
import ContactPageEN from "./views/ContactPageEN";
import JobPage from "./views/JobPage";
import JobPageEN from "./views/JobPageEN";
import ThankYouPage from "./views/ThankYouPage";
import Resellers from "./views/ResellersPage";
import ShipPage from "./views/Solutions/ShipPage";
import ShipPageEN from "./views/Solutions/ShipPageEN";

import ContBusPage from "./views/ContBusPage";


import ContShipPage from "./views/ContShipPage";
//import ContShipPageEN from "./views/ContShipPageEN";
import PBXFeatPage from "./views/PBXFeatPage";
import PBXFeatPageEN from "./views/PBXFeatPageEN";


//import MaritimePage from "./views/MaritimePage";
//import ResellersPage from "./views/ResellersPage";
import DIDPage from "./views/DIDPage";
import DIDPageEN from "./views/DIDPageEN";
import EulaPage from "./views/EulaPage";

import Privacy from "./views/PrivacyPage";
import PrivacyEN from "./views/PrivacyPageEN";
import NotFound from "./views/NotFound";
import RegPage from "./views/RegPage";
import WorkFromHomePage from "./views/Solutions/WorkFromHomePage";
import WorkFromHomePageEN from "./views/Solutions/WorkFromHomePageEN";
import CallCenterPage from "./views/Solutions/CallCenterPage";
import CallCenterPageEN from "./views/Solutions/CallCenterPageEN";

import EshopPage from "./views/Solutions/EshopPage";
import EshopPageEN from "./views/Solutions/EshopPageEN";

import HotelPage from "./views/Solutions/HotelPage";
import HotelPageEN from "./views/Solutions/HotelPageEN";

import RegBusinessPage from "./views/RegBusinessPage";
import Rates from "./views/RatesPage";
import RatesEN from "./views/RatesPageEN";

import HomePage from "./views/HomePage";
import HomePageEN from "./views/HomePageEN";

//import DIDs from "./views/DIDPage";
//import BlogPage from "./views/BlogPage";

import SolutionBusinessPage from "./views/Solutions/BusinessPage";
import SolutionCallCenterPage from "./views/Solutions/CallCenterPage";
import SolutionCallNoCostPage from "./views/Solutions/CallNoCostPage";
import SolutionCallshopPage from "./views/Solutions/CallshopPage";
import SolutionCardsPage from "./views/Solutions/CardsPage";
import SolutionHotelsPage from "./views/Solutions/HotelsPage";
import SolutionIndividualsPage from "./views/Solutions/IndividualsPage";
import SolutionInternationalBusiness from "./views/Solutions/InternationalBusiness";
import SolutionNoCallCenterPage from "./views/Solutions/NoCallCenterPage";
import SolutionOnlineSales from "./views/Solutions/OnlineSales";
import SolutionPCPhonesPage from "./views/Solutions/PCPhonesPage";
import SolutionRemotePage from "./views/Solutions/RemotePage";
import SolutionShippingPage from "./views/Solutions/ShippingPage";
import SolutionStartupPage from "./views/Solutions/StartupPage";
import SolutionStartupPageEN from "./views/Solutions/StartupPageEN";
import SolutionTaxiPage from "./views/Solutions/TaxiPage";
import SolutionTelephoneCenterPage from "./views/Solutions/TelephoneCenterPage";
import SolutionVideoConfPage from "./views/Solutions/VideoConfPage";

import "assets/scss/material-kit-react.scss?v=1.7.0";

import { Provider } from "react-redux";
import store from "store";

var hist = createBrowserHistory();

function App() {
  return (
    <Provider store={store}>
      <Router history={hist}>
        <div className="App">
          <Switch>
            <Redirect exact from='/privacy' to='/privacy-policy'/>
            <Redirect exact from='/en/privacy' to='/en/privacy-policy'/>
            <Redirect exact from='/img' to='/'/>
            <Redirect exact from='/static/media' to='/'/>
            <Route exact path="/" component={HomePage} />
            <Route exact path="/home" component={HomePage} />
            <Route exact path="/retail" component={RetailPage} />
            <Route exact path="/privacy-policy" component={Privacy} />
            <Route exact path="/rates" component={Rates} />
            <Route exact path="/cloudpbx" component={CloudPBXPage} />
            <Route exact path="/resellers" component={Resellers} />
            <Route exact path="/siptrunk" component={SipTrunkPage} />
			<Route exact path="/travelsim" component={TravelSimPage} />
			<Route exact path="/iotsim" component={IotSimPage} />
            <Route exact path="/company" component={CompanyPage} />
            <Route exact path="/faq" component={FAQPage} />
            <Route exact path="/faqoffice" component={FAQOffice} />
            <Route exact path="/contact" component={ContactPage} />
            <Route exact path="/job" component={JobPage} />
            <Route exact path="/eula" component={EulaPage} />
            <Route exact path="/reg/:pattern/:id" component={RegPage} />
            <Route exact path="/didnumbers" component={DIDPage} />
			<Route exact path="/pbx-features" component={PBXFeatPage} />
			<Route exact path="/eshops" component={EshopPage} />
			<Route exact path="/contact-business" component={ContBusPage} />
            <Route
              exact
              path="/:redirect/:order/:currency/:total/reg/:pattern/:id/:operation"
              component={RegPage}
            />
            <Route
              exact
              path="/thankyou/:order_id/:currency/:total/pbx/:pattern/:id_plan_pack/:operation"
              component={ThankYouPage}
            />
            <Route exact path="/regbusiness/:id" component={RegBusinessPage} />
            <Route exact path="/solution/work_from_home" component={WorkFromHomePage} />
			
			      <Route exact path="/en" component={HomePageEN} />
            <Route exact path="/en/home" component={HomePageEN} />
            <Route exact path="/en/retail" component={RetailPageEN} />
            <Route exact path="/en/privacy-policy" component={PrivacyEN} />
            <Route exact path="/en/rates" component={RatesEN} />
            <Route exact path="/en/cloudpbx" component={CloudPBXPageEN} />
            <Route exact path="/en/resellers" component={Resellers} />
            <Route exact path="/en/siptrunk" component={SipTrunkPageEN} />
			<Route exact path="/en/travelsim" component={TravelSimPageEN} />
			<Route exact path="/en/iotsim" component={IotSimPageEN} />
            <Route exact path="/en/solution/work_from_home" component={WorkFromHomePageEN} />
			<Route exact path="/en/solution/call-inmarsat" component={ShipPageEN} />
			
			
            <Route exact path="/en/company" component={CompanyPage} />
            <Route exact path="/en/faq" component={FAQPageEN} />
            <Route exact path="/en/faqoffice" component={FAQOfficeEN} />
            <Route exact path="/en/contact" component={ContactPageEN} />
            <Route exact path="/en/job" component={JobPageEN} />
            <Route exact path="/en/eula" component={EulaPage} />
			<Route exact path="/en/pbx-features" component={PBXFeatPageEN} />
			<Route exact path="/en/eshops" component={EshopPageEN} />
			      <Route exact path="/en/didnumbers" component={DIDPageEN} />

            <Route
              exact
              path="/en/:redirect/:order/:currency/:total/reg/:pattern/:id/:operation"
              component={RegPage}
            />
            <Route
              exact
              path="/en/thankyou/:order_id/:currency/:total/pbx/:pattern/:id_plan_pack/:operation"
              component={ThankYouPage}
            />

            <Route exact path="/en/regbusiness/:id" component={RegBusinessPage} />
			<Route exact path="/en/solution/startup" component={SolutionStartupPageEN} />
			<Route exact path="/en/solution/eshop" component={EshopPageEN} />
			<Route exact path="/en/solution/callcenter" component={CallCenterPageEN} />
			<Route exact path="/en/solution/hotel" component={HotelPageEN} />
						
            <Route exact path="/solution/business"  component={SolutionBusinessPage} />
            <Route exact path="/solution/callcenter" component={SolutionCallCenterPage} />
            <Route exact path="/solution/callnocost" component={SolutionCallNoCostPage} />
            <Route exact path="/solution/callshop" component={SolutionCallshopPage} />
            <Route exact path="/solution/cards" component={SolutionCardsPage} />
            <Route exact path="/solution/hotels" component={SolutionHotelsPage} />
            <Route exact path="/solution/individuals" component={SolutionIndividualsPage} />
            <Route exact path="/solution/internationalbusiness" component={SolutionInternationalBusiness} />
            <Route exact path="/solution/nocallcenter" component={SolutionNoCallCenterPage} />
            <Route exact path="/solution/onlinesales" component={SolutionOnlineSales} />
            <Route exact path="/solution/pcphones" component={SolutionPCPhonesPage} />
            <Route exact path="/solution/remote" component={SolutionRemotePage} />
             
			<Route exact path="/solution/shippingcomapnies" component={SolutionShippingPage} /> 
            <Route exact path="/solution/startup" component={SolutionStartupPage} />
			<Route exact path="/solution/call-inmarsat" component={ShipPage} />
			<Route exact path="/solution/callcenter" component={CallCenterPage} />
			<Route exact path="/solution/eshop" component={EshopPage} />
			<Route exact path="/solution/hotel" component={HotelPage} />
			
			<Route exact path="/solution/call-inmarsat-contact" component={ContShipPage} />
			
            <Route exact path="/solution/taxi" component={SolutionTaxiPage} />
            <Route exact path="/solution/telephonecenter" component={SolutionTelephoneCenterPage} />
            <Route exact path="/solution/videoconference" component={SolutionVideoConfPage} />
            <Route
              path="/webmail"
              component={() => {
                window.location.href = "https://webmail.hellasfon.com/";
                return null;
              }}
            />
            <Route component={NotFound} />
          </Switch>
        </div>
      </Router>
    </Provider>
  );
}

export default App;
