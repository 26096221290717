/*eslint-disable*/
import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Button from "components/CustomButtons/Button.jsx";
import Tooltip from "@material-ui/core/Tooltip";

import headerLinksStyle from "assets/jss/material-kit-react/components/headerLinksStyle.jsx";

function HeaderSocial({ ...props }) {
  const { classes } = props;
  return (
    <List className={classes.listTop}>
      <ListItem className={classes.listTopItem}>
        <Tooltip
            id="facebook-tooltip"
            title="Follow us on facebook"
            placement={window.innerWidth > 959 ? "top" : "left"}
            classes={{ tooltip: classes.tooltip }}
            >
            <Button
                color="transparent"
                href="https://www.facebook.com/hellasfon/"
                target="_blank"
                className={classes.navTopLink}
            >
                <i className={classes.socialIcons + " fab fa-facebook"} />
            </Button>
            </Tooltip>
      </ListItem>
      <ListItem className={classes.listTopItem}>
        <Tooltip
            id="instagram-tooltip"
            title="Follow us on Twitter"
            placement={window.innerWidth > 959 ? "top" : "left"}
            classes={{ tooltip: classes.tooltip }}
        >
            <Button
            color="transparent"
            href="https://twitter.com/hellasfon"
            target="_blank"
            className={classes.navTopLink}
            >
            <i className={classes.socialIcons + " fab fa-twitter"} />
            </Button>
        </Tooltip>
      </ListItem>
      <ListItem className={classes.listTopItem}>
        <Tooltip
            id="instagram-tooltip"
            title="Follow us on instagram"
            placement={window.innerWidth > 959 ? "top" : "left"}
            classes={{ tooltip: classes.tooltip }}
            >
            <Button
                color="transparent"
                href="https://www.instagram.com/hellasfonnetworks/"
                target="_blank"
                className={classes.navTopLink}
            >
                <i className={classes.socialIcons + " fab fa-instagram"} />
            </Button>
            </Tooltip>
      </ListItem>
      <ListItem className={classes.listTopItem}>
        <Tooltip
            id="instagram-tooltip"
            title="Follow us on LinkedIn"
            placement={window.innerWidth > 959 ? "top" : "left"}
            classes={{ tooltip: classes.tooltip }}
            >
            <Button
                color="transparent"
                href="https://www.linkedin.com/in/hellasfon-networks-sa-984272173/"
                target="_blank"
                className={classes.navTopLink}
            >
                <i className={classes.socialIcons + " fab fa-linkedin"} />
            </Button>
        </Tooltip>
      </ListItem>
      <ListItem className={classes.listTopItem}>
      <Tooltip
            id="phone-tooltip"
            title="Call us now"
            placement={window.innerWidth > 959 ? "top" : "left"}
            classes={{ tooltip: classes.tooltip }}
            >
        <Button
            color="transparent"
            className={classes.phoneButton}
            href="tel:8012221001"
        >
            <i className={classes.icons + " fas fa-phone"} /> 801-222-1001
        </Button>
        </Tooltip>
      </ListItem>
    </List>
  );
}

export default withStyles(headerLinksStyle)(HeaderSocial);
