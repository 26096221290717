/*!

=========================================================
* Material Kit React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import { container } from "assets/jss/material-kit-react.jsx";

const footerStyle = {
  block: {
    padding: "0.9375rem",
    fontWeight: "500",
    fontSize: "12px",
    textTransform: "uppercase",
    borderRadius: "3px",
    textDecoration: "none",
    position: "relative",
    display: "block"
  },
  left: {
    float: "left!important",
    display: "block"
  },
  right: {
    padding: "15px 0",
    margin: "0",
    float: "right!important"
  },
  center: {
    paddingTop: "15px",
    fontSize: "12px",
    color: "#efefef",
    margin: "0"
  },
  socail : {
    color: "#009bff"
  },
  footer: {
    backgroundColor: "#2f3539",
    padding: "0.9375rem 0",
    textAlign: "center",
    display: "flex",
    zIndex: "2",
    position: "relative",
    marginTop: "10px"
  },
  a: {
    color: "#efefef",
    backgroundColor: "transparent",
    "&:hover,&:focus": {
      color: "#0086FE"
    },
    padding: "0 5px"
  },
  footerWhiteFont: {
    "&,&:hover,&:focus": {
      color: "#efefef"
    }
  },
  container,
  list: {
    marginBottom: "0",
    padding: "0",
    marginTop: "0"
  },
  inlineBlock: {
    display: "inline-block",
    padding: "0px",
    width: "auto"
  },
  icon: {
    width: "18px",
    height: "18px",
    position: "relative",
    top: "3px"
  }
};
export default footerStyle;
