import {
  GET_RETAIL_PLAN_PACKS,
  SELECT_PLAN_PACK,
  CHECK_EMAIL,
  GET_LOCAL_ARES,
  VERIFY_MOBILE,
  REMOVE_ORDER,
  VERIFY_CODE,
  CREATE_ORDER,
  GET_PLANS,
  UPDATE_ALPHA_ORDER,
  CHECK_ORDER,
  GET_TARIFF_RATES,
  APP_LOADING
} from "../actions/types";

const initialState = {
  retail_plan_packs: [],
  retail_plan_pack: {},
  check_email: {},
  local_areas: [],
  loading: true,
  verify_mobile: [],
  verify_code: false,
  create_retail: [],
  check_order: [],
  check_order_items: [],
  plans: [],
  tariff_rates: []
};

export default function(state = initialState, action) {
  switch (action.type) {
    case APP_LOADING:
      return {
        ...state,
        loading: action.payload,
      }
    case GET_TARIFF_RATES:
      return {
        tariff_rates: action.payload
      };
    case CHECK_ORDER:
      return {
        ...state,
        check_order: action.payload[0],
        check_order_items: action.payload[1],
        loading: false
      };
    case UPDATE_ALPHA_ORDER:
      return {
        ...state,
        loading: false
      };
    case GET_PLANS:
      return {
        ...state,
        plans: action.payload,
        loading: false
      };
    case GET_RETAIL_PLAN_PACKS:
      return {
        ...state,
        retail_plan_packs: action.payload,
        loading: false
      };
    case SELECT_PLAN_PACK:
      return {
        ...state,
        retail_plan_pack: action.payload,
      };
    case CHECK_EMAIL:
      return {
        ...state,
        check_email: action.payload
      };
    case GET_LOCAL_ARES:
      return {
        ...state,
        local_areas: action.payload
      };
    case VERIFY_MOBILE:
      return {
        ...state,
        verify_mobile: action.payload
      };
    case REMOVE_ORDER:
      return {
        ...state,
        verify_mobile: ""
      };
    case VERIFY_CODE:
      return {
        ...state,
        verify_code: action.payload
      };
    case CREATE_ORDER:
      return {
        ...state,
        create_retail: action.payload
      };
    default:
      return state;
  }
}
