import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import ReCAPTCHA from "react-recaptcha";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Typography from "@material-ui/core/Typography";
import green from "@material-ui/core/colors/green";
import CircularProgress from "@material-ui/core/CircularProgress";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import axios from "axios";

import scrollIntoView from "scroll-into-view-if-needed";

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";

import workStyle from "assets/jss/material-kit-react/views/landingPageSections/workStyle.jsx";

import InputAdornment from "@material-ui/core/InputAdornment";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';


const GreenCheckbox = withStyles({
  root: {
    color: green[400],
    "&$checked": {
      color: green[600]
    }
  },
  checked: {}
})(props => <Checkbox color="default" {...props} />);

class JobSection extends React.Component {
  constructor(props) {
    super(props);
    const { classes } = props;
    this.checkCaptacha = this.checkCaptacha.bind(this);
    this.state = {
      radio: "1",
      phone: "",
      name: "",
      email: "",
      message: "",
      accept: false,
      isVerified: false,
      recaptchaClass: "",
      loading: false,
      classSnackbar: classes.hideSnackbar,
      response: "",
      errors: {
        phone: false,
        name: false,
        email: false,
        message: false,
        accept: false
      }
    };
  }

  checkCaptacha(response) {
    if (response) {
      this.setState({ isVerified: true, recaptchaClass: "" });
    }
  }
  onloadCaptcha() {
    console.log("recaptcha loaded");
  }
  onSubmit = async e => {
    e.preventDefault();

    const { name, phone, email, message, accept, isVerified, loading, radio } = this.state;
    const { classes } = this.props;
    let job = (radio === "1" ? "Τηλεφωνικό Κέντρο - Υποστήριξη Πελατών" : radio === "2" ? "Πωλήσεις και Marketing" : "Άλλο");
    let subject = 'Θέσεις Εργασίας ' + job + " - Hellasfon";

    const newContact = {
      name,
      email,
      message,
      phone, 
      subject,
      job
    };
    const login = {
      dfa3424: "daDAS!@DFSGHFGJ(*)hGdffser4324765~hfgh"
    };

    // Check For Errors
    if (name === "" || name.length < 3 || name.length > 100) {
      this.setState({ errors: { name: true } });
      const node = document.getElementById("name");
      scrollIntoView(node, { behavior: "smooth", scrollMode: "if-needed" });
      return;
    }
    if (
      email === "" ||
      !email.match(/^[a-z._A-Z0-9]+@((?:[-a-z0-9]+\.)+[a-z]{2,})$/) ||
      email.length > 100
    ) {
      this.setState({
        errors: { email: true },
        classSnackbar: classes.errorSnackbar,
        response: "απαιτείται ηλεκτρονικό ταχυδρομείο"
      });
      const node = document.getElementById("email");
      scrollIntoView(node, { behavior: "smooth", scrollMode: "if-needed" });
      return;
    }

    if (
        phone === "" ||
        phone.length < 10 ||
        phone.length > 10 ||
        !phone.match(/^[0-9\b]+$/)
      ) {
        this.setState({ 
          errors: { phone: true },
          classSnackbar: classes.errorSnackbar,
          response: "κινητό τηλέφωνο" 
        });
        const node = document.getElementById('phone');
        scrollIntoView(node, { behavior: 'smooth', scrollMode: 'if-needed'  });
        return;
      }

    if (message === "" || message.length < 3 || message.length > 100) {
      this.setState({ errors: { message: true } });
      const node = document.getElementById("message");
      scrollIntoView(node, { behavior: "smooth", scrollMode: "if-needed" });
      return;
    }

    if (accept === false) {
      this.setState({ errors: { accept: true } });
      const node = document.getElementById("accept");
      scrollIntoView(node, { behavior: "smooth", scrollMode: "if-needed" });
      return;
    }
    if (!isVerified) {
      this.setState({ recaptchaClass: "recaptcha-error" });
      return;
    }

    if (!loading) {
      this.setState({ loading: true });
      // get the authorization api token
      try {
        const res = await axios.post(
          `https://myaccount.hellasfon.com/pbxres/api/fsdffgdg670dbmfsfv90cxxiy`,
          login
        );
        const header = {
          Authorization: "Bearer " + res.data.dfa3424
        };
        // sent email

        try {
          await axios.post(
            `https://myaccount.hellasfon.com/pbxres/api/jobsentemail`,
            newContact,
            { headers: header }
          );
          this.setState({
            loading: false,
            classSnackbar: classes.successSnackbar,
            response: "Το μήνυμα σας απεστάλθηκε."
          });

          // clear state
          this.setState({
            phone: "",
            name: "",
            email: "",
            message: "",
            accept: false,
            isVerified: false,
            recaptchaClass: "",
            loading: false,
            errors: {
              subject: false,
              name: false,
              email: false,
              message: false,
              accept: false
            }
          });
          await axios.post(
            `https://sip1.easyvoipcall.com/pbxres/api/jfg5345hfgh4545b4234234gv`,
            "",
            { headers: header }
          );
        } catch (e) {
          await axios.post(
            `https://sip1.easyvoipcall.com/pbxres/api/jfg5345hfgh4545b4234234gv`,
            "",
            { headers: header }
          );
          // display error mesage
          this.setState({
            loading: false,
            classSnackbar: classes.errorSnackbar,
            response: "Κάτι πήγε στραβά πάλι."
          });
        }
      } catch (e) {
        this.setState({
          loading: false,
          classSnackbar: classes.errorSnackbar,
          response: "Κάτι πήγε στραβά πάλι."
        });
      }
    }
  };
  onChange = e => {
    this.setState(
      e.target.id === "accept"
        ? {
            [e.target.id]: e.target.checked
          }
        : ( e.target.name === "radio")
        ? {
            [e.target.name]: e.target.value,
          }
        : (e.target.value === "" && e.target.id === "phone") ||
          (e.target.value.length < 10 && e.target.id === "phone") ||
          (e.target.value.length > 10 && e.target.id === "phone") ||
          (e.target.id === "phone" && !e.target.value.match(/^[0-9\b]+$/))
        ? {
            [e.target.id]: e.target.value,
            errors: {
              [e.target.id]: true
            }
          }
        : (e.target.value === "" && e.target.id === "message") ||
          (e.target.id === "message" && e.target.value.length < 3)
        ? {
            [e.target.id]: e.target.value,
            errors: {
              [e.target.id]: true
            }
          }
        : (e.target.value === "" && e.target.id === "name") ||
          (e.target.id === "name" && e.target.value.length < 3)
        ? {
            [e.target.id]: e.target.value,
            errors: {
              [e.target.id]: true
            }
          }
        : (e.target.value === "" && e.target.id === "email") ||
          (e.target.id === "email" &&
            !e.target.value.match(
              /^[a-z._A-Z0-9]+@((?:[-a-z0-9]+\.)+[a-z]{2,})$/
            ))
        ? {
            [e.target.id]: e.target.value,
            errors: {
              [e.target.id]: true
            }
          }
        : {
          [e.target.id]: e.target.value,
          errors: {
            [e.target.id]: false
          }
        }
    );
  };
  onFocus = e => {
    this.setState(
      (e.target.value === "" && e.target.id === "message") ||
        (e.target.id === "message" && e.target.value.length < 3)
        ? {
            [e.target.id]: e.target.value,
            errors: {
              [e.target.id]: true
            }
          }
        : (e.target.value === "" && e.target.id === "phone") ||
          (e.target.value.length < 10 && e.target.id === "phone") ||
          (e.target.value.length > 10 && e.target.id === "phone") ||
          (e.target.id === "phone" && !e.target.value.match(/^[0-9\b]+$/))
        ? {
            [e.target.id]: e.target.value,
            errors: {
              [e.target.id]: true
            }
          }
        : (e.target.value === "" && e.target.id === "name") ||
          (e.target.id === "name" && e.target.value.length < 3)
        ? {
            [e.target.id]: e.target.value,
            errors: {
              [e.target.id]: true
            }
          }
        : (e.target.value === "" && e.target.id === "email") ||
          (e.target.id === "email" &&
            !e.target.value.match(
              /^[a-z._A-Z0-9]+@((?:[-a-z0-9]+\.)+[a-z]{2,})$/
            ))
        ? {
            [e.target.id]: e.target.value,
            errors: {
              [e.target.id]: true
            }
          }
        : {
            errors: {
              phone: false,
              name: false,
              email: false,
              message: false,
              accept: false
            }
          }
    );
  };

  onClick = () =>
    this.setState({
      accept: !this.state.accept
    });

  render() {
    const { classes } = this.props;
    const {
      radio,
      phone,
      name,
      email,
      errors,
      message,
      accept,
      recaptchaClass,
      loading,
      classSnackbar,
      response
    } = this.state;
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <form onSubmit={this.onSubmit}>
              <GridItem xs={12} sm={12} md={12}>
                <FormControl component="fieldset" >
                    <FormLabel component="legend">Επιλέξτε για ποιά θέση ενδιαφέρεστε *</FormLabel>
                    <RadioGroup aria-label="gender" name="radio" value={radio} onChange={this.onChange}>
                        <FormControlLabel value="1" control={<Radio />} label="Τηλεφωνικό Κέντρο - Υποστήριξη Πελατών" />
                        <FormControlLabel value="2" control={<Radio />} label="Πωλήσεις και Marketing" />
                        <FormControlLabel value="3" control={<Radio />} label="Άλλο" />
                    </RadioGroup>
                </FormControl>
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <CustomInput
                  error={errors.name ? true : false}
                  required
                  type="text"
                  labelText="Όνομα και επώνυμο *"
                  id="name"
                  name="name"
                  value={name}
                  disabled={loading}
                  onChange={this.onChange}
                  onFocus={this.onFocus}
                  onBlur={this.onFocus}
                  formControlProps={{
                    fullWidth: true
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <CustomInput
                  error={errors.email ? true : false}
                  required
                  type="email"
                  labelText="Email *"
                  id="email"
                  name="email"
                  value={email}
                  onChange={this.onChange}
                  onFocus={this.onFocus}
                  onBlur={this.onFocus}
                  disabled={loading}
                  formControlProps={{
                    fullWidth: true
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <CustomInput
                  error={errors.phone ? true : false}
                  required
                  type="text"
                  labelText="Τηλέφωνο Επικοινωνίας *"
                  id="phone"
                  name="phone"
                  value={phone}
                  disabled={loading}
                  onChange={this.onChange}
                  onFocus={this.onFocus}
                  onBlur={this.onFocus}
                  helperText="Χρησιμοποίηστε τον αριθμό του κινητού σας χωρίς τον κωδικό χωράς π.χ 6988123412"
                  formControlProps={{
                    fullWidth: true
                  }}
                inputProps={{
                startAdornment: (
                    <InputAdornment position="start">+30</InputAdornment>
                )
                }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
              <CustomInput
                error={errors.message ? true : false}
                required
                type="text"
                labelText="Σχόλια - Παρατηρήσεις *"
                id="message"
                name="message"
                value={message}
                onChange={this.onChange}
                onFocus={this.onFocus}
                onBlur={this.onFocus}
                disabled={loading}
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  multiline: true,
                  rows: 5
                }}
              />
              </GridItem>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <FormControlLabel
                    control={
                      <GreenCheckbox
                        required
                        id="accept"
                        name="accept"
                        checked={accept}
                        onClick={this.onClick}
                        onChange={this.onChange}
                        disabled={loading}
                      />
                    }
                    label={
                      <Typography className={classes.labelCheckbox}>
                        Επιβεβαιώνω ότι έχω διαβάσει τη ρήτρα σχετικά με την επεξεργασία δεδομένων προσωπικού χαρακτήρα και συμφωνώ με την επεξεργασία των δεδομένων από την HELLAFON για το σκοπό που είναι απαραίτητο για το χειρισμό του ερωτήματος η την βελτίωση της υπηρεσίας. Έχω ενημερωθεί ότι η παροχή δεδομένων είναι εθελοντική, έχω το δικαίωμα να επιθεωρήσω το περιεχόμενο των δεδομένων και ζητώντας την διόρθωσή τους, ή τη διαγραφή τους.{" "}
                        <a href="/privacy">Πολιτική απορρήτου</a>
                      </Typography>
                    }
                  />
                </GridItem>
              </GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <div className={recaptchaClass}>
                  <ReCAPTCHA
                    size="normal"
                    render="explicit"
                    sitekey="6LeaOscUAAAAAN-jOw2ObPwk120dvuBmPlXWVxx4"
                    onloadCallback={this.onloadCaptcha}
                    verifyCallback={this.checkCaptacha}
                  />
                </div>
              </GridItem>

              <GridContainer className={classes.buttonContainer}>
                <GridItem
                  xs={12}
                  sm={12}
                  md={12}
                  className={classes.textCenter}
                >
                  <Button
                    type="submit"
                    color="primary"
                    disabled={loading}
                    variant="contained"
                  >
                    Αποστολή
                  </Button>
                  {loading && (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  )}
                </GridItem>
              </GridContainer>
          </form>
          <SnackbarContent
            className={classSnackbar}
            aria-describedby="client-snackbar"
            message={response}
          />
        </GridItem>
      </GridContainer>
    );
  }
}

JobSection.propTypes = {
  classes: PropTypes.object
};

export default withStyles(workStyle)(JobSection);
