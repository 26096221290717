import React from "react";
//import ReactPlayer from 'react-player';
// nodejs library to set properties for components
import PropTypes from "prop-types";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core/";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const ExpansionPanel = withStyles({
  root: {
    border: "1px solid rgba(0, 0, 0, .125)",
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(Accordion);

const ExpansionPanelSummary = withStyles({
  root: {
    backgroundColor: "rgba(0, 0, 0, .03)",
    borderBottom: "1px solid rgba(0, 0, 0, .125)",
    marginBottom: -1,
    minHeight: 56,
    "&$expanded": {
      minHeight: 56,
    },
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expanded: {},
})(AccordionSummary);

const ExpansionPanelDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(AccordionDetails);

class FAQSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: "panel0",
    };
    this.handleChange = this.handleChange.bind(this);
  }
  handleChange(panel, expanded) {
    this.setState(
      expanded === panel ? { expanded: "panel0" } : { expanded: panel }
    );
  }

  render() {
    const { expanded } = this.state;
    return (
      <div>
        <h3 style={{ textAlign: "center", color: "#0178e2" }}>
          Read Common Questions & Answers for Individuals
        </h3>
        <ExpansionPanel
          expanded={expanded === "panel3"}
          onChange={() => this.handleChange("panel3", expanded)}
        >
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3d-content"
            id="panel3d-header"
          >
            <Typography>
              How can I divert my calls ?  
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Typography component={"span"}>
              <div className="panel3">
                <CardMedia
                  component="img"
                  alt="Hellasfon Κινητή, Σταθερή Τηλεφωνία. Ολοκληρωμένες Υπηρεσίες Τηλεπικοινωνιών"
                  image={require("../../assets/img/faq/ektroph_klhshs1.jpg")}
                  title="Hellasfon Κινητή, Σταθερή Τηλεφωνία. Ολοκληρωμένες Υπηρεσίες Τηλεπικοινωνιών"
                /><br></br>
                Login at customer's web portal {" "}
                <a href="https://myaccount.hellasfon.com/HF">MY ACCOUNT</a>{" "}
                for individual users.
                <br />
                Choose <b>Profile</b> menu and then press <b>(+)</b> for <b>Call Divert</b><br />
                Here you must enter the number that you want calls to be diverted to.
                <br />
              </div>
            </Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel
          expanded={expanded === "panel4"}
          onChange={() => this.handleChange("panel4", expanded)}
        >
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel4d-content"
            id="panel14-header"
          >
            <Typography>
              How can I pay my bill and renew my talk time ? (free minutes)
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Typography component={"span"}>
              <div className="panel4">
                <CardMedia
                  component="img"
                  alt="Hellasfon Κινητή, Σταθερή Τηλεφωνία. Ολοκληρωμένες Υπηρεσίες Τηλεπικοινωνιών"
                  height="300"
                  image={require("../../assets/img/faq/forw4.png")}
                  title="Hellasfon Κινητή, Σταθερή Τηλεφωνία. Ολοκληρωμένες Υπηρεσίες Τηλεπικοινωνιών"
                /><br></br>
                When registering you can choose to pay your subscription automatically :
                <ol>
                  <ul>
                    <b>Payments</b> can be done by <b>Credit Card, Paypal or Bank Deposit.</b>
                  </ul>
                  <ul>
                    By completing registration and choosing your favourite plan , one monthly 
                    subscription is created for you.{" "}<p></p>
                  </ul>
                  <ul>
                    If you need to have extra talk-time (beyond your monthly plan) 
                    you need to buy extra credits ? . Credits never expire 
                    and your balance is transferred to next month.
                  </ul>
                  <ul>
                    ????Only when You pay your monthly plan Εξοφλείται το πακέτο σας μηνιαίως πληρώνοντας το πάγιο κάθε
                    μήνα, και μόνο όταν υπερβείτε τον δωρεάν χρόνο ομιλίας η
                    καλέσετε εκτός πακέτου καταναλώνονται η μονάδες.
                  </ul>
                  <ul>
                    By <b>PC</b> : Directly by entering {" "}
                    <a href="https://myaccount.hellasfon.com/HF">MY ACCOUNT</a>{" "}
                    , Click : <b>"My Account "</b> and then click on  
                    <b>"Renew Talk-time "</b>
                  </ul>
                  <ul>
                    By <b>Hellasfon App</b>: <b>Settings</b> -> click 
                    <b> Account</b> and then <b>Renew</b>
                  </ul>
                  <ul><br></br>
                    By BALANCE TRANSFER option, you can transfer money and credits 
                    to an other subscriber. Automatically and without additional 
                    charges you have the ability to transfer money to an other 
                    subscriber using your balance.
                  </ul>
                </ol>
                We accept PAYPAL, Credit Cards and Bank Deposits.  
              </div>
            </Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        
        <ExpansionPanel
          expanded={expanded === "panel8"}
          onChange={() => this.handleChange("panel8", expanded)}
        >
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel8d-content"
            id="panel8d-header"
          >
            <Typography>
              How can I become a HOME subscriber ?
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Typography component={"span"}>
              <div className="panel8">
                Hellasfon ΗΟΜΕ
                <ol>
                  <li>Fill in the Registration Form.</li>
                  <li>
                    Upon registration , you will receive an email that contains
                    your new number , your (username) & (password) needed
                    to access customer's portal through{" "}
                    <a href="https://myaccount.hellasfon.com/HF">PC</a>{" "}
                    and Hellasfon app.
                  </li>
                  <li>
                    Download Hellasfon app , and login using the (username) & (password)
                    send to you by email.
                  </li>
                  <li>Your service is ready to use !</li>
                </ol>
                At home :
                <ol>
                  <li>
                    All HELLASFON HOME - users can use 
                    Hellasfon app and their {" "}
                    <a href="https://myaccount.hellasfon.com/HF">PC</a>{" "}
                    for getting their services.
                  </li>
                  <li>
                    For using our service through a phone device 
                    an <a href="https://shop.hellasfon.com/"> IP device </a> is needed.
                  </li>
                  <li>
                    Connect your device with  
                    your ADSL/VDSL router.
                  </li>
                  <li>
                    All IP devices sold by Hellasfon are ready to use and do not
                    require any setup. They are send pre-programmed by us and are
                    ready to use.
                  </li>
                  <li>Service is ready on the same date! </li>
                </ol>
                For Identification your Number.
                <ol>
                  <li>
                    Pls send us an email with attached your ID (Front/Backside),
                    and a utility account (electricity,water,etc)
                    You can see analytical all documents needed for {" "}
                    <a href="/docs/GREEK-DID-REQUIREMENTS.pdf">
                     {" "}
                      ID Verification
                    </a>
                    .
                  </li>
                  <li>
                    For easier and shortier way pls send us your
                    identification data attached as files through FEEDBACK menu
                    of Hellasfon (HF) app.
                  </li>
                  <li>
                    Until the completion of your identification data, your choosen GREEK number
                    will be reserved and will remain reserved for you.
                  </li>
                  <li>
                    While waiting your identification data you can use normally
                    your services. During this stage, Hellasfon will use
                    temporary your existing number as callerid for your outbound calls
                    .
                  </li>
                  <li>
                    Upon process completion , your phone number will be
                    activated at your account!
                  </li>
                </ol>
              </div>
            </Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel
          expanded={expanded === "panel12"}
          onChange={() => this.handleChange("panel12", expanded)}
        >
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel12d-content"
            id="panel12d-header"
          >
            <Typography>
              What kind of Identification data are needed for reserving a Greek Number ?
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Typography component={"span"}>
              <div className="panel12">
                Last step to do according the Greek Authorities 
                is to identificate your data. For getting a Greek number you must 
                send us by Fax 2811-300308 or by email {" "}
                <a href="mailto:support@hellasfon.com">support@hellasfon.com</a>{" "}
                all the necessary legal documents. For easier and shorter use, take a photo of 
                take a photo of filled identification form and send us as attachments
                from FEEDBACK menu of the app.
                Upon completion of your identification, your number is activated 
                AUTOMATICALLY at your account.
                <a href="/docs/GREEK-DID-REQUIREMENTS.pdf">See here</a> all
                necessary legal documents needed for receiving a Greek number.
              </div>
            </Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel
          expanded={expanded === "panel13"}
          onChange={() => this.handleChange("panel13", expanded)}
        >
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel13d-content"
            id="panel13d-header"
          >
            <Typography>How can I fill the Portability Form ?</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Typography component={"span"}>
              <div className="panel13">
                <CardMedia
                  component="img"
                  alt="Hellasfon Κινητή, σταθερή τηλεφωνία. Ολοκληρωμένες Υπηρεσίες Τηλεπικοινωνιών"
                  image={require("../../assets/img/faq/hellasfon-foritotita-gr-1_013.jpg")}
                  title="Hellasfon Κινητή, σταθερή τηλεφωνία. Ολοκληρωμένες Υπηρεσίες Τηλεπικοινωνιών"
                />
                You can download Portability Form from here :{" "}
                <a href="/docs/hellasfon-foritotita-gr.pdf">
                  Portablity Form
                </a>
                .
              </div>
            </Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel
          expanded={expanded === "panel14"}
          onChange={() => this.handleChange("panel14", expanded)}
        >
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel14d-content"
            id="panel14d-header"
          >
            <Typography>Can I choose Greek or Internation Number ?</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Typography component={"span"}>
              <div className="panel14">
                <CardMedia
                  component="img"
                  alt="Hellasfon Κινητή, σταθερή τηλεφωνία. Ολοκληρωμένες Υπηρεσίες Τηλεπικοινωνιών"
                  image={require("../../assets/img/faq/arithoithl_014.jpg")}
                  title="Hellasfon Κινητή, σταθερή τηλεφωνία. Ολοκληρωμένες Υπηρεσίες Τηλεπικοινωνιών"
                />
                During your registration, a Greek Number is reserved for you. 
                You can buy extra Greek or Internation Numbers 
                from Hellasfon App or by Settings menu at individual customers web portal {" "}
                <a href="https://myaccount.hellasfon.com/HF">MY ACCOUNT</a> .
                Choose country, city and the number that you desire. Our App will 
                check availability and will reserve the number for you.
                Number Purchase is AUTOMATIC and IMMEDIATE and there is NO CONTRACT.
              </div>
            </Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel
          expanded={expanded === "panel15"}
          onChange={() => this.handleChange("panel15", expanded)}
        >
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel15d-content"
            id="panel15d-header"
          >
            <Typography>
              What is DID "Telephone Number" and what are benefits of it ?
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Typography component={"span"}>
              <div className="panel15">
                DID (Direct Inward Dial) describes a geographical phone number
                of a city or a region. Each phone number is a DID.
                All phone numbers are unique. By using Hellasfon you
                can have multiple numbers of Greece or abroad. 
                i.e. You have relatives or you often travell to UK. Buy a UK
                number! This way everybody from UK can call you ; local rates.
                There is no time limit or commitment , you can cancel it anytime 
                you want ! By using Hellasfon you have several advantages :
                <ol>
                  <li>
                    With your Greek / International number you can 
                    make/receive calls λαμβάνετε κλήσεις απευθείας στο σταθερό και την Hellasfon
                    (HF) εφαρμογή , κάνωντας το κινητό σας, σταθερό ώστε να μην
                    χάνετε κλήση .
                  </li>
                  <li>
                    By using HF App you take your Landline ON THE GO ! You
                    call from your mobile phone without mobile charges !{" "}
                  </li>
                  <li>
                    You call Greece with same low rates as a local, even when calling from abroad.
                  </li>
                  <li>NO Roaming extra charges.</li>
                  <li>Free all messages for your Voicemail.</li>
                  <li>
                    For the first time we combine fixed line,mobile and services of
                    CHAT, VIDEOcalls and SMS in 1 App.
                  </li>
                </ol>
                <b>Hellasfon is a win-win !</b>
              </div>
            </Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel
          expanded={expanded === "panel18"}
          onChange={() => this.handleChange("panel18", expanded)}
        >
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel18d-content"
            id="panel18d-header"
          >
            <Typography>How can I activate my Voicemail ?</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Typography component={"span"}>
              <div className="panel18">
                <CardMedia
                  component="img"
                  alt="Hellasfon Κινητή, Σταθερή Τηλεφωνία. Ολοκληρωμένες Υπηρεσίες Τηλεπικοινωνιών"
                  image={require("../../assets/img/faq/to_voicemail.jpg")}
                  title="Hellasfon Κινητή, Σταθερή Τηλεφωνία. Ολοκληρωμένες Υπηρεσίες Τηλεπικοινωνιών"
                /><br></br>
                Login to{" "}
                <a href="https://myaccount.hellasfon.com/HF">MY ACCOUNT</a> and 
                choose <b>Find me</b> menu. Follow the <b>3 steps</b> and choose to
                forward incoming calls to <b>Voicemail</b>.<br></br> When you will press <b>Save </b> 
                service will be activated automatically. For the voicemail service
                pls record your message by using customers portal {" "}
                <a href="https://myaccount.hellasfon.com/HF">MY ACCOUNT</a> or 
                by using the Hellasfon HF App. You can record multiple messages 
                for different numbers that call you.
                <b>HELLASFON features are endless !</b>
              </div>
            </Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel
          expanded={expanded === "panel19"}
          onChange={() => this.handleChange("panel19", expanded)}
        >
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel19d-content"
            id="panel19d-header"
          >
            <Typography>How can I see my detailed call charges of my account ? </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Typography component={"span"}>
              <div className="panel19">
                <b>By PC :</b><br />
                <ul>
                  <li>
                    Login to "
                    <a href="https://myaccount.hellasfon.com/HF">MY ACCOUNT</a>"
                    and choose "Account" to see all calls have been made as well
                    as charges applied.
                  </li>
                  <li>
                    You can also see your account balance , your payments , 
                    your calls history and administration for your account .
                  </li>
                </ul>
                <b>By HELLASFON App</b> all calls log and charges are always available :                :
                <ul>
                  <li>
                    When entering HF App click <b>settings </b> menu and then <b>Account </b> menu.
                  </li>
                  <li>
                    See all call charges and our full pricelist . You can choose additional talk-time bundles 
                    and pick up phone numbers from 65 countries worldwide.
                  </li>
                  <li>
                    Hellasfon App gives you all HOME services at your mobile.
                  </li>
                </ul>
              </div>
            </Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel
          expanded={expanded === "panel21"}
          onChange={() => this.handleChange("panel21", expanded)}
        >
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel21d-content"
            id="panel21d-header"
          >
            <Typography>
              How can i choose an extra Bundle ? 
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Typography component={"span"}>
              <div className="panel21">
                <CardMedia
                  component="img"
                  alt="Hellasfon Κινητή, Σταθερή Τηλεφωνία. Ολοκληρωμένες Υπηρεσίες Τηλεπικοινωνιών"
                  image={require("../../assets/img/faq/screenshot_2019-01-16_hellasfon_021.jpg")}
                  title="Hellasfon Κινητή, Σταθερή Τηλεφωνία. Ολοκληρωμένες Υπηρεσίες Τηλεπικοινωνιών"
                />
                During your registration , choose the plan that suits your needs.
                With our flexibility you can add additional talk-time bundles ,
                to your account for only 1 month or a specific period. From your PC :
                By{" "}
                <a href="https://myaccount.hellasfon.com/HF">MY ACCOUNT</a>
                All plans and offers are available in Hellasfon website and also 
                under MY ACCOUNT web portal ; "Subscriptions" menu.
                You choose the plan you desire and the type of payment.Upon completion,
                it is available and ready to use!. By mobile or tablet 
                using HF App: Settings -> Αccount -> Plans. 
				See all our available plans/bundles here. Choose the one that
                suits you and type of payment. To choose and buy bundles is very easy using only 
                2 clicks. Upon completion , it is available and ready to use!.
              </div>
            </Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel
          expanded={expanded === "panel22"}
          onChange={() => this.handleChange("panel22", expanded)}
        >
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel22d-content"
            id="panel22d-header"
          >
            <Typography>
              What type of services can i find by using MY ACCOUNT portal that are
              provided to Hellasfon customers ?
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Typography component={"span"}>
              <div className="panel22">
                At MY ACCOUNT portal you will find all the following tools for your 
                communication :<br />
                <ul>
                  <li>
                    Calls History - Incoming, Outgoing and Non-answered calls.
                  </li>
                  <li>Messages Send.</li>
                  <li>
                    Record and customize your voicemail
                  </li>
                  <li>Divert Calls.</li>
                  <li>
                    Synchronize mobile's contacts with Hellasfon Portal and HF Apps
                    for easy dialling and chat with all contacts.
                  </li>
                  <li>
                    Direct Chat through MY ACCOUNT at pc & HF App
                    for all mobile contacts - Fax to Email , Email to Fax.
                  </li>
                  <li>
                    Balance transfer - Transfer your balance to other HellasFon users.
                    (Μοίρασε τον χρόνο ομιλίας με φίλους και γνωστούς
                    με ένα κλικ .?)
                  </li>
                  <li>FREE CALLS to ALL HELLASFON subscribers.</li>
                  <li>
                    MOBILE TOP UP (?) - καινούργια υπηρεσία που θα σου επιτρέπει να
                    προσθέτεις μονάδες κινητής τηλεφωνίας σε οποιοδήποτε κινητό
                    ανεξαρτήτως πάροχο, παγκοσμίως. ΕΡΧΕΤAI - σύντομα{" "}
                  </li>
                  <li>FREE CHAT to ALL HELLASFON subscribers.</li>
                  <li>FREE VIDEOCALLS to ALL HELLASFON subscribers.</li>
                  <li>DO NOT DISTURB . Block unwanted numbers by one click. That easy.</li>
                  <li>Profile for each user.</li>
                  <li>Contacts List.</li>
                  <li>Divert Calls.</li>
                  <li>Personal Voicemail.</li>
                  <li>Missed Calls.</li>
                  <li>
                    Purcase multiple Greek Numbers or International Numbers
                    at the same account.
                  </li>
                  <li>Speed Dials.</li>
                  <li>Incoming Calls Management and Call features.</li>
                  <li>Full pricelist ONLINE.</li>
                  <li>Τop-up/Renew talk time.</li>
                </ul>
              </div>
            </Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel
          expanded={expanded === "panel23"}
          onChange={() => this.handleChange("panel23", expanded)}
        >
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel23d-content"
            id="panel23d-header"
          >
            <Typography>
              I don't have a smartphone, can i use my Hellasfon account as a Calling Card when on the go ?
           
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Typography component={"span"}>
              <div className="panel23">
                When using our services as a Calling Card, pls follow these steps :
                <ol>
                  <li>
                    In order to use " Calling Card" you must set a numeric 
					(Pin) for your account.
                  </li>
                  <li>
                    You dial the Toll Free number depending your location. ( στην
                    (When in Greece call Greek number, when UK call UK number ).
                  </li>
                  <li>Type in your Pin when asked for .</li>
                  <li>Dial the number that you want .</li>
                </ol>
                HELLASFON Numbers:
                <br />
                <br />
                -USA/CANADA -Toll Free- 1-844-273-4478
                <br />
                -UK -Toll Free- 448002494307
                <br />
                -GREECE -Toll Free- 8078021
                <br />
                -CYPRUS -Toll Free- 80080011
                <br />
              </div>
            </Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel
          expanded={expanded === "panel24"}
          onChange={() => this.handleChange("panel24", expanded)}
        >
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel24d-content"
            id="panel24d-header"
          >
            <Typography>
              How can I call somebody who have not HellasFon (HF) App,
              make a call to Landline or a destination abroad ?
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Typography component={"span"}>
              <div className="panel24">
                Between Hellasfon subscribers all calls , messages ,
                Videocalls and Chat is always FREE.
                <br />
                Η HELLASFON is σας προσφέρει χρήση της υπηρεσίας, with 3 ways.
                PC - through MY ACCOUNT portal, Mobile - through HF App,
                and your fixed phone.
                <br />
                In every case , you can call subscribers of other networks, inside 
                and outside of Greece you just type desirable number.
                
                <br />
                First, download and install HELLASFON App at your mobile.
                Then start using your pc , Hellasfon App or your Phone Device 
                <br />
                you can call ANY number , WORLDWIDE avoiding 
                expensive fees of other providers. <br />
                <br />
                <br />
                <b>By using Hellasfon you are always a winner!</b>
              </div>
            </Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel
          expanded={expanded === "panel25"}
          onChange={() => this.handleChange("panel25", expanded)}
        >
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel25d-content"
            id="panel25d-header"
          >
            <Typography>
              What is HELLASFON Virtual Softphone ?
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Typography component={"span"}>
              <div className="panel25">
                <CardMedia
                  component="img"
                  alt="Hellasfon Κινητή, Σταθερή Τηλεφωνία. Ολοκληρωμένες Υπηρεσίες Τηλεπικοινωνιών"
                  image={require("../../assets/img/faq/screenshot_2019-01-16_evcmobi_025.jpg")}
                  height="500"
                  width="350"
                  title="Hellasfon Κινητή, Σταθερή Τηλεφωνία. Ολοκληρωμένες Υπηρεσίες Τηλεπικοινωνιών"
                />
                HELLASFON Virtual Softphone is a virtual phone , build in &
                located in MY ACCOUNT portal. You don't have to install it
                and is always available for use. Really convenient for people
                that travel a lot or move ON THE GO. 
				Services like ( Video, Calls, and Messages ) direct from HELLASFON
                MY ACCOUNT portal. Login from your pc at  "
                <a href="https://myaccount.hellasfon.com/HF">myaccount</a>" and 
                click on the phone icon located at right side of your screen. 
				It is really simple and easy to use. You will need headphones and a microphone.
              </div>
            </Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel
          expanded={expanded === "panel26"}
          onChange={() => this.handleChange("panel26", expanded)}
        >
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel26d-content"
            id="panel26d-header"
          >
            <Typography>
              What is HELLASFON Home Telephony; Can I have HELLASFON also to my home ?
            
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Typography component={"span"}>
              <div className="panel26">
                Connect HELLASFON Smart Box ( Grandstream HT801) with your router
                and your telephone device to Smart Box. <br />
                Call everywhere. Download at your mobile the Free 
                Hellasfon App , to transform your fixed phone to a mobile phone <br />
                Fixed Phone and mobile phone has become One thanks to Hellasfon !
				Κάλεσε από          <br />
                το κινητό δωρεάν χρησιμοποιώντας την HF εφαρμογή. ALL
                HELLASFON services require internet access .
                <br />
              </div>
            </Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel
          expanded={expanded === "panel28"}
          onChange={() => this.handleChange("panel28", expanded)}
        >
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel28d-content"
            id="panel28d-header"
          >
            <Typography>
              How can I make a call if i don't have my mobile and I am not at home ?
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Typography component={"span"}>
              <div className="panel28">
                Hellasfon offers CALLING CARD functionalities to our clients. See how it 
                works :<br />
                <br />
                -For the " CALLING CARD " functionality you must set a numeric Pin
                for your account.
                <br />
                -Call Toll Free Number according to your location. 
                (i.e. in Greece call Greek number, in UK call UK number ).
                <br />
                -Follow the steps heard from the Automate Assistance, enter the number you want to call and also your Pin code.
                <br />
                - First time that you will use this service , you will have to type your pin 
                when asked from the Automate Assistance.
                <br />
                -Next time , we will recognize you by your (callerid) number 
                info so we will not ask you again your Pin code <br />
                - This service can identify customer by callerid number used.
                <br />
                If you call the Toll Free number from a different number, 
                you wll need to type again your Pin Code.  All Phone numbers that you use to call 
                <br />
                our Toll Free Numbers are stored to your Personal Account 
                in order for you to make calls without entering Pin Code each time.
                <br />
                <br />
                - You may delete all numbers that you called from , by 
                logging to "MY ACCOUNT" web portal.
                <br />
                <br />
                CALLING CARDS TOLL FREE Numbers:
                <br />
                <br />
                -USA/CANADA -Toll Free- 1-844-273-4478
                <br />
                -UK -Toll Free- 448002494307
                <br />
                -GREECE -Toll Free- 8078021
                <br />
                -CYPRUS -Toll Free- 80080011
                <br />
              </div>
            </Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </div>
    );
  }
}

FAQSection.propTypes = {
  classes: PropTypes.object,
};

export default FAQSection;
