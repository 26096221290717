import { GET_PBX_PLAN_PACKS, SELECT_PLAN_PACK, GET_PLANS, VERIFY_MOBILE, REMOVE_ORDER,  VERIFY_CODE, CREATE_ORDER,  GET_LOCAL_ARES, APP_LOADING } from "./types";
import axios from "axios";

export function loadingFn(loading) {
  return dispatch => {
    dispatch({
      type: APP_LOADING,
      payload: loading
    })
  };
} 

export const updateDirectOrderPBX = ( verify_mobile,
  plans, 
  users, 
  local_area) => async () => {
    const content = {
      plans: plans,
      order_id: verify_mobile.order_id,
      dsfsafw352: verify_mobile.dsfsafw352,
      local_area: local_area,
      users: users
    };
  const login = {
    dfa3424: "daDAS!@DFSGHFGJ(*)hGdffser4324765~hfgh"
  };
  let res = await axios.post(
    `https://sip1.easyvoipcall.com/pbxres/api/fsdffgdg670dbmfsfv90cxxiy`,
    login
  );
  const header = {
    Authorization: "Bearer " + res.data.dfa3424
  };
  try {
    res = await axios.post(
      `https://sip1.easyvoipcall.com/pbxres/api/fsdgfds6345gspbx`,
      content,
      { headers: header }
    );
  } catch (e) {
    await axios.post(
      `https://sip1.easyvoipcall.com/pbxres/api/jfg5345hfgh4545b4234234gv`,
      "",
      { headers: header }
    );
    return e;
  }
  await axios.post(
    `https://sip1.easyvoipcall.com/pbxres/api/jfg5345hfgh4545b4234234gv`,
    "",
    { headers: header }
  );
};

export const updateDirectOrderPBX_old = ( verify_mobile,
  plans, 
  users, 
  top_up, 
  local_area) => async () => {
    const content = {
      plans: plans,
      top_up: top_up,
      order_id: verify_mobile.order_id,
      dsfsafw352: verify_mobile.dsfsafw352,
      local_area: local_area,
      users: users
    };
  const login = {
    dfa3424: "daDAS!@DFSGHFGJ(*)hGdffser4324765~hfgh"
  };
  let res = await axios.post(
    `https://sip1.easyvoipcall.com/pbxres/api/fsdffgdg670dbmfsfv90cxxiy`,
    login
  );
  const header = {
    Authorization: "Bearer " + res.data.dfa3424
  };
  try {
    res = await axios.post(
      `https://sip1.easyvoipcall.com/pbxres/api/fsdgfds6345gspbx`,
      content,
      { headers: header }
    );
  } catch (e) {
    await axios.post(
      `https://sip1.easyvoipcall.com/pbxres/api/jfg5345hfgh4545b4234234gv`,
      "",
      { headers: header }
    );
    return e;
  }
  await axios.post(
    `https://sip1.easyvoipcall.com/pbxres/api/jfg5345hfgh4545b4234234gv`,
    "",
    { headers: header }
  );
};

export const getPBXPlanPacks = () => async dispatch => {

  const res = await axios.post(
    `https://sip1.easyvoipcall.com/pbxres/api/get_pbx_plan_packs`,
    ""
  );
  dispatch({
    type: GET_PBX_PLAN_PACKS,
    payload: res.data
  });
};
export const getlocalAreas = () => async dispatch => {
  const login = {
    dfa3424: "daDAS!@DFSGHFGJ(*)hGdffser4324765~hfgh"
  };
  let res = await axios.post(
    `https://sip1.easyvoipcall.com/pbxres/api/fsdffgdg670dbmfsfv90cxxiy`,
    login
  );
  const header = {
    Authorization: "Bearer " + res.data.dfa3424
  };
  res = await axios.post(
    `https://sip1.easyvoipcall.com/pbxres/api/get_local_areas_pbx`,
    "",
    { headers: header }
  );
  dispatch({
    type: GET_LOCAL_ARES,
    payload: res.data
  });
  await axios.post(
    `https://sip1.easyvoipcall.com/pbxres/api/jfg5345hfgh4545b4234234gv`,
    "",
    { headers: header }
  );
};
export const verifyCode = (
  order_id,
  token,
  code
) => async dispatch => {
  const content = {
    id_order: order_id,
    dsfsafw352: token,
    code: code
  };
  const login = {
    dfa3424: "daDAS!@DFSGHFGJ(*)hGdffser4324765~hfgh"
  };
  let res = await axios.post(
    `https://sip1.easyvoipcall.com/pbxres/api/fsdffgdg670dbmfsfv90cxxiy`,
    login
  );
  const header = {
    Authorization: "Bearer " + res.data.dfa3424
  };
  try {
    res = await axios.post(
      `https://sip1.easyvoipcall.com/pbxres/api/gfsd56hgfPCS45646fgd`,
      content,
      { headers: header }
    );
    dispatch({
      type: VERIFY_CODE,
      payload: res.data
    });
  } catch (e) {
    await axios.post(
      `https://sip1.easyvoipcall.com/pbxres/api/jfg5345hfgh4545b4234234gv`,
      "",
      { headers: header }
    );
    return e;
  }
  await axios.post(
    `https://sip1.easyvoipcall.com/pbxres/api/jfg5345hfgh4545b4234234gv`,
    "",
    { headers: header }
  );
};

export const removeOrder = (order_id, token) => async dispatch => {
  const content = {
    id_order: order_id,
    dsfsafw352: token
  };
  const login = {
    dfa3424: "daDAS!@DFSGHFGJ(*)hGdffser4324765~hfgh"
  };
  let res = await axios.post(
    `https://sip1.easyvoipcall.com/pbxres/api/fsdffgdg670dbmfsfv90cxxiy`,
    login
  );
  const header = {
    Authorization: "Bearer " + res.data.dfa3424
  };
  try {
    res = await axios.post(
      `https://sip1.easyvoipcall.com/pbxres/api/dfasd4234FDSd23`,
      content,
      { headers: header }
    );
    dispatch({
      type: REMOVE_ORDER,
      payload: res.data
    });
  } catch (e) {
    await axios.post(
      `https://sip1.easyvoipcall.com/pbxres/api/jfg5345hfgh4545b4234234gv`,
      "",
      { headers: header }
    );
    return e;
  }
  await axios.post(
    `https://sip1.easyvoipcall.com/pbxres/api/jfg5345hfgh4545b4234234gv`,
    "",
    { headers: header }
  );
};
export const selectPlanPack = id => async dispatch => {
  const id_plan_pack = {
    id_plan_pack: id
  };
  const res = await axios.post(
    `https://sip1.easyvoipcall.com/pbxres/api/get_pbx_plan_pack`,
    id_plan_pack
  );
  dispatch({
    type: SELECT_PLAN_PACK,
    payload: res.data
  });
};

export const getPlans = () => async dispatch => {
  const res = await axios.post(
    `https://sip1.easyvoipcall.com/pbxres/api/get_pbx_plans`,
   ""
  );
  dispatch({
    type: GET_PLANS,
    payload: res.data
  });
 
};
export const verifyMobilePBX = (
  company,
  vat,
  firstName,
  lastName,
  email,
  phonemobile,
  phone,
  address,
  tk,
  pbx_plan_pack,
  city,
  aoy
) => async dispatch => {
  const content = {
    company: company,
    vat: vat,
    firstName: firstName,
    lastName: lastName,
    email: email,
    phonemobile: phonemobile,
    phone: phone,
    address: address,
    tk: tk,
    id_plan_pack: pbx_plan_pack.id_plan_pack,
    name: pbx_plan_pack.name,
    startup_cost: pbx_plan_pack.startup_cost,
    period_cost: pbx_plan_pack.period_cost,
    city: city,
    aoy: aoy
  };
  const login = {
    dfa3424: "daDAS!@DFSGHFGJ(*)hGdffser4324765~hfgh"
  };
  let res = await axios.post(
    `https://sip1.easyvoipcall.com/pbxres/api/fsdffgdg670dbmfsfv90cxxiy`,
    login
  );
  const header = {
    Authorization: "Bearer " + res.data.dfa3424
  };
  try {
    res = await axios.post(
      `https://sip1.easyvoipcall.com/pbxres/api/verify_mobile_pbx`,
      content,
      { headers: header }
    );
    dispatch({
      type: VERIFY_MOBILE,
      payload: res.data
    });
  } catch (e) {
    await axios.post(
      `https://sip1.easyvoipcall.com/pbxres/api/jfg5345hfgh4545b4234234gv`,
      "",
      { headers: header }
    );
    return e;
  }
  await axios.post(
    `https://sip1.easyvoipcall.com/pbxres/api/jfg5345hfgh4545b4234234gv`,
    "",
    { headers: header }
  );
};
export const createOrderPBX = (
  verify_mobile,
  users, 
  plans,
  local_area,
  content,
  value_radio
) => async dispatch => {
  const params = {
    order_id: verify_mobile.order_id,
    dsfsafw352: verify_mobile.dsfsafw352,
    local_area: local_area,
    users: users,
    plans: plans,
    content: content,
    value_radio: value_radio
  };
  const login = {
    dfa3424: "daDAS!@DFSGHFGJ(*)hGdffser4324765~hfgh"
  };
  let res = await axios.post(
    `https://sip1.easyvoipcall.com/pbxres/api/fsdffgdg670dbmfsfv90cxxiy`,
    login
  );
  const header = {
    Authorization: "Bearer " + res.data.dfa3424
  };
  try {
    res = await axios.post(
      `https://sip1.easyvoipcall.com/pbxres/api/fsd234hgfh56PSX7mjhkj97`,
      params,
      { headers: header }
    );
    dispatch({
      type: CREATE_ORDER,
      payload: res.data
    });
  } catch (e) {
    await axios.post(
      `https://sip1.easyvoipcall.com/pbxres/api/jfg5345hfgh4545b4234234gv`,
      "",
      { headers: header }
    );
    return e;
  }
  await axios.post(
    `https://sip1.easyvoipcall.com/pbxres/api/jfg5345hfgh4545b4234234gv`,
    "",
    { headers: header }
  );
};

export const createOrderPBX_old = (
  verify_mobile,
  plans, 
  users, 
  top_up, 
  local_area
) => async dispatch => {
  const content = {
    plans: plans,
    top_up: top_up,
    order_id: verify_mobile.order_id,
    dsfsafw352: verify_mobile.dsfsafw352,
    local_area: local_area,
    users: users
  };
  const login = {
    dfa3424: "daDAS!@DFSGHFGJ(*)hGdffser4324765~hfgh"
  };
  let res = await axios.post(
    `https://sip1.easyvoipcall.com/pbxres/api/fsdffgdg670dbmfsfv90cxxiy`,
    login
  );
  const header = {
    Authorization: "Bearer " + res.data.dfa3424
  };
  try {
    res = await axios.post(
      `https://sip1.easyvoipcall.com/pbxres/api/fsd234hgfh56PSX7mjhkj97`,
      content,
      { headers: header }
    );
    dispatch({
      type: CREATE_ORDER,
      payload: res.data
    });
  } catch (e) {
    await axios.post(
      `https://sip1.easyvoipcall.com/pbxres/api/jfg5345hfgh4545b4234234gv`,
      "",
      { headers: header }
    );
    return e;
  }
  await axios.post(
    `https://sip1.easyvoipcall.com/pbxres/api/jfg5345hfgh4545b4234234gv`,
    "",
    { headers: header }
  );
};
