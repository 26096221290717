import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import withStyles from "@material-ui/core/styles/withStyles";
import { TinyButton as ScrollUpButton } from "react-scroll-up-button";

// core components
import Header from "components/Header/Header.jsx";
import Parallax from "components/Parallax/Parallax.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Footer from "components/Footer/Footer.jsx";

import HeaderLinks from "components/Header/HeaderLinks.jsx";
import HeaderTopLinks from "components/Header/HeaderTopLinks.jsx";
import CircularProgress from "@material-ui/core/CircularProgress";

import landingPageStyle from "assets/jss/material-kit-react/views/landingPage.jsx";

import AppSection from "./Sections/AppSection.jsx";
import VisaSection from "./Sections/VisaSection.jsx";
//import RetailSection from "./Sections/RetailSection.jsx";
//import RateSection from "./Sections/RateSection.jsx";
import CheckoutSection from "./Sections/CheckoutSection.jsx";
import CheckoutSectionSIP from "./Sections/CheckoutSectionSIP.jsx";
import CookieConsent from "react-cookie-consent";

import { connect } from "react-redux";
import { selectPlanPack, getPlans, checkOrder, loadingFn } from "actions/retailActions";

class RegPage extends React.Component {
  async componentDidMount() {
    window.scrollTo(0, 0);
    const { pattern, id, order, redirect } = this.props.match.params;
    this.props.loadingFn(true);
    await this.props.selectPlanPack(id, pattern);
    await this.props.getPlans(pattern);
    if ((order !== undefined && redirect === 'cancel') || (order !== undefined && redirect === 'sucess')) {
      this.props.loadingFn(true);
      await this.props.checkOrder(order);
    } 
  }

  render() {
    const { classes, retail_plan_pack, loading, check_order, check_order_items, ...rest } = this.props;
    const { redirect, order, pattern, operation } = this.props.match.params;
    if (retail_plan_pack.name === undefined && !loading ) {
      window.location = "/notFound";
    } else if ((redirect === 'cancel' && !check_order && !loading)  || (redirect === 'sucess' && !check_order && !loading ) || (redirect === 'banktransfer' && !check_order )) {
       window.location = "/notFound";
    }
    return (
      <div>
        {loading? (<div style={{ 
      position: "fixed", 
      zIndex: "2000", 
      paddingTop: "100px",
      left: "0",
      top: "0",
      right: "0",
      bottom: "0",
      width: "100%", 
      height: "100%", 
      overflow: "auto",
      backgroundColor: "rgba(0,0,0,0.4)"
      }} />
        ) : null}
        <Header
          topLinks={<HeaderTopLinks />}
          rightLinks={<HeaderLinks />}
          fixed
          logoUrl="/img/hellasfon_logo_white.jpg"
          color="primary"
          changeColorOnScroll={{
            height: 50,
            color: "white"
          }}
          {...rest}
        />
        <Parallax color small />
        <div className={classNames(classes.main, classes.mainRaised)}>
          <h1
            style={{
              textAlign: "center",
              backgroundColor: "#005FB4",
              color: "white"
            }}
          >
            {pattern === "retail"
              ? "Εγγραφή Ιδιώτη στην Hellasfon"
              : "Εγγραφή στα πακέτα κατηγορίας"}
          </h1>
          <div className={classes.container} style={{marginTop: "0"}}>
            {loading ? ( <GridContainer justify="center">
                <CircularProgress size={50} />
              </GridContainer>
            ) : pattern === "retail" ? (
              <CheckoutSection plan_pack={retail_plan_pack} redirect={redirect} order={check_order? check_order : order} items={check_order_items} pattern={pattern} operation={operation}/>
            ) : pattern === "sip" ? (
              <CheckoutSectionSIP plan_pack={retail_plan_pack} redirect={redirect} order={check_order? check_order : order} items={check_order_items} pattern={pattern} operation={operation}/>
            ) : (
              (window.location = "/notFound")
            )}
          </div>
          <AppSection />
          <VisaSection />
          <Footer />
        </div>
        <ScrollUpButton />
        <CookieConsent
            location="bottom"
            buttonText="ΟΚ συμφωνώ"
            cookieName="hellasfonCookie"
            style={{ background: "rgb(1, 120, 226)" }}
            buttonStyle={{ background: "#f50057", color: "white", fontSize: "15px", marginRight: "130px" }}
            expires={150}
        >
            Χρησιμοποιούμε cookies σε αυτόν τον ιστότοπο για να βελτιώσουμε την εμπειρία των χρηστών.{" "}
            <span style={{ fontSize: "12px" }}>
            Κάνοντας κλικ σε οποιονδήποτε σύνδεσμο σε αυτή τη σελίδα δίνετε τη συγκατάθεσή σας για να ορίσουμε cookies.{" "}
            <a style={{color: "white", textDecoration: "underline"}} href="/privacy">Περισσότερες πληροφορίες</a>
            </span>
            
        </CookieConsent>
      </div>
    );
  }
}

RegPage.propTypes = {
  classes: PropTypes.object
};

const mapStateToProps = state => ({
  retail_plan_pack: state.retail_reducer.retail_plan_pack,
  check_order: state.retail_reducer.check_order,
  check_order_items: state.retail_reducer.check_order_items,
  loading: state.retail_reducer.loading
});

export default connect(
  mapStateToProps,
  { selectPlanPack, getPlans, checkOrder, loadingFn }
)(withStyles(landingPageStyle)(RegPage));
