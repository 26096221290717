/*eslint-disable*/
import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { withStyles } from "@material-ui/core";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Typography from "@material-ui/core/Typography";
//import Link from '@material-ui/core/Link';
import Fab from "@material-ui/core/Fab";

import footerStyle from "assets/jss/material-kit-react/components/footerStyle.jsx";

function Footer({ ...props }) {
  const { classes, whiteFont } = props;
  const footerClasses = classNames({
    [classes.footer]: true,
    [classes.footerWhiteFont]: whiteFont,
  });
  const aClasses = classNames({
    [classes.a]: true,
    [classes.footerWhiteFont]: whiteFont,
  });
  return (
    <footer className={footerClasses}>
      <div className={classes.container}>
        <div className={classes.center}>
          <GridContainer style={{ marginTop: "0", textAlign: "left" }}>
            <GridItem xs={12} sm={12} md={3}>
              <Typography variant="h6">HOME</Typography>
              <Typography
                component="li"
                variant="subtitle2"
                style={{ listStyle: "none", padding: "5px 0 0 5px" }}
              >
                <a href="/rates" className={aClasses}>
                  Τιμοκατάλογοι
                </a>
              </Typography>
              <Typography
                component="li"
                variant="subtitle2"
                style={{ listStyle: "none", padding: "5px 0 0 5px" }}
              >
                <a href="/didnumbers" className={aClasses}>
                  Αριθμοδότηση
                </a>
              </Typography>
              <Typography
                component="li"
                variant="subtitle2"
                style={{ listStyle: "none", padding: "5px 0 0 5px" }}
              >
                <a
                  href="/docs/hellasfon-foritotita-gr.pdf"
                  className={aClasses}
                >
                  Αίτηση Φορητότητας
                </a>
              </Typography>
              <Typography
                component="li"
                variant="subtitle2"
                style={{ listStyle: "none", padding: "5px 0 0 5px" }}
              >
                <a
                  href="/docs/DID-requirements-in -Greek.pdf"
                  className={aClasses}
                >
                  Στοιχεία Ταυτοποίησης
                </a>
              </Typography>
			  <Typography
                component="li"
                variant="subtitle2"
                style={{ listStyle: "none", padding: "5px 0 0 5px" }}
              >
                <a
                  href="/docs/MHTROO11GR.pdf"
                  className={aClasses}
                >
                  Μητρώο του Άρθρου 11
                </a>
              </Typography>
              <Typography
                component="li"
                variant="subtitle2"
                style={{ listStyle: "none", padding: "5px 0 0 5px" }}
              >
                <a href="/docs/EVCOFFICE_gr_manual.pdf" className={aClasses}>
                  EVCOFFICE εγχειρίδιο
                </a>
              </Typography>
              <Typography
                component="li"
                variant="subtitle2"
                style={{ listStyle: "none", padding: "5px 0 0 5px" }}
                          
              >
                <a href="/eula" className={aClasses}>
                  
                </a>
              </Typography>
            </GridItem>
            <GridItem xs={12} sm={12} md={3}>
              <Typography variant="h6">ΥΠΗΡΕΣΙΕΣ</Typography>
              <ul style={{ margin: "0", padding: "0" }}>
                <Typography
                  component="li"
                  variant="subtitle2"
                  style={{ listStyle: "none", padding: "5px 0 0 5px" }}
                >
                  <a href="/retail" className={aClasses}>
                    Για Ιδιώτες
                  </a>
                </Typography>
                <Typography
                  component="li"
                  variant="subtitle2"
                  style={{ listStyle: "none", padding: "5px 0 0 5px" }}
                >
                  <a href="/siptrunk" className={aClasses}>
                    Τηλεφωνικές γραμμές & SIP TRUNK
                  </a>
                </Typography>
                <Typography
                  component="li"
                  variant="subtitle2"
                  style={{ listStyle: "none", padding: "5px 0 0 5px" }}
                >
                  <a href="/cloudpbx" className={aClasses}>
                    EVCOFFICE Cloud Τηλεφ. κέντρο
                  </a>
                </Typography>
                <Typography
                  component="li"
                  variant="subtitle2"
                  style={{ listStyle: "none", padding: "5px 0 0 5px" }}
                >
                  <a href="/faq" className={aClasses}>
                    Συχνές ερωτήσεις
                  </a>
                </Typography>
                              
         		<Typography
                  component="li"
                  variant="subtitle2"
                  style={{ listStyle: "none", padding: "5px 0 0 5px" }}
                >
                  <a href="/docs/aithsh_home.pdf" className={aClasses}>
                    Αίτηση Hellasfon Home
                  </a>
                </Typography>
                                
                <Typography
                  component="li"
                  variant="subtitle2"
                  style={{ listStyle: "none", padding: "5px 0 0 5px" }}
                >
                  <a href="/docs/aithsh_pbx.pdf" className={aClasses}>
                    Αίτηση EVCOFFICE PBX
                  </a>
                </Typography>
				<Typography
                  component="li"
                  variant="subtitle2"
                  style={{ listStyle: "none", padding: "5px 0 0 5px" }}
                >
                  <a href="/docs/aithsh_siptrunk.pdf" className={aClasses}>
                    Αίτηση Business SIP Trunk
                  </a>
                </Typography>
              </ul>
            </GridItem>
            <GridItem xs={12} sm={12} md={3}>
              <Typography variant="h6">Η ΕΤΑΙΡΙΑ</Typography>
              <ul style={{ margin: "0", padding: "0" }}>
                <Typography
                  component="li"
                  variant="subtitle2"
                  style={{ listStyle: "none", padding: "5px 0 0 5px" }}
                >
                  <a href="/company" className={aClasses}>
                    Η εταιρία
                  </a>
                </Typography>
                <Typography
                  component="li"
                  variant="subtitle2"
                  style={{ listStyle: "none", padding: "5px 0 0 5px" }}
                >
                  <a
                    href="/docs/Hellasfon_partner_and_platform_certifications_and_awards.pdf"
                    className={aClasses}
                  >
                    Σχετικά με μας
                  </a>
                </Typography>
                
                <Typography
                  component="li"
                  variant="subtitle2"
                  style={{ listStyle: "none", padding: "5px 0 0 5px" }}
                >
                  <a href="/job" className={aClasses}>
                    Θέσεις Εργασίας
                  </a>
                </Typography>
                <Typography
                  component="li"
                  variant="subtitle2"
                  style={{ listStyle: "none", padding: "5px 0 0 5px" }}
                >
                  <a href="/resellers" className={aClasses}>
                    Συνεργάτες/Affiliates
                  </a>
                </Typography>
                <Typography
                  component="li"
                  variant="subtitle2"
                  style={{ listStyle: "none", padding: "5px 0 0 5px" }}
                >
                  <a href="/privacy-policy" className={aClasses}>
                    Πολιτική Απορρήτου
                  </a>
                </Typography>
				<Typography
					component="li"
					variant="subtitle2"
					style={{ listStyle: "none", padding: "5px 0 0 5px" }}
				>
					<a href="/eula" className={aClasses}>
						Όροι Χρήσης Υπηρεσιών
					</a>
				</Typography>
                <Typography
                  component="li"
                  variant="subtitle2"
                  style={{ listStyle: "none", padding: "5px 0 0 5px" }}
                >
                  <a href="/contact" className={aClasses}>
                    Επικοινωνία
                  </a>
                </Typography>
              </ul>
            </GridItem>
            <GridItem xs={12} sm={12} md={3}>
              <Typography variant="h6">ΒΡΕΙΤΕ ΜΑΣ</Typography>
              <Typography variant="body1">
                Ιδομενέως 26, Ηράκλειο, Κρήτη, Ελλάδα, T.K. 71202,
                <br />
				Αρ. Γ.Ε.ΜΗ 077124727000< br/>< br/>
                Τηλ.:{" "}
                <a className={aClasses} href="tel:302811300307">
                  +302811300307
                </a>
                <br />
                Fax: +302811300308
              </Typography>
              <br />
              <div className={classes.socail}>
                <Fab
                  target="_blank"
                  href="https://www.facebook.com/hellasfon/"
                  color="inherit"
                  aria-label="facebook"
                  style={{ marginRight: "2px", marginTop: "2px" }}
                >
                  <i style={{ fontSize: "25px" }} className="fab fa-facebook" />
                </Fab>
                <Fab
                  target="_blank"
                  href="https://twitter.com/hellasfon"
                  color="inherit"
                  aria-label="twitter"
                  style={{ marginRight: "2px", marginTop: "2px" }}
                >
                  <i style={{ fontSize: "25px" }} className="fab fa-twitter" />
                </Fab>
                <Fab
                  target="_blank"
                  href="https://www.instagram.com/hellasfonnetworks/"
                  color="inherit"
                  aria-label="instagram"
                  style={{ marginRight: "2px", marginTop: "2px" }}
                >
                  <i
                    style={{ fontSize: "25px" }}
                    className="fab fa-instagram"
                  />
                </Fab>
                <Fab
                  target="_blank"
                  href="https://www.linkedin.com/in/hellasfon-networks-sa-984272173/"
                  color="inherit"
                  aria-label="linkedin"
                  style={{ marginRight: "2px", marginTop: "2px" }}
                >
                  <i style={{ fontSize: "25px" }} className="fab fa-linkedin" />
                </Fab>
              </div>
            </GridItem>
          </GridContainer>
        </div>
        <div className={classes.center}>
          All rights reserved &copy; {1900 + new Date().getYear()}{" "}
          <a href="/" className={aClasses}>
            Hellasfon Networks S.A.
          </a>
        </div>
      </div>
    </footer>
  );
}

Footer.propTypes = {
  classes: PropTypes.object.isRequired,
  whiteFont: PropTypes.bool,
};

export default withStyles(footerStyle)(Footer);
