import React from "react";
import PropTypes from "prop-types";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import ReactCountryFlag from "react-country-flag";
import MUIDataTable from "mui-datatables";
import Button from "components/CustomButtons/Button.jsx";
import Typography from "@material-ui/core/Typography";
import axios from "axios";
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";

import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";

import withStyles from "@material-ui/core/styles/withStyles";
import landingPageStyle from "assets/jss/material-kit-react/views/landingPage.jsx";

class DIDSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      country_name: "",
      country_phonecode: "",
      monthly_fee: "",
      countryCode: "GR",
      data_state: [],
      country_id: -1,
      data_areas: [],
      data_greece: [],
      greece_monthly_fee: "",
    };
  }
  async getAreas(
    id,
    country_code,
    country_name,
    country_phonecode,
    monthly_fee
  ) {
    const content = {
      country_id: id,
    };
    this.setState({ loading: true });
    try {
      const res = await axios.post(
        `https://sip1.easyvoipcall.com/pbxres/api/get_cf_area`,
        content
      );
      await this.setState({
        loading: false,
        data_areas: res.data,
        country_id: id,
        countryCode: country_code,
        country_name: country_name,
        country_phonecode: country_phonecode,
        monthly_fee: monthly_fee,
      });
      window.location.href = "#countries_table";
    } catch (e) {
      console.log(e);
    }
  }

  async getAreasGR(
    id,
    country_code,
    country_name,
    country_phonecode,
    monthly_fee
  ) {
    const content = {
      country_id: id,
    };
    this.setState({ loading: true });
    try {
      const res = await axios.post(
        `https://sip1.easyvoipcall.com/pbxres/api/get_cf_area`,
        content
      );
      await this.setState({
        loading: false,
        data_greece: res.data,
        country_id: -1,
        countryCode: country_code,
        country_name: country_name,
        country_phonecode: country_phonecode,
        monthly_fee: monthly_fee,
        greece_monthly_fee: monthly_fee,
      });
    } catch (e) {
      console.log(e);
    }
  }
  getMuiTheme = () =>
    createMuiTheme({
      overrides: {
        MUIDataTableBodyCell: {
          root: {
            fontSize: "1.5em",
            paddingTop: "5px",
            paddingBottom: "5px",
          },
          stackedCommon: {
            "@media screen and (max-width: 960px)": {
              height: "100%",
            },
          },
        },
      },
    });

  async componentDidMount() {
    this.setState({ loading: true });
    try {
      const res = await axios.post(
        `https://sip1.easyvoipcall.com/pbxres/api/get_hf_countries`,
        ""
      );
      let result = res.data.filter((d) => d.id === 1);
      await this.setState({
        loading: false,
        setupFee: result[0].setup_fee,
        monthlyFee: result[0].monthly_fee,
        data_state: res.data,
      });
    } catch (e) {
      console.log(e);
    }
    this.getAreasGR("1", "GR", "Greece", "30", "2.00");
  }

  render() {
    const {
      data_state,
      data_areas,
      data_greece,
      country_id,
      countryCode,
      country_name,
      country_phonecode,
      monthly_fee,
      loading,
      greece_monthly_fee,
    } = this.state;
    const data = data_state.map((row) => [
      <ReactCountryFlag code={row.country_code} svg />,
      row.country_name + " (" + row.country_phonecode + ")",
      row.setup_fee,
      row.monthly_fee,
      <Button
        color="primary"
        size="sm"
        onClick={() => {
          window.scrollTo(0, 0);
          this.getAreas(
            row.id,
            row.country_code,
            row.country_name,
            row.country_phonecode,
            row.monthly_fee
          );
        }}
        rel="noopener noreferrer"
      >
        Επιλογή
      </Button>,
    ]);
    const data_gr = data_greece.map((datas) => [
      <ReactCountryFlag code={"GR"} svg />,
      datas.code,
      datas.name,
      datas.monthly_fee,
      <Button color="primary" size="sm" href={""} rel="noopener noreferrer">
        BUY A NUMBER
      </Button>,
    ]);
    const areas = data_areas.map((row) => [
      <ReactCountryFlag code={countryCode} svg />,
      row.code,
      row.name,
      <Button color="primary" size="sm" href={""} rel="noopener noreferrer">
        BUY A NUMBER
      </Button>,
    ]);
    const columns = [
      {
        name: "",
        options: {
          filter: false,
          print: false,
          sort: false,
        },
      },
      {
        name: "Χώρα/Κωδικός κλήσης",
        options: {
          filter: false,
          print: false,
        },
      },
      {
        name: "Κόστος αγοράς €",
        options: {
          filter: false,
          print: false,
        },
      },
      {
        name: "Μηνιαίο πάγιο €",
        options: {
          filter: false,
          print: false,
        },
      },
      {
        name: "Επιλέξτε χώρα",
        options: {
          filter: false,
          sort: false,
          print: false,
        },
      },
    ];

    const columns_areas = [
      {
        name: "",
        options: {
          filter: false,
          print: false,
          sort: false,
        },
      },
      {
        name: "Κωδικός Πόλης",
        options: {
          filter: false,
          print: false,
        },
      },
      {
        name: "Όνομα Πόλης",
        options: {
          filter: false,
          print: false,
        },
      },
      /*{
        name: "Action",
        options: {
          filter: false,
          sort: false,
          print: false,
        },
      },*/
    ];
    const greece_areas = [
      {
        name: "",
        options: {
          filter: false,
          print: false,
          sort: false,
        },
      },
      {
        name: "Κωδ.Πόλης",
        options: {
          filter: false,
          print: false,
        },
      },
      {
        name: "Όνομα Πόλης",
        options: {
          filter: false,
          print: false,
        },
      },
    ];

    const options = {
      filter: false,
      print: false,
      selectableRows: false,
      responsive: "stacked",
      download: false,
      viewColumns: false,
      rowsPerPage: 10,
      searchText: "",
    };

    const classes = makeStyles((theme) => ({
      root: {
        display: "flex",
      },
      formControl: {
        margin: theme.spacing(20),
      },
    }));

    return (
      <div className={classes.root}>
        {loading ? (
          <GridContainer justify="center">
            <CircularProgress size={50} />
          </GridContainer>
        ) : (
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Typography
                component="h4"
                variant="h4"
                color="primary"
                style={{ textAlign: "center", marginBottom: "10px" }}
              >
                {" "}
                {"Ελλάδα"} ({"30"}) / μήνα {greece_monthly_fee} €
              </Typography>
              <MuiThemeProvider theme={this.getMuiTheme()}>
                <MUIDataTable
                  title={"Greece"}
                  data={data_gr}
                  columns={greece_areas}
                  options={options}
                />
              </MuiThemeProvider>
              <div
                style={{ marginBottom: "80px" }}
                id={"countries_table"}
              ></div>
              {country_id === -1 ? (
                <MuiThemeProvider theme={this.getMuiTheme()}>
                  <MUIDataTable
                    title={"Χώρες"}
                    data={data}
                    columns={columns}
                    options={options}
                  />
                </MuiThemeProvider>
              ) : (
                <MuiThemeProvider theme={this.getMuiTheme()}>
                  <Typography
                    component="h4"
                    variant="h4"
                    color="primary"
                    style={{ textAlign: "center", marginBottom: "10px" }}
                  >
                    {" "}
                    {country_name} ({country_phonecode}) / μήνα {monthly_fee} €
                  </Typography>
                  <IconButton
                    onClick={() => {
                      this.setState({ country_id: -1 });
                    }}
                    style={{ float: "right", zIndex: "1" }}
                  >
                    <ArrowBackIcon fontSize="large" />
                  </IconButton>

                  <MUIDataTable
                    title={"Πόλεις"}
                    data={areas}
                    columns={columns_areas}
                    options={options}
                  />
                </MuiThemeProvider>
              )}
            </GridItem>
          </GridContainer>
        )}
      </div>
    );
  }
}

DIDSection.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(landingPageStyle)(DIDSection);
