import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import withStyles from "@material-ui/core/styles/withStyles";
import { TinyButton as ScrollUpButton } from "react-scroll-up-button";
import Header from "components/Header/Header.jsx";
import Footer from "components/Footer/FooterEN.jsx";
import HeaderLinks from "components/Header/HeaderLinksEN.jsx";
import HeaderTopLinks from "components/Header/HeaderTopLinksEN.jsx";
import Parallax from "components/Parallax/Parallax.jsx";
import Typography from "@material-ui/core/Typography";
import ListItem from "@material-ui/core/ListItem";

import CookieConsent from "react-cookie-consent";

import landingPageStyle from "assets/jss/material-kit-react/views/landingPage.jsx";

//import FAQSection from "./Sections/FAQSection.jsx";
import AppSection from "./Sections/AppSectionEN.jsx";
import VisaSection from "./Sections/VisaSection.jsx";
//import RateSection from "./Sections/RateSection.jsx";
import DIDSection from "./Sections/DIDSectionEN.jsx";

class DIDPage extends React.Component {
  constructor(props) {
    window.scrollTo(0, 0);
    super(props);
  }

  render() {
    const { classes, ...rest } = this.props;

    return (
      <div>
        <Header
          topLinks={<HeaderTopLinks />}
          rightLinks={<HeaderLinks />}
          fixed
          logoUrl="/img/hellasfon_logo_white.jpg"
          color="primary"
          changeColorOnScroll={{
            height: 50,
            color: "white",
          }}
          {...rest}
        />
        <Parallax color small />
        <div className={classNames(classes.main, classes.mainRaised)}>
          <h2
            style={{
              textAlign: "center",
              backgroundColor: "#005FB4",
              color: "white",
            }}
          >
            {"DID Numbers"}
          </h2>
          <div className={classes.container} style={{ marginTop: "40px" }}>
		   <ListItem>
                  <Typography
                    component="h5"
                    variant="body1"
                    color="textPrimary"
                  >
                    Upon your registration, a <strong>Greek </strong>DID Number is reserved for you, which is given for <b>FREE </b>
					by <b>HELLASFON. </b> <p></p>
                    There is also the possibility to buy and choose <b> additional Greek numbers </b> 
					with a small fee of <b>(2 € / month)</b> directly from the 
				    <b> HF App</b> or through the Customer's portal <b>MY ACCOUNT</b> (My Account --> Telephone numbers)
					<p></p> <b>Except Greek phone numbers we provide you the option to choose and buy extra phone numbers
					for 61 countries all over the world!</b><p></p> This phone number will be added to
					your account; including your Greek number, and it will be available for  
					all customers/colleagues/friends/relatives that are located abroad.
					You will have the ability to call them at their country using local charges 
					no matter where you are located to, and they will call you charged for local call ! 
					All you have to do is choose country, city and the number that you preffer. App will check immediately 
					if this number is available and will reserve it for you.<p></p>
					<b>Purchasing additional telephone numbers is AUTOMATIC and IMMEDIATE, and does not require 
					ANY commitment or ANY contract !</b>

                  </Typography>
            </ListItem>

            <DIDSection />
			<ListItem>
                  <Typography
                    component="h5"
                    variant="body1"
                    color="textPrimary"
                  >
                    <p></p>
					<b>Purchasing additional telephone numbers is AUTOMATIC and IMMEDIATE, and does not require 
					ANY commitment or ANY contract!
					</b>

                  </Typography>
            </ListItem>
          </div>

          <AppSection />
          <VisaSection />
          <Footer />
          <ScrollUpButton />
          <CookieConsent
            location="bottom"
            buttonText="ΟΚ I agree"
            cookieName="hellasfonCookie"
            style={{ background: "rgb(1, 120, 226)" }}
            buttonStyle={{
              background: "#f50057",
              color: "white",
              fontSize: "15px",
              marginRight: "130px",
            }}
            expires={150}
          >
            We use cookies in this website to improve our customers experience
            .{" "}
            <span style={{ fontSize: "12px" }}>
              By clicking in any link in this page you consent to set up cookies.
              {" "}
              <a
                style={{ color: "white", textDecoration: "underline" }}
                href="/privacy"
              >
                More information
              </a>
            </span>
          </CookieConsent>
        </div>
      </div>
    );
  }
}

DIDPage.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(landingPageStyle)(DIDPage);
