import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "components/CustomButtons/Button.jsx";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import Slide from "@material-ui/core/Slide";
import CardMedia from "@material-ui/core/CardMedia";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const styles = (theme) => ({
  root: {
    backgroundColor: "#005fb4",
    margin: 0,
    padding: theme.spacing(2),
    color: "white",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: "white",
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      <IconButton
        aria-label="close"
        className={classes.closeButton}
        onClick={onClose}
      >
        <CloseIcon />
      </IconButton>
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function DialogAdvertisment(props) {
  const { open, handleClose } = props;

  return (
    <div>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        TransitionComponent={Transition}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Εργασία από το σπίτι - Τηλεργασία:
        </DialogTitle>
        <DialogContent dividers>
          <CardMedia
            component="img"
            alt="Hellasfon Κινητή, Σταθερή Voip τηλεφωνία. Ολοκληρωμένες Υπηρεσίες Τηλεπικοινωνιών"
            image="img/home_office.png"
            title="Hellasfon Κινητή, Σταθερή Voip τηλεφωνία. Ολοκληρωμένες Υπηρεσίες Τηλεπικοινωνιών"
          />
          <h5>
            <strong>
              Με το τηλεφωνικό κέντρο της HELLASFON η εργασία απο το σπίτι είναι
              απλή υπόθεση!
            </strong>
            <br />
            <ul>
              <li>
                Χωρίς έξοδα για συσκευές και εξοπλισμό, καλείς / δέχεσαι κλήσεις
                στο κινητό και τον υπολογιστή σου όταν είσαι σπίτι/εκτός γραφείου.
              </li>
              <li>
                Κάθε χρήστης έχει επικοινωνία με όλη την εταιρεία/συνεργάτες
                χρησιμοποιώντας βίντεο, μηνύματα και τηλέφωνο απευθείας από τον
                υπολογιστή και το κινητό του.
              </li>
              <li>
                Η υπηρεσία Τηλεργασίας / Τηλεδιάσκεψης, περιλαμβάνεται σε όλα τα
                τηλεφωνικά κέντρα EVCOFFICE για όλους τους χρήστες! Χωρίς έξτρα
                κόστος!
              </li>
              <li>Εύκολη χρήση - PLUG and PLAY.</li>
            </ul>
            <strong>Έτοιμο σε λίγα λεπτά, και μόλις με 3 βήματα!</strong>
          </h5>
        </DialogContent>
        <DialogActions>
          <Button href="#pricing" onClick={handleClose} color="primary">
          Εγγραφή
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
