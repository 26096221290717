import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import withStyles from "@material-ui/core/styles/withStyles";
import { TinyButton as ScrollUpButton } from "react-scroll-up-button";
import Header from "components/Header/Header.jsx";
import Footer from "components/Footer/Footer.jsx";
import HeaderLinks from "components/Header/HeaderLinks.jsx";
import HeaderTopLinks from "components/Header/HeaderTopLinks.jsx";
import Parallax from "components/Parallax/Parallax.jsx";

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import Typography from "@material-ui/core/Typography";

import CardMedia from "@material-ui/core/CardMedia";
import Button from "components/CustomButtons/Button.jsx";

import landingPageStyle from "assets/jss/material-kit-react/views/landingPage.jsx";

import VisaSection from "../Sections/VisaSection.jsx";

import AppSection from "../Sections/AppSectionBusiness.jsx";

import CookieConsent from "react-cookie-consent";

class EshopPage extends React.Component {
  constructor(props) {
    window.scrollTo(0, 0);
    super(props);
  }
  render() {
    const { classes, ...rest } = this.props;

    return (
      <div>
        <Header
          topLinks={<HeaderTopLinks />}
          rightLinks={<HeaderLinks />}
          fixed
          logoUrl="../img/hellasfon_logo_white.jpg"
          color="primary"
          changeColorOnScroll={{
            height: 50,
            color: "white",
          }}
          {...rest}
        />
        <Parallax color small />
        <div className={classNames(classes.main, classes.mainRaised)}>
          <h1
            style={{
              textAlign: "center",
              backgroundColor: "#005FB4",
              color: "white",
              marginBottom: "0px",
            }}
          >
            {"Λύσεις για E-Shops"}
          </h1>
          <GridContainer
            justify="center"
            style={{
              width: "100%",
              marginLeft: "0px",
              marginRight: "0px",
              marginTop: "0px",
            }}
          >
		  
		  
		  
            <GridItem cs={12} sm={12} md={6} style={{ padding: 10 }}>
              <h2>Cloud Τηλεφωνικό Κέντρο EVCΟFFICE</h2>
              <Typography variant="body1">
                <li>Απεριόριστες Γραμμές & κανάλια φωνής</li>
				<li>Εύκολος Επαγγελματικός Αριθμός</li>
				<li> HD Ποιότητα φωνής</li>
				<li> Πάρε το σταθερό του E-Shop μαζί σου όπου και αν βρίσκεσαι! </li>
				<li> Ψηφιακή Βοηθός/Αυτόματη Υποδοχή </li>
				<li> Πλατφόρμα για Αποστολή SMS για το E-shop σου </li>
				<br></br>
				<strong>Η Hellasfon διαθέτει πρωτοπορειακές υπηρεσίες για επαγγελματικούς πελάτες παρέχοντας : 
				<li>HD Ποιότητα ήχου</li>
				<li>Χαμηλότερες χρεώσεις κλήσεων χωρίς μεσάζοντες!</li></strong>
				<br /><p></p> 
              </Typography>
			  <Button href="/contact" color="primary">
                ΖΗΤΗΣΤΕ ΠΡΟΣΦΟΡΑ
              </Button>
            </GridItem>
            <GridItem
              cs={12}
              sm={12}
              md={6}
              style={{ paddingRight: "0px", paddingLeft: "0px" }}
            >
              <CardMedia
                component="img"
                image="../img/eshop1.jpg"
                alt="Λύσεις Hellasfon για ηλεκτρονικά καταστήματα eshops"
                title="Hellasfon λύσεις για e-shops"
              />
            </GridItem>
          </GridContainer>
          <GridContainer
            justify="center"
            style={{
              width: "100%",
              marginLeft: "0px",
              marginRight: "0px",
              marginTop: "0px",
            }}
          >
            <GridItem cs={12} sm={12} md={6} style={{ background: "#009bff" }}>
              <CardMedia
                style={{ padding: 30 }}
                component="img"
                image="../img/recept.jpg"
                alt="Ψηφιακή βοηθός σε τηλεφωνικό κέντρο"
                title="Hellasfon - Υπηρεσία Προαπάντησης Κλήσεων"
              />
            </GridItem>
            <GridItem
              cs={12}
              sm={12}
              md={6}
              style={{ background: "#009bff", padding: 70 }}
            >
              <h2 style={{ color: "#fff" }}>Ψηφιακή Βοηθός/Αυτόματη Υποδοχή</h2>
              <Typography variant="body1" style={{ color: "#fff" }}>
                Με την χρήση της ψηφιακής βοηθού οι πελάτες σας ακούνε προηχογραφημένα μηνύματα και οι κλήσεις σας διαχειρίζονται αυτόματα βάση του σεναρίου που επιθυμείτε <br /><p></p> 
				Δεν υπάρχει περιορισμός ως πρός τα σενάρια που θα εκτελούνται! Εσείς το μόνο που έχετε να κάνετε είναι να ηχογραφήσετε τα μηνύματα προαπάντησης που επιθυμείτε
				να ακουγονται καθώς και να αποφασίσετε το σενάριο χρήσης σας.<br></br>		Απλή και εύκολη χρήση!<br></br>
				<br></br>
				Οποιαδήποτε στιγμή αποφασίσετε να κάνετε οποιαδήποτε αλλαγή την κάνετε πολύ εύκολα μόνοι σας απο την διαχείρηση του τηλεφωνικού κέντρου.<br></br> <br></br>Εξοικονομήστε εως 75% σε όλες τις κλήσεις σας.
				<br /><p></p> <br></br>
                
			  <Button href="/cloudpbx#pricing" color="warning">
                  ΔΕΙΤΕ ΤΑ ΠΑΚΕΤΑ ΤΗΛ.ΚΕΝΤΡΟΥ
              </Button>
              </Typography>
			 
            </GridItem>
          </GridContainer>
          <GridContainer
            justify="center"
            style={{
              width: "100%",
              marginLeft: "0px",
              marginRight: "0px",
              marginTop: "0px",
            }}
          >
            <GridItem cs={12} sm={12} md={6} style={{ padding: 30 }}>
              <h2>Αποστολή SMS για το e-shop σας μέσω API</h2>
              <Typography variant="body1">
			    <li> Στείλτε μηνύματα SMS στους πελάτες του e-shop σας μέσα απο την ιστοσελίδα σας !</li>
                <li> REST API για πραγματικά εύκολη διασύνδεση.</li>
                <li> Οι μονάδες ΔΕΝ λήγουν ποτέ! </li>
                <br></br>
				
				<br></br>
				<h3><strong> Ολοκληρωμένες Λύσεις για την Επιχείρηση σας! </strong></h3>
				<Button href="https://myaccount.easyclicksms.com/register" color="primary">
                ΔΩΡΕΑΝ ΕΓΓΡΑΦΗ
              </Button>
			  <Button href="/sms" color="secondary">
                ΔΕΙΤΕ ΣΧΕΤΙΚΑ
              </Button>
		      </Typography>
            </GridItem>
            <GridItem
              cs={12}
              sm={12}
              md={6}
              style={{ paddingRight: "0px", paddingLeft: "0px" }}
            >
              <CardMedia
                component="img"
                image="../img/sms1.jpg"
                alt="Υπηρεσίες SMS για eshops"
                title="Hellasfon - Υπηρεσίες SMS για ηλεκτρονικά καταστήματα"
              />
            </GridItem>
						
		  
          </GridContainer>
          <GridContainer
            justify="center"
            style={{
              width: "100%",
              marginLeft: "0px",
              marginRight: "0px",
              marginTop: "0px",
            }}
          >
           
            
          </GridContainer>
          <GridContainer
            justify="center"
            style={{
              width: "100%",
              marginLeft: "0px",
              marginRight: "0px",
              marginTop: "0px",
            }}
          >
            
            
          </GridContainer>
          <GridContainer
            justify="center"
            style={{
              width: "100%",
              marginLeft: "0px",
              marginRight: "0px",
              marginTop: "0px",
            }}
          >
                        
          </GridContainer>
          <GridContainer
            justify="center"
            style={{
              width: "100%",
              marginLeft: "0px",
              marginRight: "0px",
              marginTop: "0px",
            }}
          >
            
          </GridContainer>
          <GridContainer
            style={{
              width: "100%",
              marginLeft: "0px",
              marginRight: "0px",
              marginTop: "0px",
              display: "grid",
            }}
          >
		  
		  <AppSection />
		  </GridContainer>
		  
		  <VisaSection />
          <Footer />
        </div>
        <ScrollUpButton />
        <CookieConsent
          location="bottom"
          buttonText="ΟΚ συμφωνώ"
          cookieName="hellasfonCookie"
          style={{ background: "rgb(1, 120, 226)" }}
          buttonStyle={{
            background: "#f50057",
            color: "white",
            fontSize: "15px",
            marginRight: "130px",
          }}
          expires={150}
        >
          Χρησιμοποιούμε cookies σε αυτόν τον ιστότοπο για να βελτιώσουμε την
          εμπειρία των χρηστών σας.{" "}
          <span style={{ fontSize: "12px" }}>
            Κάνοντας κλικ σε οποιονδήποτε σύνδεσμο σε αυτή τη σελίδα δίνετε τη
            συγκατάθεσή σας για να ορίσουμε cookies.{" "}
            <a
              style={{ color: "white", textDecoration: "underline" }}
              href="/privacy"
            >
              Περισσότερες πληροφορίες
            </a>
          </span>
        </CookieConsent>
      </div>
    );
  }
}

EshopPage.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(landingPageStyle)(EshopPage);
