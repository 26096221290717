import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import withStyles from "@material-ui/core/styles/withStyles";
import { TinyButton as ScrollUpButton } from "react-scroll-up-button";
import Header from "components/Header/Header.jsx";
import Footer from "components/Footer/FooterEN.jsx";
import HeaderLinks from "components/Header/HeaderLinksEN.jsx";
import HeaderTopLinks from "components/Header/HeaderTopLinksEN.jsx";
import Parallax from "components/Parallax/Parallax.jsx";

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import Typography from "@material-ui/core/Typography";

import CardMedia from "@material-ui/core/CardMedia";
import Button from "components/CustomButtons/Button.jsx";

import landingPageStyle from "assets/jss/material-kit-react/views/landingPage.jsx";

import VisaSection from "../Sections/VisaSection.jsx";
//import SolutionSection from "./Sections/SolutionSection.jsx";
import AppSection from "../Sections/AppSectionBusinessEN.jsx";

import CookieConsent from "react-cookie-consent";

class StartupPage extends React.Component {
  constructor(props) {
    window.scrollTo(0, 0);
    super(props);
  }
  render() {
    const { classes, ...rest } = this.props;

    return (
      <div>
        <Header
          topLinks={<HeaderTopLinks />}
          rightLinks={<HeaderLinks />}
          fixed
          logoUrl="../img/hellasfon_logo_white.jpg"
          color="primary"
          changeColorOnScroll={{
            height: 50,
            color: "white",
          }}
          {...rest}
        />
        <Parallax color small />
        <div className={classNames(classes.main, classes.mainRaised)}>
          <h1
            style={{
              textAlign: "center",
              backgroundColor: "#005FB4",
              color: "white",
              marginBottom: "0px",
            }}
          >
            {"Start Up Company"}
          </h1>
          <GridContainer
            justify="center"
            style={{
              width: "100%",
              marginLeft: "0px",
              marginRight: "0px",
              marginTop: "0px",
            }}
          >
            <GridItem cs={12} sm={12} md={6} style={{ padding: 50 }}>
              <h2>Get your PBX ready in 5 minutes!</h2>
              <Typography variant="body1">
                <b>Communications</b> play an important role in the success of a
                company. The time lost from establishment to start &
                operate is a determining factor.                              
                <br /> <br />
                Startups require innovative solutions adapted
                to today's needs.
                <br /> <br />
                HELLASFON offers <b>UC Turnkey solutions</b> for every
                business. We combine mobile and fixed telephony with
                flexible financial programs & productivity tools, which
                will help your company start quickly and grow! 
                                               
                <br /> <br />
                <b>Build your own PBX and start
                your business within a few minutes!
                </b>
              </Typography>
            </GridItem>
            <GridItem
              cs={12}
              sm={12}
              md={6}
              style={{ paddingRight: "0px", paddingLeft: "0px" }}
            >
              <CardMedia
			    component="img"
                image="/img/hellasfon_startup_solution_02.jpg"
                alt="Home office PBX hellasfon"
                title="home office PBX hellasfon"
              />
            </GridItem>
          </GridContainer>
          <GridContainer
            justify="center"
            style={{
              width: "100%",
              marginLeft: "0px",
              marginRight: "0px",
              marginTop: "0px",
            }}
          >
            <GridItem cs={12} sm={12} md={6} style={{ background: "#009bff" }}>
              <CardMedia
                style={{ padding: 70 }}
                component="img"
                image="/img/coronavirus-hellasfon-03.jpg"
                alt="home office PBX hellasfon"
                title="home office PBX hellasfon"
              />
            </GridItem>
            <GridItem
              cs={12}
              sm={12}
              md={6}
              style={{ background: "#009bff", padding: 70 }}
            >
              <h2 style={{ color: "#fff" }}>No commitment:</h2>
              <Typography variant="body1" style={{ color: "#fff" }}>
                <b>No contracts</b> or commitments – add / remove users
                for your pbx by €0,75/user/month. <br /><br />
                <li>
                  <b>Each user</b> has his own <b>dedicated</b> line.
                </li><br />
                <li>Contributing at the company <b>100% availability.</b></li><br />
                <li>
                  10 users, 10 external lines, 10 concurrent calls.
                </li>
              </Typography>
            </GridItem>
          </GridContainer>
          <GridContainer
            justify="center"
            style={{
              width: "100%",
              marginLeft: "0px",
              marginRight: "0px",
              marginTop: "0px",
            }}
          >
            <GridItem cs={12} sm={12} md={6} style={{ padding: 60 }}>
              <h2>Low Fees</h2>
              <Typography variant="body1">
                <li>
                  Need more users? Pay extra € 0,75/licence/month ; add as many as 
                  you like <b>immediately</b>.<p></p>
                </li>
                <li>
                  NO EXPENSES for telephone devices and equipment. Download the FREE 
                  EVCOFFICE App and get your office on the go!
                  Answer/Call, chat, sms, conference, ALL by your mobile.<p></p>
                </li>
                <li>
                  ALL IP Telephony devices are supported but not needed mandatory.
                  CALL - CHAT & VIDEO
                  are supported by the web portal and the EVOFFICE app.<p></p>
                </li>
                <li>
                  For VOIP devices you may visit our {" "}
                  <a href="https://shop.hellasfon.com/">E-SHOP</a> .
                </li>
              </Typography>
            </GridItem>
            <GridItem
              cs={12}
              sm={12}
              md={6}
              style={{ paddingRight: "0px", paddingLeft: "0px" }}
            >
              <CardMedia
                component="img"
                image="/img/coronavirus-hellasfon-06.jpg"
                alt="home office PBX hellasfon"
                title="home office PBX hellasfon"
              />
            </GridItem>
          </GridContainer>
          <GridContainer
            justify="center"
            style={{
              width: "100%",
              marginLeft: "0px",
              marginRight: "0px",
              marginTop: "0px",
            }}
          >
            <GridItem cs={12} sm={12} md={6} style={{ background: "#009bff" }}>
              <CardMedia
                style={{ padding: 70 }}
                component="img"
                image="/img/coronavirus_PBX-01.png"
                alt="home office PBX hellasfon"
                title="home office PBX hellasfon"
              />
            </GridItem>
            <GridItem
              cs={12}
              sm={12}
              md={6}
              style={{ background: "#009bff", padding: 70 }}
            >
              <h2 style={{ color: "#fff" }}>Easy to use – Plug and Play</h2>
              <Typography variant="body1" style={{ color: "#fff" }}>
                <li>
                  EVCOFFICE App is a part of the U.C. PBX and does not need 
                  any setup or configuration. Just enter your (username) and
                  (password).<p></p>
                </li>
                <li>
                  For added convenience synchronize with your mobile contacts. <p></p>
                </li>
                <li>
                  All users download the EVOFFICE app and immediately connect to your PBX.
                  By using this feature, business can
                  utilize partners and colleagues in other cities;
                  fast, same day without equipment or extra costs!
                </li>
              </Typography>
            </GridItem>
          </GridContainer>
          <GridContainer
            justify="center"
            style={{
              width: "100%",
              marginLeft: "0px",
              marginRight: "0px",
              marginTop: "0px",
            }}
          >
            <GridItem cs={12} sm={12} md={6} style={{ padding: 60 }}>
              <h2>User Friendly Environment</h2>
              <Typography variant="body1">
                <li>
                  No extra fees for a technician, you can easily set it up.<p></p>
                </li>
                <li>Easy Management - Simple Menus</li> <p></p>
                <li>
                  Not the Tech Savy User ? We will take care of it upon your request !
                </li>
                <br /> <br />
              </Typography>
            </GridItem>
            <GridItem
              cs={12}
              sm={12}
              md={6}
              style={{ paddingRight: "0px", paddingLeft: "0px" }}
            >
              <CardMedia
                style={{
                  paddingTop: 100,
                  paddingRight: 100,
                  paddingBottom: 100,
                }}
                component="img"
                image="/img/coronavirus_PBX-03.png"
                alt="home office PBX hellasfon"
                title="home office PBX hellasfon"
              />
            </GridItem>
          </GridContainer>
          <GridContainer
            justify="center"
            style={{
              width: "100%",
              marginLeft: "0px",
              marginRight: "0px",
              marginTop: "0px",
            }}
          >
            <GridItem cs={12} sm={12} md={6} style={{ background: "#009bff" }}>
              <CardMedia
                style={{ padding: 70 }}
                component="img"
                image="/img/hellasfon_startup_solution_01.jpg"
                alt="home office PBX hellasfon"
                title="home office PBX hellasfon"
              />
            </GridItem>
            <GridItem
              cs={12}
              sm={12}
              md={6}
              style={{ background: "#009bff", padding: 70 }}
            >
              <h2 style={{ color: "#fff" }}>Expansion</h2>
              <Typography variant="body1" style={{ color: "#fff" }}>
                <li>
                  EVCOFFICE Cloud PBX can easilly get expanded according your office needs.
                </li> <br />
                <li>
                  NO contracts or commitments ; add/remove users depending 
                  your needs - additional cost € 0,75/user/month.
                </li>
              </Typography>
            </GridItem>
          </GridContainer>
          <GridContainer
            justify="center"
            style={{
              width: "100%",
              marginLeft: "0px",
              marginRight: "0px",
              marginTop: "0px",
            }}
          >
            <GridItem cs={12} sm={12} md={6} style={{ padding: 60 }}>
              <h2>Work ON THE GO</h2>
              <Typography variant="body1">
                EASY, every user can work on the go by
                using the FREE tools for his pc and mobile that
                HELLASFON and EVCOFFICE PBX offers.
                <br /><br />
                <li>
                  Call/Answer and serve customers by using company's number (callerid) 
                  and just like you are sitting on your office desk. <br />
                </li> <br />
                <li>
                  Communicate with other company colleagues completely FREE. 
				  ( CALL + CHAT + VIDEOCALLS ).
                </li>
              </Typography>
              <br />
              <Button fullWidth href="/cloudpbx#pricing" color="primary">
                Register
              </Button>
            </GridItem>
            <GridItem
              cs={12}
              sm={12}
              md={6}
              style={{ paddingRight: "0px", paddingLeft: "0px" }}
            >
              <CardMedia
                component="img"
                image="/img/hellasfon_startup_solution_02.jpg"
                alt="home office PBX hellasfon"
                title="home office PBX hellasfon"
              />
            </GridItem>
          </GridContainer>
          <GridContainer
            style={{
              width: "100%",
              marginLeft: "0px",
              marginRight: "0px",
              marginTop: "0px",
              display: "grid",
            }}
          >
            <AppSection />
          </GridContainer>
          <VisaSection />
          <Footer />
        </div>
        <ScrollUpButton />
        <CookieConsent
          location="bottom"
          buttonText="ΟΚ I agree"
          cookieName="hellasfonCookie"
          style={{ background: "rgb(1, 120, 226)" }}
          buttonStyle={{
            background: "#f50057",
            color: "white",
            fontSize: "15px",
            marginRight: "130px",
          }}
          expires={150}
        >
          We use cookies in the website to improve customers experience 
          .{" "}
          <span style={{ fontSize: "12px" }}>
            By clicking on any link at this webpage you consent to set up cookies.
            .{" "}
            <a
              style={{ color: "white", textDecoration: "underline" }}
              href="/privacy"
            >
              More information
            </a>
          </span>
        </CookieConsent>
      </div>
    );
  }
}

StartupPage.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(landingPageStyle)(StartupPage);
