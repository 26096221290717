import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import withStyles from "@material-ui/core/styles/withStyles";
import { TinyButton as ScrollUpButton } from "react-scroll-up-button";
import Header from "components/Header/Header.jsx";
import Footer from "components/Footer/Footer.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import HeaderLinks from "components/Header/HeaderLinks.jsx";
import HeaderTopLinks from "components/Header/HeaderTopLinks.jsx";
import Parallax from "components/Parallax/Parallax.jsx";
import CircularProgress from "@material-ui/core/CircularProgress";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import Typography from "@material-ui/core/Typography";
import ListItem from "@material-ui/core/ListItem";
import PlansTable from "./Sections/PlansTableSection.jsx";
import CookieConsent from "react-cookie-consent";

import landingPageStyle from "assets/jss/material-kit-react/views/landingPage.jsx";

//import FAQSection from "./Sections/FAQSection.jsx";
import AppSection from "./Sections/AppSection.jsx";
import VisaSection from "./Sections/VisaSection.jsx";
//import RateSection from "./Sections/RateSection.jsx";
//import ImageSection from "./Sections/ImageSection.jsx";

import PriceTable from "./Sections/PriceTableSection.jsx";

import { connect } from "react-redux";
import { getRetailPlanPacks, getPlans } from "actions/retailActions";

class SipTrunkPage extends React.Component {
  constructor(props) {
    window.scrollTo(0, 0);
    super(props);
    const { classes } = props;
    this.state = {
      classSnackbar: classes.hideSnackbar,
      response: "",
      loading_plan_pack: true
    };
  }
  async componentDidMount() {
    await this.props.getRetailPlanPacks("sip_trunk");
    await this.props.getPlans("sip");
    this.setState({
      loading_plan_pack: false
    });
  }
  handleChange = async e => {
    const { classes } = this.props;
    this.setState({
      classSnackbar: classes.hideSnackbar,
      value_radio: e.target.value,
      loading_plan_pack: true
    });
    await this.props.getRetailPlanPacks(e.target.value);
    this.setState({
      loading_plan_pack: false
    });
    if (this.props.retail_plan_packs.length === 0) {
      this.setState({
        classSnackbar: classes.infoSnackbar,
        response: "Δεν υπάρχουν σχέδια προς το παρόν"
      });
    }
  };
  selectPlan = async () => {
    return;
  };

  render() {
    const { classes, retail_plan_packs, plans, ...rest } = this.props;
    const { loading_plan_pack, classSnackbar, response } = this.state;
    return (
      <div>
        <Header
          color="transparent"
          logoUrl="/img/Hellasfon_logo.png"
          topLinks={<HeaderTopLinks />}
          rightLinks={<HeaderLinks />}
          fixed
          changeColorOnScroll={{
            height: 50,
            color: "white"
          }}
          {...rest}
        />
        <Parallax
          filter
          image={require("assets/img/Hellasfon_Networks_sipTrunk.jpg")}
        >
          <div className={classes.container}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <h5>
                <strong>Οικονομία</strong>, <strong>Ευελιξία</strong> και <strong>Αύξηση παραγωγικότητας</strong> σε κάθε επιχείρηση με την HELLASFON.
				<ul>
                  <li>Γραμμές τηλεφώνου με απεριόριστες κλήσεις σε σταθερά και κινήτα προς όλους μόνο με € 19,50/μήνα.</li>
				  <li>Προσθέστε 2 , 10 ή 30 γραμμές/κανάλια φωνής στην επιχείρηση σας.</li>
				  <li>Μειώστε εώς <strong>-30%</strong> τον λογαριασμό του γραφείου σας με HELLASFOΝ Business Telephony</li>
        </ul>
                </h5>
                <Button
                  color="primary"
                  size="lg"
                  href="#pricing"
                  rel="noopener noreferrer"
                >
                  Εγγραφή
                </Button>
              </GridItem>
            </GridContainer>
          </div>
        </Parallax>
        <div className={classNames(classes.main, classes.mainRaised)}>
          <h1
            style={{
              marginTop: "0px",
              marginBottom: "30px",
              textAlign: "center",
              backgroundColor: "#005FB4",
              color: "white"
            }}
          >
            HELLASFON Business Telephony
          </h1>
          <div className={classes.container}>
            <GridContainer id="pricing">
              <ListItem>
                <Typography component="h4" variant="h4" color="primary">
                  Διαθέσιμα Πακέτα
                </Typography>
              </ListItem>
              <ListItem>
                <Typography component="h5" variant="body1" color="textPrimary">
                  Καταργήστε τα ακριβά πάγια και προσθέστε γραμμές τηλεφώνου από € 19,50/μήνα με απεριόριστες κλήσεις πρός σταθερά και κινητά πρός όλους.<br></br>
				  Το <b>SIP</b> είναι το πρωτόκολο με τον οποίο διασυνδέεται ο πελάτης στους κόμβους μας,
                  με σκοπό την μείωση των χρεώσεων τηλεφωνίας και την απόκτηση επιπλέον γραμμών/καναλιών φωνής για την επιχείρηση σας.
                  Δείτε αναλυτικά τα χαρακτηριστικά τους και διάλεξτε το πρόγραμμα που ταιριάζει καλύτερα στις ανάγκες σας.<br></br>
				  Όλα τα προγράμματα της HELLASFON είναι <strong>ΧΩΡΙΣ ΔΕΣΜΕΥΣΗ</strong> και με <strong>ΕΓΓΥΗΣΗ ΧΑΜΗΛΟΤΕΡΗΣ ΤΙΜΗΣ</strong>.
                </Typography>
              </ListItem>
            </GridContainer>
          </div>
          <div className={classes.container}>
            <SnackbarContent
              className={classSnackbar}
              aria-describedby="client-snackbar"
              message={response}
            />

            {loading_plan_pack ? (
              <GridContainer justify="center">
                <CircularProgress size={50} />
              </GridContainer>
            ) : (
              <React.Fragment>
               <PriceTable
                id="pricing"
                url={"/reg/sip/"}
                plan_packs={retail_plan_packs}
              />

                <Typography component="h4" variant="h4" color="primary" style={{ marginBottom: "10px"}}>Προγράμματα έξτρα χρόνου ομιλίας</Typography>
                <GridContainer spacing={2}>
                  {plans.map(plan => (
                    <PlansTable
                      plan={plan}
                      key={plan.id_plan}
                      selectPlan={this.selectPlan}
                    />
                  ))}
                </GridContainer>

            </React.Fragment>
            )}
          </div>
          <AppSection />
          <VisaSection />
          <Footer />
        </div>
        <ScrollUpButton />
        <CookieConsent
            location="bottom"
            buttonText="ΟΚ συμφωνώ"
            cookieName="hellasfonCookie"
            style={{ background: "rgb(1, 120, 226)" }}
            buttonStyle={{ background: "#f50057", color: "white", fontSize: "15px", marginRight: "130px" }}
            expires={150}
        >
            Χρησιμοποιούμε cookies σε αυτόν τον ιστότοπο για να βελτιώσουμε την εμπειρία των χρηστών.{" "}
            <span style={{ fontSize: "12px" }}>
            Κάνοντας κλικ σε οποιονδήποτε σύνδεσμο σε αυτή τη σελίδα δίνετε τη συγκατάθεσή σας για να ορίσουμε cookies.{" "}
            <a style={{color: "white", textDecoration: "underline"}} href="/privacy">Περισσότερες πληροφορίες</a>
            </span>

        </CookieConsent>
      </div>
    );
  }
}

SipTrunkPage.propTypes = {
  classes: PropTypes.object,
  retail_plan_packs: PropTypes.array.isRequired,
  getRetailPlanPacks: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  retail_plan_packs: state.retail_reducer.retail_plan_packs,
  plans: state.retail_reducer.plans
});

export default connect(
  mapStateToProps,
  { getRetailPlanPacks, getPlans }
)(withStyles(landingPageStyle)(SipTrunkPage));
