import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import withStyles from "@material-ui/core/styles/withStyles";
import { TinyButton as ScrollUpButton } from "react-scroll-up-button";
import Header from "components/Header/Header.jsx";
import Footer from "components/Footer/FooterEN.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import HeaderLinks from "components/Header/HeaderLinksEN.jsx";
import HeaderTopLinks from "components/Header/HeaderTopLinksEN.jsx";
import Parallax from "components/Parallax/Parallax.jsx";
import CircularProgress from "@material-ui/core/CircularProgress";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import Typography from "@material-ui/core/Typography";
import ListItem from "@material-ui/core/ListItem";
import PlansTable from "./Sections/PlansTableSection.jsx";
import CookieConsent from "react-cookie-consent";

import landingPageStyle from "assets/jss/material-kit-react/views/landingPage.jsx";

//import FAQSection from "./Sections/FAQSection.jsx";
import AppSection from "./Sections/AppSectionEN.jsx";
import VisaSection from "./Sections/VisaSection.jsx";
//import RateSection from "./Sections/RateSection.jsx";
//import ImageSection from "./Sections/ImageSection.jsx";

import PriceTable from "./Sections/PriceTableSection.jsx";

import { connect } from "react-redux";
import { getRetailPlanPacks, getPlans } from "actions/retailActions";

class SipTrunkPage extends React.Component {
  constructor(props) {
    window.scrollTo(0, 0);
    super(props);
    const { classes } = props;
    this.state = {
      classSnackbar: classes.hideSnackbar,
      response: "",
      loading_plan_pack: true
    };
  }
  async componentDidMount() {
    await this.props.getRetailPlanPacks("sip_trunk");
    await this.props.getPlans("sip");
    this.setState({
      loading_plan_pack: false
    });
  }
  handleChange = async e => {
    const { classes } = this.props;
    this.setState({
      classSnackbar: classes.hideSnackbar,
      value_radio: e.target.value,
      loading_plan_pack: true
    });
    await this.props.getRetailPlanPacks(e.target.value);
    this.setState({
      loading_plan_pack: false
    });
    if (this.props.retail_plan_packs.length === 0) {
      this.setState({
        classSnackbar: classes.infoSnackbar,
        response: "No available plans for the moment"
      });
    }
  };
  selectPlan = async () => {
    return;
  };

  render() {
    const { classes, retail_plan_packs, plans, ...rest } = this.props;
    const { loading_plan_pack, classSnackbar, response } = this.state;
    return (
      <div>
        <Header
          color="transparent"
          logoUrl="/img/Hellasfon_logo.png"
          topLinks={<HeaderTopLinks />}
          rightLinks={<HeaderLinks />}
          fixed
          changeColorOnScroll={{
            height: 50,
            color: "white"
          }}
          {...rest}
        />
        <Parallax
          filter
          image={require("assets/img/Hellasfon_Networks_sipTrunk.jpg")}
        >
          <div className={classes.container}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <h5>
                <strong>Economy</strong>, <strong>Flexibility</strong> and <strong>Productivity increase</strong> in every company with HELLASFON.
				<ul>
                  <li>Landlines with Unlimited calls to Landlines and Mobiles , only for € 19,50/month.</li>
				  <li>Add 2 , 10 or 30 lines/voice channels for your business.</li>
				  <li>Reduce upto <strong>-30%</strong> phone bills of your office with HELLASFOΝ Business Telephony</li>
        </ul>
                </h5>
                <Button
                  color="primary"
                  size="lg"
                  href="#pricing"
                  rel="noopener noreferrer"
                >
                  Register
                </Button>
              </GridItem>
            </GridContainer>
          </div>
        </Parallax>
        <div className={classNames(classes.main, classes.mainRaised)}>
          <h1
            style={{
              marginTop: "0px",
              marginBottom: "30px",
              textAlign: "center",
              backgroundColor: "#005FB4",
              color: "white"
            }}
          >
            HELLASFON Business Telephony
          </h1>
          <div className={classes.container}>
            <GridContainer id="pricing">
              <ListItem>
                <Typography component="h4" variant="h4" color="primary">
                  Available Plans
                </Typography>
              </ListItem>
              <ListItem>
                <Typography component="h5" variant="body1" color="textPrimary">
                  Remove expensive communication fees and add lines starting from € 19,50/μήνα with unlimited phone calls Landlines and mobiles for all GR destinations.<br></br>
				  <b>SIP</b> is a protocol that a customer can connect to our nodes,
                  in order to reduce communication fees and get more lines/channels for your business.
                  You may check all details of our plans and pick the best according your needs.<br></br>
				  All HELLASFON plans are <strong>WITHOUT COMMITMENT</strong> and with <strong>LOWEST PRICE GUARANTEE</strong>.
                </Typography>
              </ListItem>
            </GridContainer>
          </div>
          <div className={classes.container}>
            <SnackbarContent
              className={classSnackbar}
              aria-describedby="client-snackbar"
              message={response}
            />

            {loading_plan_pack ? (
              <GridContainer justify="center">
                <CircularProgress size={50} />
              </GridContainer>
            ) : (
              <React.Fragment>
               <PriceTable
                id="pricing"
                url={"/reg/sip/"}
                plan_packs={retail_plan_packs}
              />

                <Typography component="h4" variant="h4" color="primary" style={{ marginBottom: "10px"}}>Extra Bundles</Typography>
                <GridContainer spacing={2}>
                  {plans.map(plan => (
                    <PlansTable
                      plan={plan}
                      key={plan.id_plan}
                      selectPlan={this.selectPlan}
                    />
                  ))}
                </GridContainer>

            </React.Fragment>
            )}
          </div>
          <AppSection />
          <VisaSection />
          <Footer />
        </div>
        <ScrollUpButton />
        <CookieConsent
            location="bottom"
            buttonText="ΟΚ συμφωνώ"
            cookieName="hellasfonCookie"
            style={{ background: "rgb(1, 120, 226)" }}
            buttonStyle={{ background: "#f50057", color: "white", fontSize: "15px", marginRight: "130px" }}
            expires={150}
        >
            We use cookies in this website to exchance user experience.{" "}
            <span style={{ fontSize: "12px" }}>
            By clicking any of the links in this page you agree to setup cookies.{" "}
            <a style={{color: "white", textDecoration: "underline"}} href="/privacy">More info</a>
            </span>

        </CookieConsent>
      </div>
    );
  }
}

SipTrunkPage.propTypes = {
  classes: PropTypes.object,
  retail_plan_packs: PropTypes.array.isRequired,
  getRetailPlanPacks: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  retail_plan_packs: state.retail_reducer.retail_plan_packs,
  plans: state.retail_reducer.plans
});

export default connect(
  mapStateToProps,
  { getRetailPlanPacks, getPlans }
)(withStyles(landingPageStyle)(SipTrunkPage));
