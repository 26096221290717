/*eslint-disable*/
import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { withStyles } from "@material-ui/core";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Typography from "@material-ui/core/Typography";
//import Link from '@material-ui/core/Link';
import Fab from "@material-ui/core/Fab";

import footerStyle from "assets/jss/material-kit-react/components/footerStyle.jsx";

function Footer({ ...props }) {
  const { classes, whiteFont } = props;
  const footerClasses = classNames({
    [classes.footer]: true,
    [classes.footerWhiteFont]: whiteFont,
  });
  const aClasses = classNames({
    [classes.a]: true,
    [classes.footerWhiteFont]: whiteFont,
  });
  return (
    <footer className={footerClasses}>
      <div className={classes.container}>
        <div className={classes.center}>
          <GridContainer style={{ marginTop: "0", textAlign: "left" }}>
            <GridItem xs={12} sm={12} md={3}>
              <Typography variant="h6">HOME</Typography>
              <Typography
                component="li"
                variant="subtitle2"
                style={{ listStyle: "none", padding: "5px 0 0 5px" }}
              >
                <a href="/en/rates" className={aClasses}>
                  Price Catalogs
                </a>
              </Typography>
              <Typography
                component="li"
                variant="subtitle2"
                style={{ listStyle: "none", padding: "5px 0 0 5px" }}
              >
                <a href="/en/didnumbers" className={aClasses}>
                  DID Numbers
                </a>
              </Typography>
              <Typography
                component="li"
                variant="subtitle2"
                style={{ listStyle: "none", padding: "5px 0 0 5px" }}
              >
                <a
                  href="/docs/hellasfon-foritotita-gr.pdf"
                  className={aClasses}
                >
                  Number Portability Form
                </a>
              </Typography>
              <Typography
                component="li"
                variant="subtitle2"
                style={{ listStyle: "none", padding: "5px 0 0 5px" }}
              >
                <a
                  href="/docs/DID-requirements-in -Greek.pdf"
                  className={aClasses}
                >
                  ID Verification
                </a>
              </Typography>
              <Typography
                component="li"
                variant="subtitle2"
                style={{ listStyle: "none", padding: "5px 0 0 5px" }}
              >
                <a href="/docs/EVCOFFICE_gr_manual.pdf" className={aClasses}>
                  EVCOFFICE Manual
                </a>
              </Typography>
              <Typography
                component="li"
                variant="subtitle2"
                style={{ listStyle: "none", padding: "5px 0 0 5px" }}
                          
              >
                <a href="/en/eula" className={aClasses}>
                  
                </a>
              </Typography>
            </GridItem>
            <GridItem xs={12} sm={12} md={3}>
              <Typography variant="h6">SERVICES</Typography>
              <ul style={{ margin: "0", padding: "0" }}>
                <Typography
                  component="li"
                  variant="subtitle2"
                  style={{ listStyle: "none", padding: "5px 0 0 5px" }}
                >
                  <a href="/en/retail" className={aClasses}>
                    For Individuals
                  </a>
                </Typography>
                <Typography
                  component="li"
                  variant="subtitle2"
                  style={{ listStyle: "none", padding: "5px 0 0 5px" }}
                >
                  <a href="/en/siptrunk" className={aClasses}>
                    DID Numbers & SIP TRUNK
                  </a>
                </Typography>
                <Typography
                  component="li"
                  variant="subtitle2"
                  style={{ listStyle: "none", padding: "5px 0 0 5px" }}
                >
                  <a href="/en/cloudpbx" className={aClasses}>
                    EVCOFFICE Cloud PBX
                  </a>
                </Typography>
                <Typography
                  component="li"
                  variant="subtitle2"
                  style={{ listStyle: "none", padding: "5px 0 0 5px" }}
                >
                  <a href="/en/faq" className={aClasses}>
                    F.A.Q.
                  </a>
                </Typography>
                                
                <Typography
                  component="li"
                  variant="subtitle2"
                  style={{ listStyle: "none", padding: "5px 0 0 5px" }}
                >
                  <a href="/en/eula" className={aClasses}>
                    Terms of Service
                  </a>
                </Typography>
              </ul>
            </GridItem>
            <GridItem xs={12} sm={12} md={3}>
              <Typography variant="h6">COMPANY</Typography>
              <ul style={{ margin: "0", padding: "0" }}>
                <Typography
                  component="li"
                  variant="subtitle2"
                  style={{ listStyle: "none", padding: "5px 0 0 5px" }}
                >
                  <a href="/en/company" className={aClasses}>
                    Company
                  </a>
                </Typography>
                <Typography
                  component="li"
                  variant="subtitle2"
                  style={{ listStyle: "none", padding: "5px 0 0 5px" }}
                >
                  <a
                    href="/docs/Hellasfon_partner_and_platform_certifications_and_awards.pdf"
                    className={aClasses}
                  >
                    About us
                  </a>
                </Typography>
                
                <Typography
                  component="li"
                  variant="subtitle2"
                  style={{ listStyle: "none", padding: "5px 0 0 5px" }}
                >
                  <a href="/en/job" className={aClasses}>
                    Job openings
                  </a>
                </Typography>
                <Typography
                  component="li"
                  variant="subtitle2"
                  style={{ listStyle: "none", padding: "5px 0 0 5px" }}
                >
                  <a href="/en/resellers" className={aClasses}>
                    Partners/Affiliates
                  </a>
                </Typography>
                <Typography
                  component="li"
                  variant="subtitle2"
                  style={{ listStyle: "none", padding: "5px 0 0 5px" }}
                >
                  <a href="/en/privacy-policy" className={aClasses}>
                    Privacy Policy
                  </a>
                </Typography>
                <Typography
                  component="li"
                  variant="subtitle2"
                  style={{ listStyle: "none", padding: "5px 0 0 5px" }}
                >
                  <a href="/en/contact" className={aClasses}>
                    Contact us
                  </a>
                </Typography>
              </ul>
            </GridItem>
            <GridItem xs={12} sm={12} md={3}>
              <Typography variant="h6">FIND US</Typography>
              <Typography variant="body1">
                Idomeneos 26, Heraklion, Crete, Greece, T.K. 71202,
                <br />
				Αρ. Γ.Ε.ΜΗ 077124727000< br/>< br/>
                Tel.:{" "}
                <a className={aClasses} href="tel:302811300307">
                  +302811300307
                </a>
                <br />
                Fax: +302811300308
              </Typography>
              <br />
              <div className={classes.socail}>
                <Fab
                  target="_blank"
                  href="https://www.facebook.com/hellasfon/"
                  color="inherit"
                  aria-label="facebook"
                  style={{ marginRight: "2px", marginTop: "2px" }}
                >
                  <i style={{ fontSize: "25px" }} className="fab fa-facebook" />
                </Fab>
                <Fab
                  target="_blank"
                  href="https://twitter.com/hellasfon"
                  color="inherit"
                  aria-label="twitter"
                  style={{ marginRight: "2px", marginTop: "2px" }}
                >
                  <i style={{ fontSize: "25px" }} className="fab fa-twitter" />
                </Fab>
                <Fab
                  target="_blank"
                  href="https://www.instagram.com/hellasfonnetworks/"
                  color="inherit"
                  aria-label="instagram"
                  style={{ marginRight: "2px", marginTop: "2px" }}
                >
                  <i
                    style={{ fontSize: "25px" }}
                    className="fab fa-instagram"
                  />
                </Fab>
                <Fab
                  target="_blank"
                  href="https://www.linkedin.com/in/hellasfon-networks-sa-984272173/"
                  color="inherit"
                  aria-label="linkedin"
                  style={{ marginRight: "2px", marginTop: "2px" }}
                >
                  <i style={{ fontSize: "25px" }} className="fab fa-linkedin" />
                </Fab>
              </div>
            </GridItem>
          </GridContainer>
        </div>
        <div className={classes.center}>
          All rights reserved &copy; {1900 + new Date().getYear()}{" "}
          <a href="/en/" className={aClasses}>
            Hellasfon Networks S.A.
          </a>
        </div>
      </div>
    </footer>
  );
}

Footer.propTypes = {
  classes: PropTypes.object.isRequired,
  whiteFont: PropTypes.bool,
};

export default withStyles(footerStyle)(Footer);
