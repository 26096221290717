import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import withStyles from "@material-ui/core/styles/withStyles";
import { TinyButton as ScrollUpButton } from "react-scroll-up-button";

// core components
import Header from "components/Header/Header.jsx";
import Parallax from "components/Parallax/Parallax.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Footer from "components/Footer/Footer.jsx";

import HeaderLinks from "components/Header/HeaderLinks.jsx";
import HeaderTopLinks from "components/Header/HeaderTopLinks.jsx";
import CircularProgress from "@material-ui/core/CircularProgress";

import landingPageStyle from "assets/jss/material-kit-react/views/landingPage.jsx";

import AppSection from "./Sections/AppSectionBusiness.jsx";
import VisaSection from "./Sections/VisaSection.jsx";
import CheckoutBusiness from "./Sections/CheckoutBusiness.jsx";
import CookieConsent from "react-cookie-consent";

import { connect } from "react-redux";
import { selectPlanPack, getPlans } from "actions/pbxActions";

class RegBussinessPage extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
    const { id } = this.props.match.params;
    this.props.selectPlanPack(id);
    this.props.getPlans();
  }

  render() {
    const { classes, pbx_plan_pack, loading, ...rest } = this.props;
    return (
      <div>
        <Header
          topLinks={<HeaderTopLinks />}
          rightLinks={<HeaderLinks />}
          logoUrl="/img/hellasfon_logo_white.jpg"
          fixed
          color="primary"
          changeColorOnScroll={{
            height: 50,
            color: "white"
          }}
          {...rest}
        />
        <Parallax color small />
        <div className={classNames(classes.main, classes.mainRaised)}>
          <h1
            style={{
              textAlign: "center",
              backgroundColor: "#005FB4",
              color: "white"
            }}
          >
            Εγγραφή EVCOFFICE
          </h1>
          <div className={classes.container} style={{marginTop: "0"}}>
            {loading ? (
              <GridContainer justify="center">
                <CircularProgress size={50} />
              </GridContainer>
            ) : (
              <CheckoutBusiness plan_pack={pbx_plan_pack} />
            )}
          </div>
          <AppSection />
          <VisaSection />
          <Footer />
        </div>
        <ScrollUpButton />
        <CookieConsent
            location="bottom"
            buttonText="ΟΚ συμφωνώ"
            cookieName="hellasfonCookie"
            style={{ background: "rgb(1, 120, 226)" }}
            buttonStyle={{ background: "#f50057", color: "white", fontSize: "15px", marginRight: "130px" }}
            expires={150}
        >
            Χρησιμοποιούμε cookies σε αυτόν τον ιστότοπο για να βελτιώσουμε την εμπειρία των χρηστών.{" "}
            <span style={{ fontSize: "12px" }}>
            Κάνοντας κλικ σε οποιονδήποτε σύνδεσμο σε αυτή τη σελίδα δίνετε τη συγκατάθεσή σας για να ορίσουμε cookies.{" "}
            <a style={{color: "white", textDecoration: "underline"}} href="/privacy">Περισσότερες πληροφορίες</a>
            </span>
            
        </CookieConsent>
      </div>
    );
  }
}

RegBussinessPage.propTypes = {
  classes: PropTypes.object
};

const mapStateToProps = state => ({
  pbx_plan_pack: state.pbx_reducer.pbx_plan_pack,
  loading: state.pbx_reducer.loading
});

export default connect(
  mapStateToProps,
  { selectPlanPack, getPlans }
)(withStyles(landingPageStyle)(RegBussinessPage));
