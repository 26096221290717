import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import withStyles from "@material-ui/core/styles/withStyles";
import { TinyButton as ScrollUpButton } from "react-scroll-up-button";
import Header from "components/Header/Header.jsx";
import Footer from "components/Footer/FooterEN.jsx";
import HeaderLinks from "components/Header/HeaderLinksEN.jsx";
import HeaderTopLinks from "components/Header/HeaderTopLinksEN.jsx";
import Parallax from "components/Parallax/Parallax.jsx";

import CookieConsent from "react-cookie-consent";

import landingPageStyle from "assets/jss/material-kit-react/views/landingPage.jsx";

//import FAQSection from "./Sections/FAQSection.jsx";
import AppSection from "./Sections/AppSectionEN.jsx";
import VisaSection from "./Sections/VisaSection.jsx";
import RateSection from "./Sections/RateSectionEN.jsx";
//import DIDSection from "./Sections/DIDSection.jsx";

class RatesPage extends React.Component {
  constructor(props) {
    window.scrollTo(0, 0);
    super(props);
  }

  render() {
    const { classes, ...rest } = this.props;

    return (
      <div>
        <Header
          topLinks={<HeaderTopLinks />}
          rightLinks={<HeaderLinks />}
          fixed
          logoUrl="/img/hellasfon_logo_white.jpg"
          color="primary"
          changeColorOnScroll={{
            height: 50,
            color: "white",
          }}
          {...rest}
        />
        <Parallax color small />
        <div className={classNames(classes.main, classes.mainRaised)}>
          <h2
            style={{
              textAlign: "center",
              backgroundColor: "#005FB4",
              color: "white",
            }}
          >
            {"ΧΡΟΝΟΧΡΕΩΣΗ"}
          </h2>

          <div className={classes.container} style={{ marginTop: "40px" }}>
            <RateSection />
          </div>
          
          <AppSection />
          <VisaSection />
          <Footer />
          <ScrollUpButton />
          <CookieConsent
            location="bottom"
            buttonText="ΟΚ I agree"
            cookieName="hellasfonCookie"
            style={{ background: "rgb(1, 120, 226)" }}
            buttonStyle={{
              background: "#f50057",
              color: "white",
              fontSize: "15px",
              marginRight: "130px",
            }}
            expires={150}
          >
            We use cookies in the website in order to improve customer's experience.
            {" "}
            <span style={{ fontSize: "12px" }}>
              By clicking in any link of this website, you conseνt to set up cookies.
              {" "}
              <a
                style={{ color: "white", textDecoration: "underline" }}
                href="/privacy"
              >
                More information
              </a>
            </span>
          </CookieConsent>
        </div>
      </div>
    );
  }
}

RatesPage.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(landingPageStyle)(RatesPage);
