import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import withStyles from "@material-ui/core/styles/withStyles";
import { TinyButton as ScrollUpButton } from "react-scroll-up-button";
import Header from "components/Header/Header.jsx";
import Footer from "components/Footer/Footer.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import HeaderLinks from "components/Header/HeaderLinks.jsx";
import HeaderTopLinks from "components/Header/HeaderTopLinks.jsx";
import Parallax from "components/Parallax/Parallax.jsx";
import CircularProgress from "@material-ui/core/CircularProgress";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import PlansTable from "./Sections/PlansTableSection.jsx";
import Typography from "@material-ui/core/Typography";

import CookieConsent from "react-cookie-consent";

import landingPageStyle from "assets/jss/material-kit-react/views/landingPage.jsx";

//import FAQSection from "./Sections/FAQSection.jsx";
import AppSection from "./Sections/AppSection.jsx";
import VisaSection from "./Sections/VisaSection.jsx";
//import RateSection from "./Sections/RateSection.jsx";
import ImageSection from "./Sections/ImageSection.jsx";

import PriceTable from "./Sections/PriceTableSection.jsx";
//import YouTube from "react-youtube";
import ListItem from "@material-ui/core/ListItem";

import { connect } from "react-redux";
import { getRetailPlanPacks, getPlans } from "actions/retailActions";

//import DialogAdvertisment from "./DialogAdvertisment";

class RetailPage extends React.Component {
  constructor(props) {
    window.scrollTo(0, 0);
    super(props);
    const { classes } = props;
    this.state = {
      classSnackbar: classes.hideSnackbar,
      response: "",
      value_radio: "device",
      loading_plan_pack: true,
    };
  }

  async componentDidMount() {
    await this.props.getRetailPlanPacks("no_device");
    await this.props.getPlans("retail");
    this.setState({
      loading_plan_pack: false,
    });
    setTimeout(() => {
      this.setState({ open: true });
    }, 1000);
  }
  handleChange = async (e) => {
    const { classes } = this.props;
    this.setState({
      classSnackbar: classes.hideSnackbar,
      value_radio: e.target.value,
      loading_plan_pack: true,
    });
    await this.props.getRetailPlanPacks(e.target.value);
    this.setState({
      loading_plan_pack: false,
    });
    if (this.props.retail_plan_packs.length === 0) {
      this.setState({
        classSnackbar: classes.infoSnackbar,
        response: "Δεν υπάρχουν σχέδια προς το παρόν",
      });
    }
  };
  selectPlan = async () => {
    return;
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  render() {
    const { classes, retail_plan_packs, plans, ...rest } = this.props;
    const { loading_plan_pack, classSnackbar, response } = this.state;
    return (
      <div>
        <Header
          color="transparent"
          logoUrl="/img/Hellasfon_logo.png"
          topLinks={<HeaderTopLinks />}
          rightLinks={<HeaderLinks />}
          fixed
          changeColorOnScroll={{
            height: 50,
            color: "white",
          }}
          {...rest}
        />
        <Parallax
          filter
          image={require("assets/img/Hellasfon_Networks_Retail.jpg")}
        >
          <div className={classes.container}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12} style={{paddingTop: '80px' }}>
                <h5>
                  <strong>
                    HELLASFON TELEPHONY : Απόκτησε ΣΤΑΘΕΡΟ αριθμό στο κινητό σου!
                  </strong>
                  <br />
                  <ul>
                    <li>Κατέβασε την ΔΩΡΕΑΝ Hellasfon εφαρμογή. </li>
                    <li>Κάνε <b>ΕΓΓΡΑΦΗ</b> και απόκτησε σταθερό αριθμό στο κινητό σου. </li>
                    <li>Χωρίς περιαγωγή Roaming και πρόσθετες χρεώσεις.</li>
					<li>Συνδυάζουμε την οικονομία της σταθερής με την ευελιξία της κινητής.</li>
                  </ul>
                  <strong>Τηλεφωνία για όλες τις χρήσεις!</strong>
                </h5>
                <Button
                  color="primary"
                  size="lg"
                  href="#pricing"
                  rel="noopener noreferrer"
                >
                  Εγγραφή
                </Button>
              </GridItem>
            </GridContainer>
          </div>
        </Parallax>
        <div className={classNames(classes.main, classes.mainRaised)}>
          <h1
            style={{
              marginTop: "0px",
              marginBottom: "30px",
              textAlign: "center",
              backgroundColor: "#005FB4",
              color: "white",
            }}
          >
            Hellasfon Telephony
          </h1>
          <div className={classes.container}>
            <GridContainer id="pricing">
              <ListItem>
                <Typography component="h4" variant="h4" color="primary">
                  Ποιότητα , ευελιξία και οικονομία με την HELLASFON
                </Typography>
              </ListItem>
              <ListItem>
                <Typography component="h5" variant="body1" color="textPrimary">
                  Με την Hellasfon έχεις σταθερό αριθμό στο κινητό σου.
				  Απαντάς από το κινητό σου χωρίς πρόσθετες χρεώσεις και μιλάς απεριόριστα πρός ολους.
                  Eξοικονομήστε χρήματα χρησιμοποιώντας την HELLASFON εφαρμογή παντού.<br></br>
                  <br></br>
                  <strong>Βήμα 1</strong> - Διάλεξε το πακέτο πού σου ταιριάζει.<br></br>
                  <strong>Βήμα 2</strong> – Ολοκλήρωσε την συνδρομή σου
                  προσθέτοντας προγράμματα δωρεάν χρόνου ομιλίας.<br></br>
                  <strong>Βήμα 3</strong> - Κατέβασε την δωρεάν{" "}
                  <strong>HELLASFON εφαρμογή</strong> και χρησιμοποίησε την{" "}
                  <strong>HELLASFON</strong> από το κινητό σου παντού.<br></br>
                  <br></br>
                </Typography>
              </ListItem>
            </GridContainer>
            <SnackbarContent
              className={classSnackbar}
              aria-describedby="client-snackbar"
              message={response}
            />
            <div id="pricing">
              {loading_plan_pack ? (
                <GridContainer justify="center">
                  <CircularProgress size={50} />
                </GridContainer>
              ) : (
                <React.Fragment>
                  <GridContainer style={{ marginTop: "0" }}>
                    <GridItem xs={12} sm={12} md={12}>
                      <PriceTable
                        url={"/reg/retail/"}
                        plan_packs={retail_plan_packs}
                      />
                    </GridItem>
                  </GridContainer>

                  <Typography
                    component="h4"
                    variant="h4"
                    color="primary"
                    style={{ marginBottom: "10px" }}
                  >
                    Πρόσθετα Προγράμματα Χρόνου Ομιλίας {" "}
                  </Typography>
                  <GridContainer spacing={2}>
                    {plans.map((plan) => (
                      <PlansTable
                        plan={plan}
                        key={plan.id_plan}
                        selectPlan={this.selectPlan}
                      />
                    ))}
                  </GridContainer>
                </React.Fragment>
              )}
            </div>
            <div className={classes.container} style={{ marginTop: "40px" }}>
              <ImageSection
                media="img/callcenter.jpg"
                headText="Hellasfon Κινητό, επαγγελματικό κινητό, Σταθερή τηλεφωνία"
                text1={[
                  <strong> - Για το σπίτι και για την επιχείρηση . </strong>,
                  " -  Σταθερή τηλεφωνία για το σπίτι ,καθώς και τηλεφωνικό κέντρο με επαγγελματικά προγράμματα κινητής και σταθερής τηλεφωνίας. ",
                  <br />,
                  <strong>
                    {" "}
                    - IOT sim, κινητή και σταθερή voip τηλεφωνία χωρίς περιαγωγή
                    .{" "}
                  </strong>,
                  "- Ακόμα δείτε τις ευρυζωνικές υπηρεσίες μας και το PBX CLOUD σύστημά μας.  ",
                ]}
              />
            </div>
            <div className={classes.container} style={{ marginTop: "40px" }}>
              <ImageSection
                media="img/hellasfonappdisplay_0.png"
                headText="Hellasfon εφαρμογή για το σταθερό σου! (Αndroid και IOS)"
                text1={[
                  <strong>HELLASFON APP</strong>,
                  " - Απεριόριστες κλήσεις ,video και chat για πάντα προς όλους τους Hellasfon συνδρομητές.",
                  <br />,
                  "- ",
                  <strong>Voicemail to email</strong>,
                  <br />,
                  "- Πλήρης ανάλυση ιστορικού κλήσεων από την εφαρμογή καθώς και απο την πύλη πελατών - ",
                  <strong>MYACCOUNT</strong>,
                ]}
                text2={[
                  <strong>Χωρίς κόστος</strong>,
                  "- Πάρε το σταθερό και την Hellasfon μαζί σου παντού. Κάλεσε/απάντησε από το κινητό σου χωρίς πρόσθετες χρεώσεις.",
                  <br />,
                  "- Δραστική μείωση χρεώσεων κινητής και σταθερής τηλεφωνίας.",
                  <br />,
                  "- Ανταλλάξτε αρχεία, τοποθεσίες, φωτογραφίες, ακόμα και SMS με την Hellasfon εφαρμογή.",
                  <br />,
				  
                  <strong>Χωρίς περιαγωγή</strong>,
                  "– Σε καλούν στο σταθερό και απαντάς από το κινητό οπου και αν είσαι στον κόσμο! Χωρίς πρόσθετες χρεώσεις!",
                  <br />,
                  "- Εσύ καλείς από την Hellasfon εφαρμογή αξιοποιώντας το δωρεάν χρόνο ομιλίας σου και τις χαμηλές χρεώσεις της HELLASFON.",
                ]}
              />
            </div>
          </div>
          <AppSection />
          <VisaSection />
          <Footer />

          <ScrollUpButton />
          <CookieConsent
            location="bottom"
            buttonText="ΟΚ συμφωνώ"
            cookieName="hellasfonCookie"
            style={{ background: "rgb(1, 120, 226)" }}
            buttonStyle={{
              background: "#f50057",
              color: "white",
              fontSize: "15px",
              marginRight: "130px",
            }}
            expires={150}
          >
            Χρησιμοποιούμε cookies σε αυτόν τον ιστότοπο για να βελτιώσουμε την
            εμπειρία των χρηστών.{" "}
            <span style={{ fontSize: "12px" }}>
              Κάνοντας κλικ σε οποιονδήποτε σύνδεσμο σε αυτή τη σελίδα δίνετε τη
              συγκατάθεσή σας για να ορίσουμε cookies.{" "}
              <a
                style={{ color: "white", textDecoration: "underline" }}
                href="/privacy"
              >
                Περισσότερες πληροφορίες
              </a>
            </span>
          </CookieConsent>
        </div>
      </div>
    );
  }
}

RetailPage.propTypes = {
  classes: PropTypes.object,
  retail_plan_packs: PropTypes.array.isRequired,
  getRetailPlanPacks: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  retail_plan_packs: state.retail_reducer.retail_plan_packs,
  plans: state.retail_reducer.plans,
});

export default connect(
  mapStateToProps,
  { getRetailPlanPacks, getPlans }
)(withStyles(landingPageStyle)(RetailPage));
