import {
    GET_PBX_PLAN_PACKS,
    SELECT_PLAN_PACK,
    GET_PLANS,
    VERIFY_MOBILE,
    REMOVE_ORDER,
    GET_LOCAL_ARES,
    VERIFY_CODE,
    CREATE_ORDER,
    APP_LOADING
  } from '../actions/types';
  
  const initialState = {
    pbx_plan_packs: [],
    pbx_plan_pack: {},
    loading: true,
    verify_mobile: [],
    local_areas: [],
    verify_code: false,
    plans: [],
    create_pbx: []
  };
  
  export default function(state = initialState, action) {
    switch (action.type) {
      case APP_LOADING:
      return {
        ...state,
        loading: action.payload,
      }
      case GET_LOCAL_ARES:
      return {
        ...state,
        local_areas: action.payload
      };
      case CREATE_ORDER:
      return {
        ...state,
        create_pbx: action.payload
      };
      case VERIFY_CODE:
      return {
        ...state,
        verify_code: action.payload
      };
      case REMOVE_ORDER:
      return {
        ...state,
        verify_mobile: ""
      };
      case VERIFY_MOBILE:
        return {
          ...state,
          verify_mobile: action.payload
        };
      case GET_PLANS:
        return {
          ...state,
          plans: action.payload,
          loading: false
        };
      case GET_PBX_PLAN_PACKS:
        return {
          ...state,
          pbx_plan_packs: action.payload,
          loading: false
        };
      case SELECT_PLAN_PACK:
        return {
          ...state,
          pbx_plan_pack: action.payload,
        };
      default:
        return state;
    }
  }