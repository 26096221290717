import React from "react";
import Paper from "@material-ui/core/Paper";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Typography from "@material-ui/core/Typography";
import AddressFormSIP from "./Register/AddressFormSIP";
import PhoneForm from "./Register/PhoneForm";
import Review from "./Register/Review";
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";

const checkoutStyle = theme => ({
  paper: {
    marginTop: "0",
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2)
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
    [theme.breakpoints.down("sm")]: {
      display: "flow-root"
    }
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end"
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1)
  }
});

const steps = [
  "Προσωπικά στοιχεία",
  "Τηλεφωνικό νούμερο",
  "Ελέγξτε την παραγγελία σας"
];

class CheckoutSectionSIP extends React.Component {
  constructor(props) {
    super(props);
    const { redirect } = props;
    this.state = {
      activeStep: redirect === 'sucess' || redirect === 'cancel' ? 3 : 0,
      company: "",
      vat: "",
      firstName: "",
      lastName: "",
      email: "",
      check_email: "",
      phonemobile: "",
      phone: "",
      address: "",
      tk: "",
      phonemove: "",
      local_area: 1,
      changePhone: false,
      accept: false,
      city: "",
      aoy: "",
      verify_mobile: ""
    };
    this.getStepContent = this.getStepContent.bind(this);
    this.handleNext = this.handleNext.bind(this);
    this.handleNextReview = this.handleNextReview.bind(this);
    this.handleBack = this.handleBack.bind(this);
  }

  getStepContent(step, steps) {
    switch (step) {
      case 0:
        return (
          <AddressFormSIP
            handleNext={this.handleNext}
            handleBack={this.handleBack}
            company={this.state.company}
            vat={this.state.vat}
            firstName={this.state.firstName}
            lastName={this.state.lastName}
            email={this.state.email}
            phonemobile={this.state.phonemobile}
            phone={this.state.phone}
            address={this.state.address}
            tk={this.state.tk}
            accept={this.state.accept}
            phonemove={this.state.phonemove}
            local_area={this.state.local_area}
            changePhone={this.state.changePhone}
            city={this.state.city}
            aoy={this.state.aoy}
          />
        );
      case 1:
        return (
          <PhoneForm
            activeStep={step}
            handleNext={this.handleNext}
            handleBack={this.handleBack}
            company={this.state.company}
            vat={this.state.vat}
            firstName={this.state.firstName}
            lastName={this.state.lastName}
            email={this.state.email}
            phonemobile={this.state.phonemobile}
            phone={this.state.phone}
            address={this.state.address}
            tk={this.state.tk}
            accept={this.state.accept}
            phonemove={this.state.phonemove}
            local_area={this.state.local_area}
            changePhone={this.state.changePhone}
            city={this.state.city}
            aoy={this.state.aoy}
          />
        );
      case 2:
        return (
          <Review
            activeStep={step}
            steps={steps}
            handleNext={this.handleNext}
            handleNextReview={this.handleNextReview}
            handleBack={this.handleBack}
            company={this.state.company}
            vat={this.state.vat}
            firstName={this.state.firstName}
            lastName={this.state.lastName}
            email={this.state.email}
            phonemobile={this.state.phonemobile}
            phone={this.state.phone}
            address={this.state.address}
            tk={this.state.tk}
            accept={this.state.accept}
            phonemove={this.state.phonemove}
            local_area={this.state.local_area}
            changePhone={this.state.changePhone}
            city={this.state.city}
            aoy={this.state.aoy}
            pattern={this.props.pattern}
          />
        );
      default:
        return (
          <AddressFormSIP
            activeStep={step}
            handleNext={this.handleNext}
            handleBack={this.handleBack}
          />
        );
    }
  }

  handleNextReview(verify_mobile) {
    window.scrollTo(0, 0);
    this.setState({
      activeStep: this.state.activeStep + 1,
      verify_mobile: verify_mobile
    });
  }

  handleNext(
    company,
    vat,
    firstName,
    lastName,
    email,
    phonemobile,
    phone,
    address,
    tk,
    accept,
    changePhone,
    phonemove,
    verify_mobile,
    local_area,
    city,
    aoy
  ) {
    this.setState({
      activeStep: this.state.activeStep + 1,
      company: company,
      vat: vat,
      firstName: firstName,
      lastName: lastName,
      email: email,
      phonemobile: phonemobile,
      phone: phone,
      address: address,
      tk: tk,
      accept: accept,
      changePhone: changePhone,
      phonemove: phonemove,
      local_area: local_area,
      city: city,
      aoy: aoy
    });
  }
  handleBack(
    company,
    vat,
    firstName,
    lastName,
    email,
    phonemobile,
    phone,
    address,
    tk,
    accept,
    changePhone,
    phonemove,
    local_area,
    city,
    aoy
  ) {
    this.setState({
      activeStep: this.state.activeStep - 1,
      company: company,
      vat: vat,
      firstName: firstName,
      lastName: lastName,
      email: email,
      phonemobile: phonemobile,
      phone: phone,
      address: address,
      tk: tk,
      accept: accept,
      phonemove: phonemove,
      local_area: local_area,
      changePhone: changePhone,
      city: city,
      aoy: aoy
    });
  }
  render() {
    const { classes, plan_pack, redirect, order, operation, items } = this.props;
    const { activeStep, verify_mobile } = this.state;
    return (
      <GridContainer justify="center" style={{marginTop: "0"}}>
        <GridItem xs={12} sm={12} md={12}>
          <Paper className={classes.paper}>
            <Typography component="h1" variant="h4" align="center">
              {activeStep + 1 + ". " + plan_pack.name}
            </Typography>
            <Typography component="h5" align="center">
              {plan_pack.description}
            </Typography>
            <Stepper activeStep={activeStep} className={classes.stepper}>
              {steps.map(label => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            {activeStep === steps.length && redirect === 'sucess' && operation !== 'banktransfer'  ? (
              <React.Fragment>
                <Typography variant="h5" gutterBottom>
                Σας ευχαριστούμε που μας επιλέξατε.
                </Typography>

                <ListItem className={classes.listItem}>
                <Typography variant="h6" className={classes.total}>ΚΩΔΙΚΟΣ ΠΑΡΑΓΓΕΛΙΑΣ:&nbsp;</Typography>
                  <Typography variant="h6" className={classes.total}>#</Typography>
                  <Typography variant="h6" id="transaction_id" className={classes.total}>
                    {order.order_id}
                  </Typography>
                </ListItem>

                <List disablePadding>
                {items.map((item) => (
                  <React.Fragment>
                    <ListItem className={classes.listItem} key={item.id}>
                      <ListItemText
                        primary={item.name}
                      />
                      <Typography variant="h6">{
                        (item.plan_pack_id !== 0) ? (parseFloat(item.startup_cost) + parseFloat(item.period_cost)).toFixed(2) 
                        : (item.plan_id !== 0) ? item.startup_cost
                        : item.cost 
                      }</Typography>
                    </ListItem>
                    <Divider />
                  </React.Fragment>
                ))}
                <ListItem className={classes.listItem}>
                  <ListItemText primary="ΠΟΣΟ" />
                  <Typography variant="h5" id="currency" className={classes.total}>
                    EUR
                  </Typography>
                  <Typography variant="h5"className={classes.total}>&nbsp;</Typography>
                  <Typography variant="h5" id="value" className={classes.total}>
                    {order.total_cost}
                  </Typography>
                </ListItem>
                </List>
                <Typography variant="subtitle1">
                  Σας έχουμε στείλει ένα email επιβεβαίωσης σχετικά 
                  με την παραγγελία σας. Θα λάβετε επιπλέον ενημέρωση
                  όταν η παραγγελία σας έχει αποσταλθεί.
                </Typography>

              </React.Fragment>
            )
            : (activeStep === steps.length && redirect === 'sucess' && operation === 'banktransfer') ? (
              <React.Fragment>
                <Typography variant="h5" gutterBottom>
                Σας ευχαριστούμε που μας επιλέξατε.
                </Typography>

                <ListItem className={classes.listItem}>
                <Typography variant="h6" className={classes.total}>ΚΩΔΙΚΟΣ ΠΑΡΑΓΓΕΛΙΑΣ:&nbsp;</Typography>
                  <Typography variant="h6" className={classes.total}>#</Typography>
                  <Typography variant="h6" id="transaction_id" className={classes.total}>
                    {order.order_id}
                  </Typography>
                </ListItem>

              <List disablePadding>
                {items.map((item) => (
                  <React.Fragment>
                    <ListItem className={classes.listItem} key={item.id}>
                      <ListItemText
                        primary={item.name}
                      />
                      <Typography variant="h6">{
                        (item.plan_pack_id !== 0) ? (parseFloat(item.startup_cost) + parseFloat(item.period_cost)).toFixed(2) 
                        : (item.plan_id !== 0) ? item.startup_cost
                        : item.cost 
                      }</Typography>
                    </ListItem>
                    <Divider />
                  </React.Fragment>
                ))}
                <ListItem className={classes.listItem}>
                  <ListItemText primary="ΠΟΣΟ" />
                  <Typography variant="h5" id="currency" className={classes.total}>
                    EUR
                  </Typography>
                  <Typography variant="h5"className={classes.total}>&nbsp;</Typography>
                  <Typography variant="h5" id="value" className={classes.total}>
                    {order.total_cost}
                  </Typography>
                </ListItem>
              </List>

              <Typography variant="subtitle1">
                <ul>
                  <li>Κατάσταση: Σε αναμονή εξόφλησης</li>
                </ul>
              </Typography>

              <Typography variant="h5" gutterBottom>
              EUROBANK: IBAN: <strong>GR60 0260 2760 0002 5020 0715 959</strong><br />
              ALPHA BANK: IBAN: <strong>GR09 0140 6640 6640 0233 0002 230</strong>
              </Typography>
              <Typography variant="h5" gutterBottom>
                Σημειώστε τον κωδικό παραγγελίας και το ονομά σας στο καταθετήριο.
                Στείλτε την απόδειξη κατάθεσης στο <a href="mailto:billing@hellasfon.com">billing@hellasfon.com</a>
                <br /><br /> ή με Fax: <a href="tel:2811300308">2811300308</a><br /><br /> 
                <strong>Σας ευχαριστούμε για την παραγγελία και περιμένουμε την πληρωμή σας!</strong>
                </Typography>
              </React.Fragment> 
            )
            : (activeStep === steps.length && redirect === 'cancel' )? (
              <React.Fragment>
                <Typography variant="h5" gutterBottom>
                Η ΣΥΝΑΛΛΑΓΗ ΜΕ ΤΗ ΠΙΣΤΩΤΙΚΗ ΣΑΣ ΚΑΡΤΑ ΔΕΝ ΕΙΝΑΙ ΕΦΙΚΤΗ - H ΣΥΝΑΛΛΑΓΗ ΔΕΝ ΕΓΚΡΙΝΕΤΑΙ - Η ΣΥΝΑΛΛΑΓΗ ΔΕΝ ΠΡΑΓΜΑΤΟΠΟΙΗΘΗΚΕ
                </Typography>
                <Typography variant="h6" gutterBottom>
                Συνήθεις Λόγοι Μη πραγματοποίησης της συναλλαγής
                </Typography>
                <Typography variant="h6" gutterBottom>
                  1. Λανθασμένη Καταχώρηση των στοιχείων της Κάρτας σας <br/>
                  2. Υπέρβαση του Πιστωτικού Ορίου της Κάρτας σας <br/>
                  3. Xαμηλή Ποιότητα της Σύνδεσης σας με το Internet <br/>
                  4. Ξαφνική Διακοπή της Σύνδεσης σας με το Internet <br/>
                </Typography>
                <Typography variant="subtitle1">
                Ο αριθμός παραγγελίας σας είναι <strong>#{order.order_id}</strong>. Επικοινωνήστε με την υποστήριξη για να ολοκληρώσετε την πληρωμή σας.
              </Typography>
              </React.Fragment>
            )
              : (activeStep === steps.length)? (
              <React.Fragment>
                <Typography variant="h5" gutterBottom>
                  Ευχαριστούμε για την παραγγελία σας.
                </Typography>
                <Typography variant="subtitle1">
                  Ο αριθμός παραγγελίας σας είναι <strong>#{verify_mobile.order_id}</strong>.
                  Σας έχουμε στείλει ένα email επιβεβαίωσης σχετικά
                  με την παραγγελία σας. Θα λάβετε επιπλέον ενημέρωση
                  όταν η παραγγελία σας έχει αποσταλθεί.
              </Typography>
              </React.Fragment>
            ) : (
              <React.Fragment>
                {this.getStepContent(activeStep, steps)}
              </React.Fragment>
            )}
          </Paper>
        </GridItem>
      </GridContainer>
    );
  }
}
CheckoutSectionSIP.propTypes = {
  classes: PropTypes.object
};

export default withStyles(checkoutStyle)(CheckoutSectionSIP);
