import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import withStyles from "@material-ui/core/styles/withStyles";
import { TinyButton as ScrollUpButton } from "react-scroll-up-button";
import Header from "components/Header/Header.jsx";
import Footer from "components/Footer/FooterEN.jsx";
import HeaderLinks from "components/Header/HeaderLinksEN.jsx";
import HeaderTopLinks from "components/Header/HeaderTopLinksEN.jsx";
import Parallax from "components/Parallax/Parallax.jsx";

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import Typography from "@material-ui/core/Typography";

import CardMedia from "@material-ui/core/CardMedia";
import Button from "components/CustomButtons/Button.jsx";

import landingPageStyle from "assets/jss/material-kit-react/views/landingPage.jsx";

import VisaSection from "../Sections/VisaSection.jsx";

import AppSection from "../Sections/AppSectionBusinessEN.jsx";

import CookieConsent from "react-cookie-consent";

class HotelPage extends React.Component {
  constructor(props) {
    window.scrollTo(0, 0);
    super(props);
  }
  render() {
    const { classes, ...rest } = this.props;

    return (
      <div>
        <Header
          topLinks={<HeaderTopLinks />}
          rightLinks={<HeaderLinks />}
          fixed
          logoUrl="../img/hellasfon_logo_white.jpg"
          color="primary"
          changeColorOnScroll={{
            height: 50,
            color: "white",
          }}
          {...rest}
        />
        <Parallax color small />
        <div className={classNames(classes.main, classes.mainRaised)}>
          <h1
            style={{
              textAlign: "center",
              backgroundColor: "#005FB4",
              color: "white",
              marginBottom: "0px",
            }}
          >
            {"Solutions for Hotels "}
          </h1>
          <GridContainer
            justify="center"
            style={{
              width: "100%",
              marginLeft: "0px",
              marginRight: "0px",
              marginTop: "0px",
            }}
          >
		  
		  
		  
            <GridItem cs={12} sm={12} md={6} style={{ padding: 10 }}>
              <h2>Direct Lines - High Quality connections for all international destinations</h2>
              <Typography variant="body1">
			  <ul>
			    <li><b>Low fees</b></li>
				<li>High Quality calls to destinations all over the world</li>
                <li>Unlimited Lines & Voice Channels</li>
				<li><b>Unlimited calls to Greece and GR Mobiles only with 19,50 €/month </b> (price include VAT 24%)</li>
				<li><b>FREE SIP Trunk</b> with all packages what you choose </li>
			  </ul>
				<br></br>
				<strong>Ask for an offer and get a quote according your needs; we will design a tailor made solution 
				without unnecessary costs or any other additional charges.
				</strong>
				<br /><p></p> 
              </Typography>
			  <Button href="/contact" color="primary">
                ASK FOR AN OFFER
              </Button>
			  
            </GridItem>
            <GridItem
              cs={12}
              sm={12}
              md={6}
              style={{ paddingRight: "0px", paddingLeft: "0px" }}
            >
              <CardMedia
                component="img"
                image="/img/hotels-solution.jpg"
                alt="Λύσεις Hellasfon για ξενοδοχεία"
                title="Hellasfon λύσεις για ξενοδοχεία"
              />
            </GridItem>
          </GridContainer>
          <GridContainer
            justify="center"
            style={{
              width: "100%",
              marginLeft: "0px",
              marginRight: "0px",
              marginTop: "0px",
            }}
          >
            <GridItem cs={12} sm={12} md={6} style={{ background: "#009bff" }}>
              <CardMedia
                style={{ padding: 30 }}
                component="img"
                image="/img/reception1.jpg"
                alt="Cloud Τηλεφωνικό κέντρο EVCOFFICE"
                title="Hellasfon - Cloud PBX EVCOFFICE"
              />
            </GridItem>
            <GridItem
              cs={12}
              sm={12}
              md={6}
              style={{ background: "#009bff", padding: 70 }}
            >
              <h2 style={{ color: "#fff" }}>Cloud PBX EVCOFFICE</h2>
              <Typography variant="body1" style={{ color: "#fff" }}>
			  <ul>
			    <li>Unlimited Lines & Voice Channels</li>
				<li>Easy Business Number</li>
				<li>Scalability for as many users as you like without the need to purchase equipment</li>
				<li>HD Voice Quality</li>
				<li>Digital Assistance - Automatic calls reception</li>
				<li>Platform for sending SMS messages for your business</li>
			  </ul>
				<br></br>
                Hellasfon has innovative services for business customers providing:
				  <ul>
					<li><b> HD Voice Quality </b></li>
					<li><b> Lower call rates without intermediaries! </b></li>
                  </ul>
			  <Button href="/contact" color="warning">
                  GET A QUOTE
              </Button>
              <br></br>
			 Soon one of our team will contact you to analyze your needs and find the best solution!
			 </Typography>
            </GridItem>
          </GridContainer>
          <GridContainer
            justify="center"
            style={{
              width: "100%",
              marginLeft: "0px",
              marginRight: "0px",
              marginTop: "0px",
            }}
          >
            <GridItem cs={12} sm={12} md={6} style={{ padding: 30 }}>
              <h2>Send SMS for your hotel via API </h2>
              <Typography variant="body1">
			  <ul>
			    <li> Send <b>SMS messages</b> to your hotel customer <b> through your website !</b></li>
                <li><b> REST API</b> for really easy interface.</li>
                <li> Credits <b>NEVER</b> get expired! </li>
			  </ul>
                <br></br>
				
				<h3><strong> Complete Solutions for your Hotel! </strong></h3>
				<Button href="https://myaccount.easyclicksms.com/register" color="primary">
                FREE REGISTRATION
              </Button>
			  <Button href="/sms" color="secondary">
                READ MORE
              </Button>
		      </Typography>
            </GridItem>
            <GridItem
              cs={12}
              sm={12}
              md={6}
              style={{ paddingRight: "0px", paddingLeft: "0px" }}
            >
              <CardMedia
                component="img"
                image="/img/sms1.jpg"
                alt="Υπηρεσίες SMS για ξενοδοχείαs"
                title="Hellasfon - Υπηρεσίες SMS για ξενοδοχεία"
              />
            </GridItem>
						
		  
          </GridContainer>
          <GridContainer
            justify="center"
            style={{
              width: "100%",
              marginLeft: "0px",
              marginRight: "0px",
              marginTop: "0px",
            }}
          >
           
            
          </GridContainer>
          <GridContainer
            justify="center"
            style={{
              width: "100%",
              marginLeft: "0px",
              marginRight: "0px",
              marginTop: "0px",
            }}
          >
            
            
          </GridContainer>
          <GridContainer
            justify="center"
            style={{
              width: "100%",
              marginLeft: "0px",
              marginRight: "0px",
              marginTop: "0px",
            }}
          >
                        
          </GridContainer>
          <GridContainer
            justify="center"
            style={{
              width: "100%",
              marginLeft: "0px",
              marginRight: "0px",
              marginTop: "0px",
            }}
          >
            
          </GridContainer>
          <GridContainer
            style={{
              width: "100%",
              marginLeft: "0px",
              marginRight: "0px",
              marginTop: "0px",
              display: "grid",
            }}
          >
		  
		  <AppSection />
		  </GridContainer>
		  
		  <VisaSection />
          <Footer />
        </div>
        <ScrollUpButton />
        <CookieConsent
          location="bottom"
          buttonText="ΟΚ συμφωνώ"
          cookieName="hellasfonCookie"
          style={{ background: "rgb(1, 120, 226)" }}
          buttonStyle={{
            background: "#f50057",
            color: "white",
            fontSize: "15px",
            marginRight: "130px",
          }}
          expires={150}
        >
          Χρησιμοποιούμε cookies σε αυτόν τον ιστότοπο για να βελτιώσουμε την
          εμπειρία των χρηστών σας.{" "}
          <span style={{ fontSize: "12px" }}>
            Κάνοντας κλικ σε οποιονδήποτε σύνδεσμο σε αυτή τη σελίδα δίνετε τη
            συγκατάθεσή σας για να ορίσουμε cookies.{" "}
            <a
              style={{ color: "white", textDecoration: "underline" }}
              href="/privacy"
            >
              Περισσότερες πληροφορίες
            </a>
          </span>
        </CookieConsent>
      </div>
    );
  }
}

HotelPage.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(landingPageStyle)(HotelPage);
