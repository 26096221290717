import React from "react";
//import ReactPlayer from 'react-player';
// nodejs library to set properties for components
import PropTypes from "prop-types";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core/";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const ExpansionPanel = withStyles({
  root: {
    border: "1px solid rgba(0, 0, 0, .125)",
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(Accordion);

const ExpansionPanelSummary = withStyles({
  root: {
    backgroundColor: "rgba(0, 0, 0, .03)",
    borderBottom: "1px solid rgba(0, 0, 0, .125)",
    marginBottom: -1,
    minHeight: 56,
    "&$expanded": {
      minHeight: 56,
    },
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expanded: {},
})(AccordionSummary);

const ExpansionPanelDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(AccordionDetails);

class FAQSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: "panel0",
    };
    this.handleChange = this.handleChange.bind(this);
  }
  handleChange(panel, expanded) {
    this.setState(
      expanded === panel ? { expanded: "panel0" } : { expanded: panel }
    );
  }

  render() {
    const { expanded } = this.state;
    return (
      <div>
        <h3 style={{ textAlign: "center", color: "#0178e2" }}>
          Διαβάστε όλες τις απάντησεις σε συχνές ερωτήσεις.
        </h3>
        <ExpansionPanel
          expanded={expanded === "panel3"}
          onChange={() => this.handleChange("panel3", expanded)}
        >
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3d-content"
            id="panel3d-header"
          >
            <Typography>
              Είμαι ιδιώτης συνδρομητής - Πως μπορώ να κάνω <b>εκτροπή κλήσης</b> από
              το <b>MY ACCOUNT</b> portal της Hellasfon ;
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Typography component={"span"}>
              <div className="panel3">
                <CardMedia
                  component="img"
                  alt="Hellasfon Κινητή, Σταθερή Τηλεφωνία. Ολοκληρωμένες Υπηρεσίες Τηλεπικοινωνιών"
                  image={require("../../assets/img/faq/ektroph_klhshs1.jpg")}
                  title="Hellasfon Κινητή, Σταθερή Τηλεφωνία. Ολοκληρωμένες Υπηρεσίες Τηλεπικοινωνιών"
                />
                Μπείτε στο{" "}
                <a href="https://myaccount.hellasfon.com/HF">MY ACCOUNT</a>{" "}
                portal για <b>ιδιώτες</b>.
                <br />
                Πηγαίνετε στο <b>Το Προφίλ μου</b> (1) και στη συνέχεια <b>Έκτροπή κλήσης </b> (2).<br />
                Εδώ εισάγετε σε ποιο αριθμό θέλετε να γίνει η εκτροπή κλήσεων.
                <br />
              </div>
            </Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel
          expanded={expanded === "panel4"}
          onChange={() => this.handleChange("panel4", expanded)}
        >
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel4d-content"
            id="panel4-header"
          >
            <Typography>
              Πως μπορώ να <b>πληρώσω τον λογαριασμό</b> μου και να ανανεώσω τον χρόνο
              ομιλίας μου στη HellasFon;
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Typography component={"span"}>
              <div className="panel4">
                <CardMedia
                  component="img"
                  alt="Hellasfon Κινητή, Σταθερή Τηλεφωνία. Ολοκληρωμένες Υπηρεσίες Τηλεπικοινωνιών"
                  height="300"
                  image={require("../../assets/img/faq/forw4.png")}
                  title="Hellasfon Κινητή, Σταθερή Τηλεφωνία. Ολοκληρωμένες Υπηρεσίες Τηλεπικοινωνιών"
                />
                Με την Hellasfon η εγγραφή και εξόφληση του λογαριασμού σας
                γίνετε αυτόματα:
                <ol>
                  <ul>
                    Η εξόφληση γίνεται μέσω πιστωτικής κάρτας, PAYPAL ή
                    τραπεζική κατάθεσης.
                  </ul>
                  <ul>
                    Με την ολοκλήρωση εγγραφής και επιλογής του πακέτου σας,
                    δημιουργείται μια μηνιαία συνδρομή.{" "}
                  </ul>
                  <ul>
                    Για αγορά χρόνου ομιλίας πέραν του πακέτου ή εκτός
                    προγράμματος απαιτείται αγορά επιπλέον μονάδων. Οι μονάδες
                    δεν λήγουν ποτέ και το υπόλοιπο σας μεταφέρεται στον επόμενο
                    μήνα.
                  </ul>
                  <ul>
                    Εξοφλείτε το πακέτο σας πληρώνοντας το πάγιο κάθε
                    μήνα, και μόνο όταν υπερβείτε τον δωρεάν χρόνο ομιλίας ή
                    καλέσετε εκτός πακέτου καταναλώνονται οι μονάδες.
                  </ul>
                  <ul>
                    Από τον υπολογιστή: Απευθείας από τo{" "}
                    <a href="https://myaccount.hellasfon.com/HF">MY ACCOUNT</a>{" "}
                    ,έπειτα στο : "O λογαριασμός μου" και στην συνέχεια
                    "Ανανέωση χρόνου ".
                  </ul>
                  <ul>
                    Από την Hellasfon εφαρμογή: Ρυθμίσεις -> επιλέγοντας "
                    Account" και Ανανέωση.
                  </ul>
                  <ul>
                    Με την επιλογή BALANCE TRANSFER, μπορείτε να μεταφέρετε
                    χρήματα και μονάδες σε άλλο συνδρομητή. Αυτόματα και χωρίς
                    καμία επιβάρυνση, έχετε την δυνατότητα να μεταφέρετε χρήματα
                    σε άλλον συνδρομητή από το υπόλοιπο σας.
                  </ul>
                </ol>
                Δεχόμαστε PAYPAL, πιστωτική κάρτα και τραπεζική κατάθεση.
              </div>
            </Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        
        <ExpansionPanel
          expanded={expanded === "panel8"}
          onChange={() => this.handleChange("panel8", expanded)}
        >
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel8d-content"
            id="panel8d-header"
          >
            <Typography>
              Πως μπορώ να <b>γίνω συνδρομητής</b> Hellasfon HOME;
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Typography component={"span"}>
              <div className="panel8">
                <b>Hellasfon ΗΟΜΕ</b>
                <ol>
                  <li>Συμπληρώστε την αίτηση εγγραφής.</li>
                  <li>
                    Με την δημιουργία του λογαριασμού, θα λάβετε ενα email με τον
                    καινούργιο αριθμό σας και το όνομα χρήστη / συνθηματικό για
                    πρόσβαση από τον{" "}
                    <a href="https://myaccount.hellasfon.com/HF">υπολογιστή</a>{" "}
                    σας αλλά και απο την Hellasfon εφαρμογή.
                  </li>
                  <li>
                    Κατεβάστε την Hellasfon εφαρμογή, και κάντε login χρησιμοποιώντας
                    τα στοιχεία που σας έχουμε στείλει.
                  </li>
                  <li>Η υπηρεσία σας είναι έτοιμη για χρήση!</li>
                </ol>
                Στο σπίτι :
                <ol>
                  <li>
                    Οι συνδρομητές Hellasfon HOME - μπορούν να χρησιμοποιούν την
                    Hellasfon εφαρμογή και τον{" "}
                    <a href="https://myaccount.hellasfon.com/HF">υπολογιστή</a>{" "}
                    τους για όλες τις υπηρεσίες μας.
                  </li>
                  <li>
                    Για χρήση της υπηρεσίας από μια σταθερή συσκευή του σπιτιού σας απαιτείται
                    μια <a href="https://shop.hellasfon.com/">IP συσκευή</a>.
                  </li>
                  <li>
                    Συνδέστε την{" "}
                    <a href="https://shop.hellasfon.com/">HF IP συσκευή</a> στο
                    ADSL/VDSL ρούτερ.
                  </li>
                  <li>
                    Όλες οι Hellasfon συσκευές είναι έτοιμες για χρήση και δέν
                    χρειάζονται παραμετροποίηση. Έρχονται ήδη προγραμματισμένες
                    από εμάς.
                  </li>
                  <li>Η υπηρεσία σας είναι έτοιμη την ίδια μέρα! </li>
                </ol>
                Για την ταυτοποίηση
                <ol>
                  <li>
                    Για την ταυτοποίηση και ενεργοποίηση του αριθμού τηλεφώνου,
                    στείλτε μας Φάξ στο 2811-300308 ή{" "}
                    <a href="mailto:support@hellasfon.com;">e-mail</a> την
                    ταυτότητα σας και ένα λογαριασμό ΔΕΚΟ. Δείτε αναλυτικά τα
                    απαραίτητα{" "}
                    <a href="/docs/GREEK-DID-REQUIREMENTS.pdf">
                      στοιχεία ταυτοποίησης
                    </a>
                    .
                  </li>
                  <li>
                    Για μεγαλύτερη ευκολία, στείλτε μας τα
                    στοιχεία ταυτοποίησης σαν συνημμένα από το FEEDBACK της
                    Hellasfon εφαρμογής.
                  </li>
                  <li>
                    Μέχρι την ολοκλήρωση της ταυτοποίησης ο Ελληνικός αριθμός
                    που σας έχει εκχωρηθεί θα παραμείνει δεσμευμένος για εσάς.
                  </li>
                  <li>
                    Περιμένοντας τα στοιχεία ταυτοποίησης μπορείτε να κάνετε
                    κανονική χρήση της υπηρεσίας. Σε αυτό το στάδιο, η Hellasfon
                    εφαρμογή χρησιμοποιεί προσωρινά τον αριθμό κινητού σας
                    τηλεφώνου σαν αναγνώριση στις εξερχόμενες κλήσεις σας (callerid).
                  </li>
                  <li>
                    Με την ολοκλήρωση της ταυτοποίησης, αυτόματα θα
                    ενεργοποιηθεί ο καινούργιος αριθμός στον λογαριασμό σας.
                  </li>
                </ol>
              </div>
            </Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel
          expanded={expanded === "panel12"}
          onChange={() => this.handleChange("panel12", expanded)}
        >
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel12d-content"
            id="panel12d-header"
          >
            <Typography>
              Τι <b>στοιχεία ταυτοποίησης</b> χρειάζονται για την έκδοση Ελληνικού
              τηλεφωνικού αριθμού;
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Typography component={"span"}>
              <div className="panel12">
                Το τελευταίο βήμα για την έκδοση Ελληνικού αριθμού τηλεφώνου
                βάσει της ισχύουσας νομοθεσίας είναι η ταυτοποίηση των
                στοιχείων σας. Για την έκδοση ενός Ελληνικού αριθμού θα πρέπει να μας
                αποστείλετε με Φαξ στο 2811-300308 ή με email στο{" "}
                <a href="mailto:support@hellasfon.com">support@hellasfon.com</a>{" "}
                τα απαραίτητα νομιμοποιητικά έγγραφα. Για μεγαλύτερη ευκολία σας,
                τραβήξτε μία φωτογραφία, τα νομιμοποιητικά έγγραφα με το κινητό
                σας και στείλτε τα μας σαν συνημμένα από το FEEDBACK της εφαρμογής. Με
                τη λήψη των εγγράφων και την ταυτοποίηση των στοιχείων
                ολοκληρώνεται η διαδικασία και ενεργοποιείται ο τηλεφωνικός
                αριθμός σας.
                <a href="/docs/GREEK-DID-REQUIREMENTS.pdf">Δείτε εδώ</a> τα
                απαραίτητα στοιχεία ταυτοποίησης για έκδοση Ελληνικού αριθμού.
              </div>
            </Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel
          expanded={expanded === "panel13"}
          onChange={() => this.handleChange("panel13", expanded)}
        >
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel13d-content"
            id="panel13d-header"
          >
            <Typography>Πως να συμπληρώσω την <b>αίτηση φορητότητας;</b></Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Typography component={"span"}>
              <div className="panel13">
                <CardMedia
                  component="img"
                  alt="Hellasfon Κινητή, σταθερή τηλεφωνία. Ολοκληρωμένες Υπηρεσίες Τηλεπικοινωνιών"
                  image={require("../../assets/img/faq/hellasfon-foritotita-gr-1_013.jpg")}
                  title="Hellasfon Κινητή, σταθερή τηλεφωνία. Ολοκληρωμένες Υπηρεσίες Τηλεπικοινωνιών"
                />
                Μπορείτε να κατεβάσετε την αίτηση φορητότητας από εδώ :{" "}
                <a href="/docs/hellasfon-foritotita-gr.pdf">
                  αίτηση φορητότητας
                </a>
                .
              </div>
            </Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel
          expanded={expanded === "panel14"}
          onChange={() => this.handleChange("panel14", expanded)}
        >
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel14d-content"
            id="panel14d-header"
          >
            <Typography>Μπορώ να <b>προσθέσω</b> ένα <b>Ελληνικό ή Διεθνή αριθμό;</b></Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Typography component={"span"}>
              <div className="panel14">
                <CardMedia
                  component="img"
                  alt="Hellasfon Κινητή, σταθερή τηλεφωνία. Ολοκληρωμένες Υπηρεσίες Τηλεπικοινωνιών"
                  image={require("../../assets/img/faq/arithoithl_014.jpg")}
                  title="Hellasfon Κινητή, σταθερή τηλεφωνία. Ολοκληρωμένες Υπηρεσίες Τηλεπικοινωνιών"
                />
                Με την εγγραφή σας, αμέσως δεσμεύεται ένας ελληνικός αριθμός για
                εσάς. Μπορείτε να αγοράσετε και να επιλέξετε ένα δεύτερο Ελληνικό /
                Διεθνή αριθμό απευθείας από την HF εφαρμογή ή από τις ρυθμίσεις
                στην πύλη πελατών στο{" "}
                <a href="https://myaccount.hellasfon.com/HF">MY ACCOUNT</a> .
                Επιλέξτε χώρα , πόλη και τον αριθμό που επιθυμείτε. Η εφαρμογή μας
                αμέσως θα ελέγξει την διαθεσιμότητα των αριθμών. Η αγορά είναι
                αυτόματη και άμεση και δεν υπάρχει καμία χρονική δέσμευση.
              </div>
            </Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel
          expanded={expanded === "panel15"}
          onChange={() => this.handleChange("panel15", expanded)}
        >
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel15d-content"
            id="panel15d-header"
          >
            <Typography>
              Τι είναι ο <b>DID "Αριθμός τηλεφώνου"</b> και τι μου προσφέρει;
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Typography component={"span"}>
              <div className="panel15">
                O όρος <b>DID (Direct Inward Dial) </b> περιγράφει ένα γεωγραφικό αριθμό
                τηλεφώνου μίας πόλης η περιοχής. Κάθε αριθμός τηλεφώνου είναι
                DID. Οι αριθμοί τηλεφώνου είναι μοναδικοί . Με την Hellasfon
                μπορείτε να έχετε πολλαπλούς αριθμούς από Ελλάδα και το
                εξωτερικό. Π.χ. Έχετε συγγενείς ή ταξιδεύετε στην Αγγλία;
                Αγοράστε έναν αριθμό σε οποιαδήποτε πόλη της Αγγλίας. Με αυτό
                τον τρόπο μπορούν να σας καλέσουν από την Αγγλία με ΑΣΤΙΚΗ
                χρέωση! Δεν υπάρχει χρονική δέσμευση, και μπορείτε να το
                ακυρώσετε όποτε Θέλετε. Επίσης με την Hellasfon έχετε τα
                παρακάτω πλεονεκτήματα.
                <ol>
                  <li>
                    Με τον Ελληνικό / Διεθνή αριθμό σας στην Hellasfon , μπορείτε να
                    λαμβάνετε κλήσεις απευθείας στο σταθερό και απο την Hellasfon
                    (HF) εφαρμογή , κάνοντας το κινητό σας, σταθερό ώστε να μην
                    χάνετε καμία κλήση .
                  </li>
                  <li>
                    Με την Hellasfon και την HF εφαρμογή παίρνεις παντού το
                    σταθερό μαζί σου! Καλείς από το κινητό σου, χωρίς τις χρεώσεις
                    κινητής τηλεφωνίας!{" "}
                  </li>
                  <li>
                    Καλείς Ελλάδα με την ίδια χαμηλή χρέωση και από το
                    εξωτερικό.
                  </li>
                  <li>Δεν έχει χρεώσεις περιαγωγής Roaming.</li>
                  <li>Δωρεάν όλα τα μηνύματα στον τηλεφωνητή σας.</li>
                  <li>
                    Για πρώτη φορά συνδυάζουμε Σταθερό, Κινητό και Yπηρεσίες
                    CHAT,VIDEOcalls και SMS σε μία εφαρμογή.
                  </li>
                </ol>
                <b>Μέ την Hellasfon όλοι κερδίζουν</b>
              </div>
            </Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel
          expanded={expanded === "panel18"}
          onChange={() => this.handleChange("panel18", expanded)}
        >
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel18d-content"
            id="panel18d-header"
          >
            <Typography>Πως μπορώ να <b>ενεργοποιήσω τον τηλεφωνητή μου;</b></Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Typography component={"span"}>
              <div className="panel18">
                <CardMedia
                  component="img"
                  alt="Hellasfon Κινητή, Σταθερή Τηλεφωνία. Ολοκληρωμένες Υπηρεσίες Τηλεπικοινωνιών"
                  image={require("../../assets/img/faq/to_voicemail.jpg")}
                  title="Hellasfon Κινητή, Σταθερή Τηλεφωνία. Ολοκληρωμένες Υπηρεσίες Τηλεπικοινωνιών"
                />
                Κάνετε login στην πύλη πελατών{" "}
                <a href="https://myaccount.hellasfon.com/HF">MY ACCOUNT</a> και
                επιλέξτε το μενού <b>Εκτροπή κλήσης</b> (1). Ακολουθείστε τα επόμενα 2 βήματα διαλέγοντας την
                προώθηση εισερχόμενων κλήσεων στον Τηλεφωνητή. Πατώντας το κουμπί
                <b> ΑΠΟΘΗΚΕΥΣΗ</b>, και η υπηρεσία ενεργοποιείται αυτόματα. Για
                την υπηρεσία τηλεφωνητή - ηχογραφήστε τον μήνυμα σας από το{" "}
                <a href="https://myaccount.hellasfon.com/HF">MYACCOUNT</a> ή από
                την Hellasfon HF εφαρμογή. Μπορείτε να ηχογραφήσετε πολλαπλά
                διαφορετικά μηνύματα όπως και να ορίσετε διαφορετικά μηνύματα
                για αυτούς που σας καλούν.
                <b>Οι δυνατότητες με την HELLASFON είναι απεριόριστες !</b>
              </div>
            </Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel
          expanded={expanded === "panel19"}
          onChange={() => this.handleChange("panel19", expanded)}
        >
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel19d-content"
            id="panel19d-header"
          >
            <Typography>Πως μπορώ να δω τις <b>αναλυτικές χρεώσεις κλήσεων</b> του λογαριασμού μου;</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Typography component={"span"}>
              <div className="panel19">
                Απο τον Υπολογιστή :<br />
                <ul>
                  <li>
                    Login στο "
                    <a href="https://myaccount.hellasfon.com/HF">MY ACCOUNT</a>"
                    και επιλέξτε <b>"Λογαριασμός"</b> για να δείτε τις κλήσεις που έχουν
                    γίνει ,καθώς και τις χρεώσεις που έχουν πραγματοποιηθεί.
                  </li>
                  <li>
                    Μπορείτε ακόμα να δείτε το υπόλοιπο του λογαριασμό σας , τις
                    πληρωμές σας ,το ιστορικό των κλήσεων σας , καθώς και όλη
                    την διαχείριση του λογαριασμού σας .
                  </li>
                </ul>
                Από την Hellasfon εφαρμογή το ιστορικό κλήσεων και οι χρεώσεις
                σας ειναι πάντα διαθέσιμες:
                <ul>
                  <li>
                    Με την είσοδο σας στην HF εφαρμογή πηγαίνετε στις ρυθμίσεις
                    στο μενού Αccount.
                  </li>
                  <li>
                    Δείτε τις χρεώσεις, τον πλήρη τιμοκατάλογο, επιλέξετε
                    πρόσθετα πακέτα χρόνου ομιλίας και αριθμούς τηλεφώνου από 65
                    χώρες.
                  </li>
                  <li>
                    Προσθέστε μονάδες στον λογαριασμό σας επιλέγοντας ποσό και
                    τρόπο πληρωμής. ( Πιστωτική κάρτα, PAYPAL ).
                  </li>
                  <li>
                    Η Hellasfon εφαρμογή σας προσφέρει όλες τις υπηρεσίες HOME από
                    το κινητό σας.
                  </li>
                </ul>
              </div>
            </Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel
          expanded={expanded === "panel21"}
          onChange={() => this.handleChange("panel21", expanded)}
        >
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel21d-content"
            id="panel21d-header"
          >
            <Typography>
              Πως μπορώ να <b>επιλέξω ένα πρόγραμμα ή πακέτο χρόνου ομιλίας;</b>
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Typography component={"span"}>
              <div className="panel21">
                <CardMedia
                  component="img"
                  alt="Hellasfon Κινητή, Σταθερή Τηλεφωνία. Ολοκληρωμένες Υπηρεσίες Τηλεπικοινωνιών"
                  image={require("../../assets/img/faq/screenshot_2019-01-16_hellasfon_021.jpg")}
                  title="Hellasfon Κινητή, Σταθερή Τηλεφωνία. Ολοκληρωμένες Υπηρεσίες Τηλεπικοινωνιών"
                />
                Κατά την ολοκλήρωση της εγγραφής, επιλέξτε το βασικό πακέτο που
                σας ταιριάζει. Με την ευελιξία που σας προσφέρει η HELLASFON,
                μπορείτε να προσθέσετε επιπλέον πακέτα χρόνου ομιλίας στον
                λογαριασμό σας για ένα μήνα ή μία χρονική περίοδο. Από τον υπολογιστή:
                Από το{" "}
                <a href="https://myaccount.hellasfon.com/HF">MY ACCOUNT </a>.
                Όλα τα πακέτα και οι προσφορές είναι διαθέσιμα στο portal της
                Hellasfon απο το MY ACCOUNT και στην ενότητα "Συνδρομές".
                Επιλέγεις το πρόγραμμα που επιθυμείς και τον τρόπο εξοφλήσης. Με
                την ολοκλήρωση, είναι άμεσα διαθέσιμο και έτοιμο για χρήση. Από την
                HF εφαρμογή (κινητό ή τάμπλετ): <b>Ρυθμίσεις</b> -> <b>Αccount</b> ->
                <b>Plans</b>. Δείτε όλα τα διαθέσιμα προγράμματα εδώ. Διαλέξτε το
                πρόγραμμα που σας ταιριάζει καθώς και τον τρόπο πληρωμής. Η
                αγορά και επιλογή είναι πανεύκολη και γίνεται μόνο με 2 κλικ.
                Μετά την ολοκλήρωση, είναι άμεσα διαθέσιμο και έτοιμο για χρήση.
              </div>
            </Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel
          expanded={expanded === "panel22"}
          onChange={() => this.handleChange("panel22", expanded)}
        >
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel22d-content"
            id="panel22d-header"
          >
            <Typography>
              Τι είδους <b>υπηρεσίες</b> μπορείτε να βρείτε στο <b>MY ACCOUNT</b> portal που
              διατίθονται σε πελάτες της Hellasfon;
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Typography component={"span"}>
              <div className="panel22">
                Στο MYACCOUNT θα βρείτε όλα τα ακόλουθα εργαλεία για την
                επικοινωνία :<br />
                <ul>
                  <li>
                    Ιστορικό όλων των κλήσεων. Εισερχόμενες, εξερχόμενες και
                    αναπάντητες κλήσεις.
                  </li>
                  <li>Αποστολή μηνυμάτων.</li>
                  <li>
                    Ηχογράφηση και προσαρμογή πολλαπλών μηνυμάτων στον
                    τηλεφωνητή.
                  </li>
                  <li>Εκτροπή κλήσεων.</li>
                  <li>
                    Συγχρονισμός επαφών κινητού με το Hellasfon Portal και HF
                    εφαρμογή, για εύκολη κλήση και chat με όλες της επαφές.
                  </li>
                  <li>
                    Chat απευθείας από το MYACCOUNT στον υπολογιστή και την HF
                    εφαρμογή, με όλες τις επαφές κινητού. - Fax to Email , Email
                    to Fax.
                  </li>
                  <li>
                    Balance transfer - Μεταφορά υπολοίπου σε άλλο HellasFon
                    συνδρομητή. Μοίρασε τον χρόνο ομιλίας με φίλους και γνωστούς
                    με ένα κλικ .
                  </li>
                  <li>ΔΩΡΕΑΝ κλήσεις προς όλους τους HF συνδρομητές.</li>
                  <li>
                    MOBILE TOP UP - καινούργια υπηρεσία που θα σου επιτρέπει να
                    προσθέτεις μονάδες κινητής τηλεφωνίας σε οποιοδήποτε κινητό
                    ανεξαρτήτως πάροχο, παγκοσμίως. ΕΡΧΕΤAI - σύντομα{" "}
                  </li>
                  <li>ΔΩΡΕΑΝ chat προς όλους τους HellasFon συνδρομητές.</li>
                  <li>
                    ΔΩΡΕΑΝ video-κλήσεις προς όλους τους HellasFon συνδρομητές.
                  </li>
                  <li>ΔΩΡΕΑΝ κλήσεις πρός όλους τους HellasFon συνδρομητές.</li>
                  <li>
                    DO NOT DISTURB . Μπλοκάρετε ανεπιθύμητους αριθμούς με ένα
                    κλίκ. Ότι πιο εύκολο.
                  </li>
                  <li>Profile για κάθε χρήστη.</li>
                  <li>Κατάλογος Επαφών.</li>
                  <li>Προώθηση κλήσεων.</li>
                  <li>Προσωπικός τηλεφωνητής.</li>
                  <li>Αναπάντητες κλήσεις.</li>
                  <li>
                    Αγορά πολλαπλών αριθμών τηλεφώνου από Ελλάδα και το
                    εξωτερικό στον ίδιο λογαριασμό.
                  </li>
                  <li>Ταχεία κλήση.</li>
                  <li>
                    Διαχείρηση εισερχομένων κλήσεων και χαρακτηριστικά κλήσεων.
                  </li>
                  <li>Πλήρης τιμοκατάλογος ONLINE.</li>
                  <li>Τop-up/Ανανέωση χρόνου ομιλίας.</li>
                </ul>
              </div>
            </Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel
          expanded={expanded === "panel23"}
          onChange={() => this.handleChange("panel23", expanded)}
        >
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel23d-content"
            id="panel23d-header"
          >
            <Typography>
              <b>Δεν έχω smartphone</b>, πως χρησιμοποιώ τον λογαριασμό μου στην
              HELLASFON σαν <b>χρονοκάρτα</b> όταν είμαι εκτός σπιτιού;
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Typography component={"span"}>
              <div className="panel23">
                Όταν χρησιμοποιείτε την υπηρεσία σαν χρονοκάρτα, παρακαλούμε
                ακολουθήστε τα παρακάτω βήματα :
                <ol>
                  <li>
                    Για την υπηρεσία " Χρονοκάρτα" πρέπει να ορίσετε αριθμητικό
                    συνθηματικό (Pin) για τον λογαριασμό σας.
                  </li>
                  <li>
                    Καλέστε τον αριθμό σας ανάλογα με την τοποθεσία σας.( στην
                    Ελλάδα κάλεσε τον Ελληνικό σου αριθμό, στην Αγγλία τον
                    Αγγλικό σου αριθμό ).
                  </li>
                  <li>Πληκτρολογήστε το PIN όταν σας ζητηθεί .</li>
                  <li>Καλέστε τον αριθμό που επιθυμείτε .</li>
                </ol>
                Αριθμοί πρόσβασης της HELLASFON:
                <br />
                <br />
                -USA/CANADA -Toll Free- 1-844-273-4478
                <br />
                -UK -Toll Free- 448002494307
                <br />
                -GREECE -Toll Free- 8078021
                <br />
                -CYPRUS -Toll Free- 80080011
                <br />
              </div>
            </Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel
          expanded={expanded === "panel24"}
          onChange={() => this.handleChange("panel24", expanded)}
        >
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel24d-content"
            id="panel24d-header"
          >
            <Typography>
              Πως μπορώ να <b>καλέσω κάποιον που δεν διαθέτει την HellasFon (HF)
              εφαρμογή</b>, σε σταθερά ή για κλήσεις στο εξωτερικό ;
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Typography component={"span"}>
              <div className="panel24">
                Μεταξύ των συνδρομητών της Hellasfon όλες οι κλήσεις, τα
                μηνύματα, Video-Κλήσεις και το Chat είναι πάντα ΔΩΡΕΑΝ.
                <br />
                Η HELLASFON σας προσφέρει χρήση της υπηρεσίας, με 3 τρόπους.
                ΥΠΟΛΟΓΙΣΤΗ - μέσω του MYACCOUNT, Κινητό - μέσω της HF εφαρμογή,
                και από το σταθερό σας.
                <br />
                Σε όλες της περιπτώσεις, για να καλέσεις συνδρομητές άλλων
                παρόχων, εντός και εκτός Ελλάδας, απλά πληκτρολογείς τον
                επιθυμητό αριθμό.
                <br />
                Αρχικά κατεβάστε και εγκαταστήσετε την HELLASFON εφαρμογή στο
                κινητό σας. Έπειτα χρησιμοποιώντας τον υπολογιστή σας, την
                Hellasfon εφαρμογή ή <br />
                το σταθερό σας, μπορείτε να καλέσετε οποιοδήποτε αριθμό σε όλο
                τον κόσμο αποφεύγοντας τις υψηλές χρεώσεις άλλων παρόχων. <br />
                <br />
                <br />
                <b>Με την Hellasfon είσαι πάντα κερδισμένος .</b>
              </div>
            </Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel
          expanded={expanded === "panel25"}
          onChange={() => this.handleChange("panel25", expanded)}
        >
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel25d-content"
            id="panel25d-header"
          >
            <Typography>
              Τι είναι το <b>Hellasfon εικονικό τηλέφωνο;</b>
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Typography component={"span"}>
              <div className="panel25">
                <CardMedia
                  component="img"
                  alt="Hellasfon Κινητή, Σταθερή Τηλεφωνία. Ολοκληρωμένες Υπηρεσίες Τηλεπικοινωνιών"
                  image={require("../../assets/img/faq/screenshot_2019-01-16_evcmobi_025.jpg")}
                  height="500"
                  width="350"
                  title="Hellasfon Κινητή, Σταθερή Τηλεφωνία. Ολοκληρωμένες Υπηρεσίες Τηλεπικοινωνιών"
                />
                Το Hellasfon SOFTPHONE είναι ένα ψηφιακό εικονικό τηλέφωνο, το
                οποίο βρίσκεται ενσωματωμένο στο MYACCOUNT portal. Δεν
                χρειάζεται εγκατάσταση και είναι πάντα διαθέσιμο για χρήση.
                Είναι ότι πρέπει για όσους ταξιδεύουν συχνά. Προσφέρει όλες τις
                υπηρεσίες,( Βίντεο, τηλέφωνο, και μηνύματα ) απευθείας από το
                MYACCOUNT portal της Hellasfon. Κάντε login από τον υπολογιστή
                σας στο "
                <a href="https://myaccount.hellasfon.com/HF">myaccount</a>" και
                κάνετε κλικ στο εικονίδιο του τηλεφώνου, που βρίσκεται στα δεξιά
                της οθόνης . Είναι πάρα πολύ εύκολο και απλό στη χρήση του . Θα
                χρειαστείτε ακουστικά και μικρόφωνο.
              </div>
            </Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel
          expanded={expanded === "panel26"}
          onChange={() => this.handleChange("panel26", expanded)}
        >
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel26d-content"
            id="panel26d-header"
          >
            <Typography>
              Τι είναι το <b>HELLASFON Home Telephony;</b> Πως μπορώ να έχω Ηellasfon
              και στο σπίτι;
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Typography component={"span"}>
              <div className="panel26">
                Συνδέστε το Hellasfon Smart Box ( Grandstream HT801) στο ρούτερ
                και στην τηλεφωνική συσκευή σας. <br />
                Και καλέσετε παντού. Κατεβάστε στο κινητό σας την ΔΩΡΕΑΝ
                Hellasfon εφαρμογή , μετατρέποντας το <br />
                σταθερό σε κινητό. Πράγματι το κινητό και το σταθερό έχουν γίνει
                ΕΝΑ με την Hellasfon. Κάλεσε από
                <br />
                το κινητό δωρεάν χρησιμοποιώντας την HF εφαρμογή. Όλες οι
                υπηρεσίες της Hellasfon προϋποθέτουν την παροχή Ίντερνετ.
                <br />
              </div>
            </Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel
          expanded={expanded === "panel28"}
          onChange={() => this.handleChange("panel28", expanded)}
        >
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel28d-content"
            id="panel28d-header"
          >
            <Typography>
              Πως μπορώ να κάνω <b>κλήση</b> εάν <b>δεν έχω κινητό και δεν είμαι σπίτι;</b>
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Typography component={"span"}>
              <div className="panel28">
                Η Hellasfon προσφέρει υπηρεσίες ΧΡΟΝΟΚΑΡΤΑΣ στους πελάτες. Δείτε
                πως δουλεύει :<br />
                <br />
                -Για την υπηρεσία " Χρονοκάρτα" πρέπει να ορίσετε αριθμητικό
                συνθηματικό (Pin) για τον λογαριασμό σας.
                <br />
                -Καλέστε τον αριθμό πρόσβασης ανάλογα με την τοποθεσία σας. (
                στην Ελλάδα , κάλεσε τον Ελληνικό αριθμό, στην Αγγλία τον
                Αγγλικό).
                <br />
                -Ακολουθήστε τα βήματα από την αυτόματη τηλεφωνήτρια, εισάγοντας
                τον επιθυμητό αριθμό και το Pin σας.
                <br />
                -Την 1η φορά που θα κάνετε χρήση της υπηρεσίας, θα χρειαστεί να
                πληκτρολογήσετε το Pin όταν σας ζητηθεί από τον αυτόματο
                τηλεφωνητή.
                <br />
                -Την επόμενη φορά, θα σας αναγνωρίσουμε από τον τηλεφωνικό
                αριθμό κλήσης, και δεν θα σα σας ξαναζητήσουμε το Pin <br />
                -Η υπηρεσία μπορεί και αναγνωρίζει τον πελάτη από τον τηλεφωνικό
                αριθμό όπου καλεί ( callerid).
                <br />
                Εάν καλέσετε τον αριθμό πρόσβασεις από διαφορετικό αριθμό, τότε
                θα χρειαστεί και πάλι Pin για την ταυτοποίηση. Οι τηλεφωνικοί
                αριθμοί
                <br />
                απο τους οποίους καλείτε τον αριθμό πρόσβασης, αποθηκεύονται
                στον λογαριασμό σας. Για να μπορέσετε να κάνετε όλες τις κλήσεις
                σας χωρίς την επιβολή του Pin.
                <br />
                <br />
                -Μπορείτε να διαγράψετε τους τηλεφωνικούς αριθμούς κλήσεων που
                έχετε αποθηκεύσει κάνοντας login στο "myaccount".
                <br />
                <br />
                Οι αριθμοί πρόσβασης για την ΧΡΟΝΟΚΑΡΤΑ:
                <br />
                <br />
                -USA/CANADA -Toll Free- 1-844-273-4478
                <br />
                -UK -Toll Free- 448002494307
                <br />
                -GREECE -Toll Free- 8078021
                <br />
                -CYPRUS -Toll Free- 80080011
                <br />
              </div>
            </Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>
		<ExpansionPanel
          expanded={expanded === "panel29"}
          onChange={() => this.handleChange("panel29", expanded)}
        >
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel29d-content"
            id="panel26d-header"
          >
            <Typography>
              Πως μπορώ να <b>ενταχθώ/απενταχθώ</b> στο <b>μητρώο του Άρθρου 11</b> του νόμου Ν.3471/2006 ;
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Typography component={"span"}>
              <div className="panel26">
                Η διαδικασία που πρέπει να ακολουθήσετε είναι πολύ απλή. 
                Κατεβάστε και συμπληρώστε την 
				<a href="/docs/MHTROO11GR.pdf"> Αίτηση Ένταξης/Απένταξης στο Μητρώο 11 </a> του νόμου Ν.3471/2006 
				και αποστείλτε μας την μέσω Fax στο <b>2811-300308</b> ή μέσω e-mail στο 
				{" "}
                <a href="mailto:support@hellasfon.com">support@hellasfon.com</a>{" "}
		     </div>
            </Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>
		
		<ExpansionPanel
          expanded={expanded === "panel30"}
          onChange={() => this.handleChange("panel30", expanded)}
        >
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel30d-content"
            id="panel26d-header"
          >
            <Typography>
              Πως μπορώ να <b>κατεβάσω</b> και να χρησιμοποιήσω το <b>Zoiper ?</b> (softphone) ;
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Typography component={"span"}>
              <div className="panel27">
                Η διαδικασία που πρέπει να ακολουθήσετε είναι πολύ απλή! <br></br>
                Πατήστε στο σύνδεσμο για να δείτε :  
				<a href="https://www.zoiper.com/en/page/4c709eec7c406f250a9f146f77909c4c?u=&h=&p=&o=&t=&a=&tr=">Οδηγίες για το κατέβασμα και ρύθμιση του Zoiper (συσκευές Android και iOS)</a>
				
		     </div>
            </Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </div>
    );
  }
}

FAQSection.propTypes = {
  classes: PropTypes.object,
};

export default FAQSection;
