import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import withStyles from "@material-ui/core/styles/withStyles";
import { TinyButton as ScrollUpButton } from "react-scroll-up-button";
import Header from "components/Header/Header.jsx";
import Footer from "components/Footer/Footer.jsx";
import HeaderLinks from "components/Header/HeaderLinks.jsx";
import HeaderTopLinks from "components/Header/HeaderTopLinks.jsx";
import Parallax from "components/Parallax/Parallax.jsx";
import Typography from "@material-ui/core/Typography";
import ListItem from "@material-ui/core/ListItem";

import CookieConsent from "react-cookie-consent";

import landingPageStyle from "assets/jss/material-kit-react/views/landingPage.jsx";

//import FAQSection from "./Sections/FAQSection.jsx";
import AppSection from "./Sections/AppSection.jsx";
import VisaSection from "./Sections/VisaSection.jsx";
//import RateSection from "./Sections/RateSection.jsx";
import DIDSection from "./Sections/DIDSection.jsx";

class DIDPage extends React.Component {
  constructor(props) {
    window.scrollTo(0, 0);
    super(props);
  }

  render() {
    const { classes, ...rest } = this.props;

    return (
      <div>
        <Header
          topLinks={<HeaderTopLinks />}
          rightLinks={<HeaderLinks />}
          fixed
          logoUrl="/img/hellasfon_logo_white.jpg"
          color="primary"
          changeColorOnScroll={{
            height: 50,
            color: "white",
          }}
          {...rest}
        />
        <Parallax color small />
        <div className={classNames(classes.main, classes.mainRaised)}>
          <h2
            style={{
              textAlign: "center",
              backgroundColor: "#005FB4",
              color: "white",
            }}
          >
            {"Αριθμοί Τηλεφώνου"}
          </h2>
          <div className={classes.container} style={{ marginTop: "40px" }}>
		   <ListItem>
                  <Typography
                    component="h5"
                    variant="body1"
                    color="textPrimary"
                  >
                    Με την εγγραφή σας, δεσμεύεται και ένας Ελληνικός τηλεφωνικός αριθμός
					ο οποίος δίνεται <b>ΔΩΡΕΑΝ</b> απο την <b>HELLASFON. </b> <p></p>
                    Υπάρχει επιπλέον η δυνατότητα να αγοράσετε και να επιλέξετε <b>πρόσθετους 
					Ελληνικούς αριθμούς </b> με χαμηλό κόστος <b>(2 € / μήνα)</b> απευθείας μέσα απο την
				    <b> HF εφαρμογή</b> ή μέσα απο την πύλη πελατών <b>MYACCOUNT</b> (Λογαριασμός-->Τηλεφωνικοί αριθμοί)
					<p></p> <b>Εκτός των Ελληνικών αριθμών τηλεφώνου , σας παρέχουμε την δυνατότητα
					να αγοράσετε επιπλέον τηλεφωνικούς αριθμούς για 61 χώρες.</b><p></p>Ο αριθμός αυτός θα προστεθεί
					στον λογαριασμό σας (μαζί με τον Ελληνικό) και θα είναι διαθέσιμος για 
					όλους τους πελάτες/φίλους/συγγενείς σας που βρίσκονται στο εξωτερικό.
					Έτσι θα έχετε την δυνατότητα να καλείτε σε αυτή την χώρα με αστική χρέωση όπου και αν
					βρίσκεστε, όπως επίσης και αυτοί να σας καλούν με αστική χρέωση! Εσείς απλά επιλέξτε την
					χώρα ,την πόλη και τον αριθμό που επιθυμείτε. Η εφαρμογή μας θα ελέγξει άμεσα την
					διαθεσιμότητα των αριθμών, και θα προχωρήσει στην δέσμευση τους.<p></p>
					<b>Η αγορά πρόσθετων τηλεφωνικών αριθμών είναι αυτόματη και άμεση, και δεν υπάρχει καμία χρονική 
					δέσμευση ή συμβόλαιο!</b>

                  </Typography>
            </ListItem>

            <DIDSection />
			<ListItem>
                  <Typography
                    component="h5"
                    variant="body1"
                    color="textPrimary"
                  >
                    <p></p>
					<b>Η αγορά πρόσθετων τηλεφωνικών αριθμών είναι αυτόματη και άμεση, και δεν υπάρχει καμία χρονική 
					δέσμευση ή συμβόλαιο!</b>

                  </Typography>
            </ListItem>
          </div>

          <AppSection />
          <VisaSection />
          <Footer />
          <ScrollUpButton />
          <CookieConsent
            location="bottom"
            buttonText="ΟΚ συμφωνώ"
            cookieName="hellasfonCookie"
            style={{ background: "rgb(1, 120, 226)" }}
            buttonStyle={{
              background: "#f50057",
              color: "white",
              fontSize: "15px",
              marginRight: "130px",
            }}
            expires={150}
          >
            Χρησιμοποιούμε cookies σε αυτόν τον ιστότοπο για να βελτιώσουμε την
            εμπειρία των χρηστών.{" "}
            <span style={{ fontSize: "12px" }}>
              Κάνοντας κλικ σε οποιονδήποτε σύνδεσμο σε αυτή τη σελίδα δίνετε τη
              συγκατάθεσή σας για να ορίσουμε cookies.{" "}
              <a
                style={{ color: "white", textDecoration: "underline" }}
                href="/privacy"
              >
                Περισσότερες πληροφορίες
              </a>
            </span>
          </CookieConsent>
        </div>
      </div>
    );
  }
}

DIDPage.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(landingPageStyle)(DIDPage);
