import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import withStyles from "@material-ui/core/styles/withStyles";
import { TinyButton as ScrollUpButton } from "react-scroll-up-button";
import Header from "components/Header/Header.jsx";
import Footer from "components/Footer/FooterEN.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import HeaderLinks from "components/Header/HeaderLinksEN.jsx";
import HeaderTopLinks from "components/Header/HeaderTopLinksEN.jsx";
import Parallax from "components/Parallax/Parallax.jsx";
//import CircularProgress from "@material-ui/core/CircularProgress";
import SnackbarContent from "@material-ui/core/SnackbarContent";
//import PlansTable from "./Sections/PlansTableSection.jsx";
//import Typography from "@material-ui/core/Typography";
import CookieConsent from "react-cookie-consent";
import landingPageStyle from "assets/jss/material-kit-react/views/landingPage.jsx";
//import FAQSection from "./Sections/FAQSection.jsx";
import AppSection from "./Sections/AppSectionEN.jsx";
import VisaSection from "./Sections/VisaSection.jsx";
//import RateSection from "./Sections/RateSection.jsx";
import ImageSection from "./Sections/ImageSection.jsx";
//import PriceTable from "./Sections/PriceTableSection.jsx";
//import YouTube from "react-youtube";
//import ListItem from "@material-ui/core/ListItem";
import { connect } from "react-redux";
import { getRetailPlanPacks, getPlans } from "actions/retailActions";

//import DialogAdvertisment from "./DialogAdvertisment";

class RetailPage extends React.Component {
  constructor(props) {
    window.scrollTo(0, 0);
    super(props);
    const { classes } = props;
    this.state = {
      classSnackbar: classes.hideSnackbar,
      response: "",
      value_radio: "device",
      loading_plan_pack: true,
    };
  }

  async componentDidMount() {
    await this.props.getRetailPlanPacks("no_device");
    await this.props.getPlans("retail");
    this.setState({
      loading_plan_pack: false,
    });
    setTimeout(() => {
      this.setState({ open: true });
    }, 1000);
  }
  handleChange = async (e) => {
    const { classes } = this.props;
    this.setState({
      classSnackbar: classes.hideSnackbar,
      value_radio: e.target.value,
      loading_plan_pack: true,
    });
    await this.props.getRetailPlanPacks(e.target.value);
    this.setState({
      loading_plan_pack: false,
    });
    if (this.props.retail_plan_packs.length === 0) {
      this.setState({
        classSnackbar: classes.infoSnackbar,
        response: "Δεν υπάρχουν σχέδια προς το παρόν",
      });
    }
  };
  selectPlan = async () => {
    return;
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  render() {
    const { classes, retail_plan_packs, plans, ...rest } = this.props;
    //const { loading_plan_pack, classSnackbar, response } = this.state;
    return (
      <div>
        <Header
          color="transparent"
          logoUrl="/img/Hellasfon_logo.png"
          topLinks={<HeaderTopLinks />}
          rightLinks={<HeaderLinks />}
          fixed
          changeColorOnScroll={{
            height: 50,
            color: "white",
          }}
          {...rest}
        />
        <Parallax
          filter
          image={require("assets/img/iot2.png")}
        >
          <div className={classes.container}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12} style={{paddingTop: '80px' }}>
                <h5>
                  <strong>
                    360ᵒ IoT DATA SIM CARD : INTERNET access to ALL countries of the world!
                  </strong>
                  <br />
                  <ul>
                    <li><b> 200+ countries </b> coverage</li>
                    <li>Suitable for <b>data use (Data & SMS) </b> </li>
                    <li><b> Save up to 85% </b> on roaming </li>
					<li><b>EXPRESS</b> delivery to your place and <b>INSTANT</b> activation </li>
                  </ul>
                  <strong>ONE SIM for internet use everywhere!</strong>
                </h5>
                <Button
                  color="primary"
                  size="lg"
                  href="https://shop.hellasfon.com/index.php?route=product/category&path=73_70"
                  rel="noopener noreferrer"
                >
                  Buy now
                </Button>
              </GridItem>
            </GridContainer>
          </div>
        </Parallax>
        <div className={classNames(classes.main, classes.mainRaised)}>
          <h1
            style={{
              marginTop: "0px",
              marginBottom: "30px",
              textAlign: "center",
              backgroundColor: "#005FB4",
              color: "white",
            }}
          >
            360ᵒ IoT Data Sim Card
          </h1>
          <div className={classes.container}>
            <GridContainer id="pricing">
              
              
            </GridContainer>
            <SnackbarContent
              //className={classSnackbar}
              aria-describedby="client-snackbar"
              //message={response}
            />
            
            <div className={classes.container} style={{ marginTop: "40px" }}>
              <ImageSection
                media="../img/iot_sim.png"
                headText="Internet access everywhere. For every device. 2,29€ /month. "
                text1={[
                  <strong> - Internet everywhere. </strong>,
				  <br />,
                  " Connect your device with a sim card that has coverage in 200 countries using 500 and more operators easily and directly. ",
                  <br />,
				  <br />,
                  <strong>
                    {" "}
                    - For every device you want to have online.{" "}
                  </strong>,
				  <br />,
                  " Whether it is the GPS of your favorite vehicle or a simple IoT sensor , internet access is now direct and low cost.  ",
				  
                ]}
              />
            </div>
            <div className={classes.container} style={{ marginTop: "40px" }}>
              <ImageSection
                media="../img/iot_electronics.jpg"
                headText="Smart connectivity. Expandible for every IoT Project. "
                text1={[
                  <strong>Machine to Machine (M2M)</strong>,
				  <br />,
                  " - Machine to Machine connectivity has become easier than ever.",
                  <br />,
                  " ",
                  <strong>Total Control</strong>,
                  <br />,
                  "- Add unlimited devices and manage your devices sim cards easily from your personal control panel .  ",
                  
                ]}
                text2={[
                  <strong>Extensive system  </strong>,
				  <br />,
                  "- Expand your existing Internet of Things system easily , quickly and economically.",
                  <br />,
                  "- Reduce your monthly expenses in mobile telephony .",
                  <br />,
                  " ",
                  <br />,
				                    
                ]}
              />
            </div>
          </div>
          <AppSection />
          <VisaSection />
          <Footer />

          <ScrollUpButton />
          <CookieConsent
            location="bottom"
            buttonText="ΟΚ συμφωνώ"
            cookieName="hellasfonCookie"
            style={{ background: "rgb(1, 120, 226)" }}
            buttonStyle={{
              background: "#f50057",
              color: "white",
              fontSize: "15px",
              marginRight: "130px",
            }}
            expires={150}
          >
            Χρησιμοποιούμε cookies σε αυτόν τον ιστότοπο για να βελτιώσουμε την
            εμπειρία των χρηστών.{" "}
            <span style={{ fontSize: "12px" }}>
              Κάνοντας κλικ σε οποιονδήποτε σύνδεσμο σε αυτή τη σελίδα δίνετε τη
              συγκατάθεσή σας για να ορίσουμε cookies.{" "}
              <a
                style={{ color: "white", textDecoration: "underline" }}
                href="/privacy"
              >
                Περισσότερες πληροφορίες
              </a>
            </span>
          </CookieConsent>
        </div>
      </div>
    );
  }
}

RetailPage.propTypes = {
  classes: PropTypes.object,
  retail_plan_packs: PropTypes.array.isRequired,
  getRetailPlanPacks: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  retail_plan_packs: state.retail_reducer.retail_plan_packs,
  plans: state.retail_reducer.plans,
});

export default connect(
  mapStateToProps,
  { getRetailPlanPacks, getPlans }
)(withStyles(landingPageStyle)(RetailPage));
