import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
import CardMedia from "@material-ui/core/CardMedia";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import ListItem from "@material-ui/core/ListItem";
import YouTube from "react-youtube";

import teamStyle from "assets/jss/material-kit-react/views/landingPageSections/teamStyle.jsx";

class ImageSection extends React.Component {
  render() {
    const { classes, media, headText, text1, text2, youtube } = this.props;

    return (
      <div className={classes.section}>
        <div className="body" style={{ color: "white" }}>
          <div className="apps" style={{ display: "block", color: "black" }}>
            <GridContainer justify="center">
              <GridItem cs={12} sm={12} md={6}>
                <ListItem>
                  <Typography component="h4" variant="h4" color="primary">
                    {headText}
                  </Typography>
                </ListItem>
                <ListItem>
                  <Typography
                    component="h5"
                    variant="body1"
                    color="textPrimary"
                  >
                    {text1}
                  </Typography>
                </ListItem>
                <ListItem>
                  <Typography
                    component="h5"
                    variant="body1"
                    color="textPrimary"
                  >
                    {text2}
                  </Typography>
                </ListItem>
              </GridItem>

              <GridItem cs={8} sm={8} md={6}>
                {youtube ? (
                  <YouTube className="youtube-video" videoId={youtube} />
                ) : (
                  <CardMedia
                    component="img"
                    alt="Hellasfon Κινητή, Σταθερή voip Τηλεφωνία. Ολοκληρωμένες Υπηρεσίες Τηλεπικοινωνιών"
                    image={media}
                    title="Hellasfon Κινητή, Σταθερή voip Τηλεφωνία. Ολοκληρωμένες Υπηρεσίες Τηλεπικοινωνιών"
                  />
                )}
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>
    );
  }
}

ImageSection.propTypes = {
  classes: PropTypes.object
};

export default withStyles(teamStyle)(ImageSection);
