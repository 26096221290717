import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import ReCAPTCHA from "react-recaptcha";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Typography from "@material-ui/core/Typography";
import green from "@material-ui/core/colors/green";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import CircularProgress from "@material-ui/core/CircularProgress";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import axios from "axios";
import Fab from "@material-ui/core/Fab";
import scrollIntoView from "scroll-into-view-if-needed";

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";

import workStyle from "assets/jss/material-kit-react/views/landingPageSections/workStyle.jsx";

const GreenCheckbox = withStyles({
  root: {
    color: green[400],
    "&$checked": {
      color: green[600]
    }
  },
  checked: {}
})(props => <Checkbox color="default" {...props} />);

class WorkSection extends React.Component {
  constructor(props) {
    super(props);
    const { classes } = props;
    this.checkCaptacha = this.checkCaptacha.bind(this);
    this.state = {
      subject: "",
      name: "",
      phone: "",
      email: "",
      message: "",
      accept: false,
      isVerified: false,
      recaptchaClass: "",
      loading: false,
      classSnackbar: classes.hideSnackbar,
      response: "",
      errors: {
        subject: false,
        name: false,
        email: false,
        phone: false,
        message: false,
        accept: false
      }
    };
  }

  checkCaptacha(response) {
    if (response) {
      this.setState({ isVerified: true, recaptchaClass: "" });
    }
  }
  onloadCaptcha() {
    console.log("recaptcha loaded");
  }
  onSubmit = async e => {
    e.preventDefault();

    const { name, subject, email, message, phone, accept, isVerified, loading } = this.state;
    const { classes } = this.props;
    const newContact = {
      name,
      email,
      message,
      phone,
      subject
    };
    const login = {
      dfa3424: "daDAS!@DFSGHFGJ(*)hGdffser4324765~hfgh"
    };

    // Check For Errors
    if (name === "" || name.length < 3 || name.length > 100) {
      this.setState({ errors: { name: true } });
      const node = document.getElementById("name");
      scrollIntoView(node, { behavior: "smooth", scrollMode: "if-needed" });
      return;
    }
    if (
      email === "" ||
      !email.match(/^[a-z._A-Z0-9]+@((?:[-a-z0-9]+\.)+[a-z]{2,})$/) ||
      email.length > 100
    ) {
      this.setState({
        errors: { email: true },
        classSnackbar: classes.errorSnackbar,
        response: "απαιτείται διεύθυνση ηλεκτρονικού ταχυδρομείου"
      });
      const node = document.getElementById("email");
      scrollIntoView(node, { behavior: "smooth", scrollMode: "if-needed" });
      return;
    }

    if (subject === "" || subject.length < 3 || subject.length > 100) {
      this.setState({ errors: { subject: true } });
      const node = document.getElementById("subject");
      scrollIntoView(node, { behavior: "smooth", scrollMode: "if-needed" });
      return;
    }

    if (message === "" || message.length < 3 || message.length > 100) {
      this.setState({ errors: { message: true } });
      const node = document.getElementById("message");
      scrollIntoView(node, { behavior: "smooth", scrollMode: "if-needed" });
      return;
    }

    if (accept === false) {
      this.setState({ errors: { accept: true } });
      const node = document.getElementById("accept");
      scrollIntoView(node, { behavior: "smooth", scrollMode: "if-needed" });
      return;
    }
    if (!isVerified) {
      this.setState({ recaptchaClass: "recaptcha-error" });
      return;
    }

    if (!loading) {
      this.setState({ loading: true });
      // get the authorization api token
      try {
        const res = await axios.post(
          `https://myaccount.hellasfon.com/pbxres/api/fsdffgdg670dbmfsfv90cxxiy`,
          login
        );
        const header = {
          Authorization: "Bearer " + res.data.dfa3424
        };
        // sent email

        try {
          await axios.post(
            `https://myaccount.hellasfon.com/pbxres/api/sentemail`,
            newContact,
            { headers: header }
          );
          this.setState({
            loading: false,
            classSnackbar: classes.successSnackbar,
            response: "Το μήνυμα αποστάλθηκε."
          });

          // clear state
          this.setState({
            subject: "",
            name: "",
            email: "",
            phone: "",
            message: "",
            accept: false,
            isVerified: false,
            recaptchaClass: "",
            loading: false,
            errors: {
              subject: false,
              name: false,
              email: false,
              message: false,
              accept: false
            }
          });
          await axios.post(
            `https://sip1.easyvoipcall.com/pbxres/api/jfg5345hfgh4545b4234234gv`,
            "",
            { headers: header }
          );
        } catch (e) {
          await axios.post(
            `https://sip1.easyvoipcall.com/pbxres/api/jfg5345hfgh4545b4234234gv`,
            "",
            { headers: header }
          );
          // display error mesage
          this.setState({
            loading: false,
            classSnackbar: classes.errorSnackbar,
            response: "Κάτι πήγε στραβά πάλι."
          });
        }
      } catch (e) {
        this.setState({
          loading: false,
          classSnackbar: classes.errorSnackbar,
          response: "Κάτι πήγε στραβά πάλι."
        });
      }
    }
  };
  onChange = e => {
    this.setState(
      e.target.id === "accept"
        ? {
            [e.target.id]: e.target.checked
          }
        : (e.target.value === "" && e.target.id === "subject") ||
          (e.target.id === "subject" && e.target.value.length < 3)
        ? {
            [e.target.id]: e.target.value,
            errors: {
              [e.target.id]: true
            }
          }
        : (e.target.value === "" && e.target.id === "phone") ||
          (e.target.id === "phone" && e.target.value.length < 3)
        ? {
            [e.target.id]: e.target.value,
            errors: {
              [e.target.id]: true
            }
          }
        : (e.target.value === "" && e.target.id === "message") ||
          (e.target.id === "message" && e.target.value.length < 3)
        ? {
            [e.target.id]: e.target.value,
            errors: {
              [e.target.id]: true
            }
          }
        : (e.target.value === "" && e.target.id === "name") ||
          (e.target.id === "name" && e.target.value.length < 3)
        ? {
            [e.target.id]: e.target.value,
            errors: {
              [e.target.id]: true
            }
          }
        : (e.target.value === "" && e.target.id === "email") ||
          (e.target.id === "email" &&
            !e.target.value.match(
              /^[a-z._A-Z0-9]+@((?:[-a-z0-9]+\.)+[a-z]{2,})$/
            ))
        ? {
            [e.target.id]: e.target.value,
            errors: {
              [e.target.id]: true
            }
          }
        : {
          [e.target.id]: e.target.value,
          errors: {
            [e.target.id]: false
          }
        }
    );
  };
  onFocus = e => {
    this.setState(
      (e.target.value === "" && e.target.id === "message") ||
        (e.target.id === "message" && e.target.value.length < 3)
        ? {
            [e.target.id]: e.target.value,
            errors: {
              [e.target.id]: true
            }
          }
        : (e.target.value === "" && e.target.id === "subject") ||
          (e.target.id === "subject" && e.target.value.length < 3)
        ? {
            [e.target.id]: e.target.value,
            errors: {
              [e.target.id]: true
            }
          }
        : (e.target.value === "" && e.target.id === "phone") ||
          (e.target.id === "phone" && e.target.value.length < 3)
        ? {
            [e.target.id]: e.target.value,
            errors: {
              [e.target.id]: true
            }
          }
        : (e.target.value === "" && e.target.id === "name") ||
          (e.target.id === "name" && e.target.value.length < 3)
        ? {
            [e.target.id]: e.target.value,
            errors: {
              [e.target.id]: true
            }
          }
        : (e.target.value === "" && e.target.id === "email") ||
          (e.target.id === "email" &&
            !e.target.value.match(
              /^[a-z._A-Z0-9]+@((?:[-a-z0-9]+\.)+[a-z]{2,})$/
            ))
        ? {
            [e.target.id]: e.target.value,
            errors: {
              [e.target.id]: true
            }
          }
        : {
            errors: {
              subject: false,
              name: false,
              phone: false,
              email: false,
              message: false,
              accept: false
            }
          }
    );
  };

  onClick = () =>
    this.setState({
      accept: !this.state.accept
    });

  render() {
    const { classes } = this.props;
    const {
      subject,
      name,
      email,
      phone,
      errors,
      message,
      accept,
      recaptchaClass,
      loading,
      classSnackbar,
      response
    } = this.state;
    return (
      <GridContainer>
        <GridItem cs={12} sm={12} md={6}>
          <h2 className={classes.description}>Hellasfon Α.Ε.</h2>
		  <h5 className={classes.description}>Αρ. Γ.Ε.ΜΗ 77124727000</h5>
          <h4 className={classes.description}>Καλωσήρθατε στο CLOUD!</h4>
		  <List>
            <ListItem>
              <ListItemIcon className={classes.iconBig}>
                <i className="fas fa-address-card" />
              </ListItemIcon>
              <ListItemText
                className={classes.description}
                primary="Ιδομενέως 26
                Ηράκλειο, Κρήτη, Ελλάδα"
                secondary="T.K. 71202"
				
              ></ListItemText>
            </ListItem>
            <ListItem>
              <ListItemIcon className={classes.iconBig}>
                <i className="fas fa-phone" />
              </ListItemIcon>
              <a href="tel:801-222-1001">
                <ListItemText
                  className={classes.description}
                  primary="801-222-1001"
                ></ListItemText>
              </a>
            </ListItem>
            <ListItem>
              <ListItemIcon className={classes.iconBig}>
                <i className="fas fa-phone" />
              </ListItemIcon>
              <a href="tel:+302811300307">
                <ListItemText
                  className={classes.description}
                  primary="+302811300307"
                ></ListItemText>
              </a>
            </ListItem>
            <ListItem>
              <ListItemIcon className={classes.iconBig}>
                <i className="fas fa-fax" />
              </ListItemIcon>
              <ListItemText
                className={classes.description}
                primary="+302811300308"
              ></ListItemText>
            </ListItem>
            <ListItem>
              <ListItemIcon className={classes.iconBig}>
                <i className="fas fa-envelope" />
              </ListItemIcon>
              <a href="mailto:support@hellasfon.com">
                <ListItemText
                  className={classes.description}
                  primary="support@hellasfon.com"
                ></ListItemText>
              </a>
            </ListItem>
            <ListItem style={{ color: "#009bff" }}>
              <Fab
                target="_blank"
                href="https://www.facebook.com/hellasfon/"
                color="inherit"
                aria-label="facebook"
                style={{ marginRight: "10px", marginTop: "2px" }}
              >
                <i style={{ fontSize: "25px" }} className="fab fa-facebook" />
              </Fab>
              <Fab
                target="_blank"
                href="https://twitter.com/hellasfon"
                color="inherit"
                aria-label="twitter"
                style={{ marginRight: "10px", marginTop: "2px" }}
              >
                <i style={{ fontSize: "25px" }} className="fab fa-twitter" />
              </Fab>
              <Fab
                target="_blank"
                href="https://www.instagram.com/hellasfonnetworks/"
                color="inherit"
                aria-label="instagram"
                style={{ marginRight: "10px", marginTop: "2px" }}
              >
                <i style={{ fontSize: "25px" }} className="fab fa-instagram" />
              </Fab>
              <Fab
                target="_blank"
                href="https://www.linkedin.com/in/hellasfon-networks-sa-984272173/"
                color="inherit"
                aria-label="linkedin"
                style={{ marginRight: "2px", marginTop: "2px" }}
              >
                <i style={{ fontSize: "25px" }} className="fab fa-linkedin" />
              </Fab>
            </ListItem>
          </List>
        </GridItem>
        <GridItem xs={12} sm={12} md={6}>
          <form onSubmit={this.onSubmit}>

            <GridItem xs={12} sm={12} md={12}>
                <CustomInput
                  error={errors.subject ? true : false}
                  required
                  type="text"
                  labelText="Θέμα *"
                  id="subject"
                  name="subject"
                  value={subject}
                  disabled={loading}
                  onChange={this.onChange}
                  onFocus={this.onFocus}
                  onBlur={this.onFocus}
                  formControlProps={{
                    fullWidth: true
                  }}
                />
              </GridItem>

              <GridItem xs={12} sm={12} md={12}>
                <CustomInput
                  error={errors.name ? true : false}
                  required
                  type="text"
                  labelText="Όνομα και Eπώνυμο *"
                  id="name"
                  name="name"
                  value={name}
                  disabled={loading}
                  onChange={this.onChange}
                  onFocus={this.onFocus}
                  onBlur={this.onFocus}
                  formControlProps={{
                    fullWidth: true
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <CustomInput
                  error={errors.email ? true : false}
                  required
                  type="email"
                  labelText="Email *"
                  id="email"
                  name="email"
                  value={email}
                  onChange={this.onChange}
                  onFocus={this.onFocus}
                  onBlur={this.onFocus}
                  disabled={loading}
                  formControlProps={{
                    fullWidth: true
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <CustomInput
                  error={errors.phone ? true : false}
                  type="text"
                  labelText="Tηλέφωνο επικοινωνίας"
                  id="phone"
                  name="phone"
                  value={phone}
                  disabled={loading}
                  onChange={this.onChange}
                  onFocus={this.onFocus}
                  onBlur={this.onFocus}
                  formControlProps={{
                    fullWidth: true
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
              <CustomInput
                error={errors.message ? true : false}
                required
                type="text"
                labelText="Μήνυμα *"
                id="message"
                name="message"
                value={message}
                onChange={this.onChange}
                onFocus={this.onFocus}
                onBlur={this.onFocus}
                disabled={loading}
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  multiline: true,
                  rows: 5
                }}
              />
              </GridItem>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <FormControlLabel
                    control={
                      <GreenCheckbox
                        required
                        id="accept"
                        name="accept"
                        checked={accept}
                        onClick={this.onClick}
                        onChange={this.onChange}
                        disabled={loading}
                      />
                    }
                    label={
                      <Typography className={classes.labelCheckbox}>
                        Επιβεβαιώνω ότι έχω διαβάσει τη ρήτρα σχετικά με την επεξεργασία δεδομένων προσωπικού χαρακτήρα και συμφωνώ με την επεξεργασία των δεδομένων από την HELLAFON για το σκοπό που είναι απαραίτητο για το χειρισμό του ερωτήματος η την βελτίωση της υπηρεσίας. Έχω ενημερωθεί ότι η παροχή δεδομένων είναι εθελοντική, έχω το δικαίωμα να επιθεωρήσω το περιεχόμενο των δεδομένων και ζητώντας την διόρθωσή τους, ή τη διαγραφή τους.{" "}
                        <a href="/privacy">Πολιτική απορρήτου</a>
                      </Typography>
                    }
                  />
                </GridItem>
              </GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <div className={recaptchaClass}>
                  <ReCAPTCHA
                    size="normal"
                    render="explicit"
                    sitekey="6LeaOscUAAAAAN-jOw2ObPwk120dvuBmPlXWVxx4"
                    onloadCallback={this.onloadCaptcha}
                    verifyCallback={this.checkCaptacha}
                  />
                </div>
              </GridItem>

              <GridContainer className={classes.buttonContainer}>
                <GridItem
                  xs={12}
                  sm={12}
                  md={12}
                  className={classes.textCenter}
                >
                  <Button
                    type="submit"
                    color="primary"
                    disabled={loading}
                    variant="contained"
                  >
                    Αποστολή
                  </Button>
                  {loading && (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  )}
                </GridItem>
              </GridContainer>
          </form>
          <SnackbarContent
            className={classSnackbar}
            aria-describedby="client-snackbar"
            message={response}
          />
        </GridItem>
      </GridContainer>
    );
  }
}

WorkSection.propTypes = {
  classes: PropTypes.object
};

export default withStyles(workStyle)(WorkSection);
