import {
  GET_RETAIL_PLAN_PACKS,
  SELECT_PLAN_PACK,
  CHECK_EMAIL,
  GET_LOCAL_ARES,
  VERIFY_MOBILE,
  REMOVE_ORDER,
  VERIFY_CODE,
  CREATE_ORDER,
  GET_PLANS,
  UPDATE_ALPHA_ORDER,
  CHECK_ORDER,
  GET_TARIFF_RATES,
  APP_LOADING
} from "./types";
import axios from "axios";

export const loadingFn = (loading) => dispatch => {
    dispatch({
      type: APP_LOADING,
      payload: loading
    });
} 

export const getTariffRates = () => async dispatch => {
  const res = await axios.post(
    `https://sip1.easyvoipcall.com/pbxres/api/get_tariff_rates`,
    ''
  );
  dispatch({
    type: GET_TARIFF_RATES,
    payload: res.data
  });
};

export const getPlans = (pattern) => async dispatch => {
  const content = {
    pattern: pattern
  };
  const res = await axios.post(
    `https://sip1.easyvoipcall.com/pbxres/api/get_plans`,
    content,
  );
  dispatch({
    type: GET_PLANS,
    payload: res.data
  });
};
export const getRetailPlanPacks = plan_pack => async dispatch => {
  const id_plan_pack = {
    id_plan_pack: plan_pack
  };
  const res = await axios.post(
    `https://sip1.easyvoipcall.com/pbxres/api/get_retail_plan_packs`,
    id_plan_pack,
  );
  dispatch({
    type: GET_RETAIL_PLAN_PACKS,
    payload: res.data
  });
};

export const selectPlanPack = (id, pattern) => async dispatch => {
  const id_plan_pack = {
    id_plan_pack: id,
    pattern: pattern
  };
  const res = await axios.post(
    `https://sip1.easyvoipcall.com/pbxres/api/get_retail_plan_pack`,
    id_plan_pack,
  );
  dispatch({
    type: SELECT_PLAN_PACK,
    payload: res.data
  });
};

export const checkEmail = email => async dispatch => {
  const check = {
    email: email
  };
  const res = await axios.post(
    `https://sip1.easyvoipcall.com/pbxres/api/check_email_react`,
    check,
  );
  dispatch({
    type: CHECK_EMAIL,
    payload: res.data
  });
};

export const getlocalAreas = () => async dispatch => {
  const res = await axios.post(
    `https://sip1.easyvoipcall.com/pbxres/api/get_local_areas`,
    "",
  );
  dispatch({
    type: GET_LOCAL_ARES,
    payload: res.data
  });
};
export const verifyCode = (
  order_id,
  token,
  code,
  phonemove,
  local_area,
  changePhone
) => async dispatch => {
  const content = {
    id_order: order_id,
    dsfsafw352: token,
    code: code,
    phonemove: phonemove,
    local_area: local_area,
    changePhone: changePhone
  };
  const login = {
    dfa3424: "daDAS!@DFSGHFGJ(*)hGdffser4324765~hfgh"
  };
  let res = await axios.post(
    `https://sip1.easyvoipcall.com/pbxres/api/fsdffgdg670dbmfsfv90cxxiy`,
    login
  );
  const header = {
    Authorization: "Bearer " + res.data.dfa3424
  };
  try {
    res = await axios.post(
      `https://sip1.easyvoipcall.com/pbxres/api/gfsd56hgf45646fgd`,
      content,
      { headers: header }
    );
    dispatch({
      type: VERIFY_CODE,
      payload: res.data
    });
  } catch (e) {
    await axios.post(
      `https://sip1.easyvoipcall.com/pbxres/api/jfg5345hfgh4545b4234234gv`,
      "",
      { headers: header }
    );
    return e;
  }
  await axios.post(
    `https://sip1.easyvoipcall.com/pbxres/api/jfg5345hfgh4545b4234234gv`,
    "",
    { headers: header }
  );
};
export const removeOrder = (order_id, token) => async dispatch => {
  const content = {
    id_order: order_id,
    dsfsafw352: token
  };
  const login = {
    dfa3424: "daDAS!@DFSGHFGJ(*)hGdffser4324765~hfgh"
  };
  let res = await axios.post(
    `https://sip1.easyvoipcall.com/pbxres/api/fsdffgdg670dbmfsfv90cxxiy`,
    login
  );
  const header = {
    Authorization: "Bearer " + res.data.dfa3424
  };
  try {
    res = await axios.post(
      `https://sip1.easyvoipcall.com/pbxres/api/dfasd4234FDSd23`,
      content,
      { headers: header }
    );
    dispatch({
      type: REMOVE_ORDER,
      payload: res.data
    });
  } catch (e) {
    await axios.post(
      `https://sip1.easyvoipcall.com/pbxres/api/jfg5345hfgh4545b4234234gv`,
      "",
      { headers: header }
    );
    return e;
  }
  await axios.post(
    `https://sip1.easyvoipcall.com/pbxres/api/jfg5345hfgh4545b4234234gv`,
    "",
    { headers: header }
  );
};

export const verifyMobile = (
  company,
  vat,
  firstName,
  lastName,
  email,
  phonemobile,
  phone,
  address,
  tk,
  phonemove,
  local_area,
  changePhone,
  retail_plan_pack,
  city,
  aoy
) => async dispatch => {
  const content = {
    company: company,
    vat: vat,
    firstName: firstName,
    lastName: lastName,
    email: email,
    phonemobile: phonemobile,
    phone: phone,
    address: address,
    tk: tk,
    phonemove: phonemove,
    local_area: local_area,
    changePhone: changePhone,
    id_plan_pack: retail_plan_pack.id_plan_pack,
    name: retail_plan_pack.name,
    startup_cost: retail_plan_pack.startup_cost,
    period_cost: retail_plan_pack.period_cost,
    city: city,
    aoy: aoy
  };
  const login = {
    dfa3424: "daDAS!@DFSGHFGJ(*)hGdffser4324765~hfgh"
  };
  let res = await axios.post(
    `https://sip1.easyvoipcall.com/pbxres/api/fsdffgdg670dbmfsfv90cxxiy`,
    login
  );
  const header = {
    Authorization: "Bearer " + res.data.dfa3424
  };
  try {
    res = await axios.post(
      `https://sip1.easyvoipcall.com/pbxres/api/verify_mobile`,
      content,
      { headers: header }
    );
    dispatch({
      type: VERIFY_MOBILE,
      payload: res.data
    });
  } catch (e) {
    await axios.post(
      `https://sip1.easyvoipcall.com/pbxres/api/jfg5345hfgh4545b4234234gv`,
      "",
      { headers: header }
    );
    return e;
  }
  await axios.post(
    `https://sip1.easyvoipcall.com/pbxres/api/jfg5345hfgh4545b4234234gv`,
    "",
    { headers: header }
  );
};
export const checkOrder = (
 order
) => async dispatch => {
  const content = {
    order_id: order
  };
 
  try {
    const res = await axios.post(
      `https://sip1.easyvoipcall.com/pbxres/api/check_order`,
      content,
    );
    dispatch({
      type: CHECK_ORDER,
      payload: res.data
    });
  } catch (e) {
    return e;
  }
 
};
export const updateDirectOrder = (
  plans,
  top_up,
  verify_mobile,
  paymentMethod
) => async dispatch => {
  const content = {
    plans: plans,
    top_up: top_up,
    order_id: verify_mobile.order_id,
    dsfsafw352: verify_mobile.dsfsafw352,
    paymentMethod: paymentMethod
  };
  const login = {
    dfa3424: "daDAS!@DFSGHFGJ(*)hGdffser4324765~hfgh"
  };
  let res = await axios.post(
    `https://sip1.easyvoipcall.com/pbxres/api/fsdffgdg670dbmfsfv90cxxiy`,
    login
  );
  const header = {
    Authorization: "Bearer " + res.data.dfa3424
  };
  try {
    res = await axios.post(
      `https://sip1.easyvoipcall.com/pbxres/api/fsdgfds6345gsx`,
      content,
      { headers: header }
    );
    dispatch({
      type: UPDATE_ALPHA_ORDER,
      payload: res.data
    });
  } catch (e) {
    await axios.post(
      `https://sip1.easyvoipcall.com/pbxres/api/jfg5345hfgh4545b4234234gv`,
      "",
      { headers: header }
    );
    return e;
  }
  await axios.post(
    `https://sip1.easyvoipcall.com/pbxres/api/jfg5345hfgh4545b4234234gv`,
    "",
    { headers: header }
  );
};
export const updateAlphaOrder = (
  plans,
  top_up,
  verify_mobile,
  paymentMethod
) => async dispatch => {
  const content = {
    plans: plans,
    top_up: top_up,
    order_id: verify_mobile.order_id,
    dsfsafw352: verify_mobile.dsfsafw352,
    paymentMethod: paymentMethod
  };
  const login = {
    dfa3424: "daDAS!@DFSGHFGJ(*)hGdffser4324765~hfgh"
  };
  let res = await axios.post(
    `https://sip1.easyvoipcall.com/pbxres/api/fsdffgdg670dbmfsfv90cxxiy`,
    login
  );
  const header = {
    Authorization: "Bearer " + res.data.dfa3424
  };
  try {
    res = await axios.post(
      `https://sip1.easyvoipcall.com/pbxres/api/fsdgfds6345gs`,
      content,
      { headers: header }
    );
    dispatch({
      type: UPDATE_ALPHA_ORDER,
      payload: res.data
    });
  } catch (e) {
    await axios.post(
      `https://sip1.easyvoipcall.com/pbxres/api/jfg5345hfgh4545b4234234gv`,
      "",
      { headers: header }
    );
    return e;
  }
  await axios.post(
    `https://sip1.easyvoipcall.com/pbxres/api/jfg5345hfgh4545b4234234gv`,
    "",
    { headers: header }
  );
};
export const createOrder = (
  plans,
  top_up,
  verify_mobile,
  paymentID,
  paymentToken,
  paymentMethod
) => async dispatch => {
  const content = {
    plans: plans,
    top_up: top_up,
    order_id: verify_mobile.order_id,
    dsfsafw352: verify_mobile.dsfsafw352,
    paymentID: paymentID,
    paymentToken: paymentToken,
    paymentMethod: paymentMethod
  };
  const login = {
    dfa3424: "daDAS!@DFSGHFGJ(*)hGdffser4324765~hfgh"
  };
  let res = await axios.post(
    `https://sip1.easyvoipcall.com/pbxres/api/fsdffgdg670dbmfsfv90cxxiy`,
    login
  );
  const header = {
    Authorization: "Bearer " + res.data.dfa3424
  };
  try {
    res = await axios.post(
      `https://sip1.easyvoipcall.com/pbxres/api/fsd234hgfh567mjhkj97`,
      content,
      { headers: header }
    );
    dispatch({
      type: CREATE_ORDER,
      payload: res.data
    });
  } catch (e) {
    await axios.post(
      `https://sip1.easyvoipcall.com/pbxres/api/jfg5345hfgh4545b4234234gv`,
      "",
      { headers: header }
    );
    return e;
  }
  await axios.post(
    `https://sip1.easyvoipcall.com/pbxres/api/jfg5345hfgh4545b4234234gv`,
    "",
    { headers: header }
  );
};

export const createOrderSIP = (
  plans,
  top_up,
  verify_mobile,
  paymentID,
  paymentToken,
  paymentMethod
) => async dispatch => {
  const content = {
    plans: plans,
    top_up: top_up,
    order_id: verify_mobile.order_id,
    dsfsafw352: verify_mobile.dsfsafw352,
    paymentID: paymentID,
    paymentToken: paymentToken,
    paymentMethod: paymentMethod
  };
  const login = {
    dfa3424: "daDAS!@DFSGHFGJ(*)hGdffser4324765~hfgh"
  };
  let res = await axios.post(
    `https://sip1.easyvoipcall.com/pbxres/api/fsdffgdg670dbmfsfv90cxxiy`,
    login
  );
  const header = {
    Authorization: "Bearer " + res.data.dfa3424
  };
  try {
    res = await axios.post(
      `https://sip1.easyvoipcall.com/pbxres/api/fsd23SS4hgfh567mjhkj97`,
      content,
      { headers: header }
    );
    dispatch({
      type: CREATE_ORDER,
      payload: res.data
    });
  } catch (e) {
    await axios.post(
      `https://sip1.easyvoipcall.com/pbxres/api/jfg5345hfgh4545b4234234gv`,
      "",
      { headers: header }
    );
    return e;
  }
  await axios.post(
    `https://sip1.easyvoipcall.com/pbxres/api/jfg5345hfgh4545b4234234gv`,
    "",
    { headers: header }
  );
};
