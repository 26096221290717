/*eslint-disable*/
import React from "react";
import { Link } from "react-router-dom";

import withStyles from "@material-ui/core/styles/withStyles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
//import Drawer from '@material-ui/core/Drawer';
import Typography from "@material-ui/core/Typography";

import Divider from "@material-ui/core/Divider";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Icon from "@material-ui/core/Icon";
//import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Grid from "@material-ui/core/Grid";

import CustomDropdown from "components/CustomDropdown/CustomDropdown.jsx";

import headerLinksStyle from "assets/jss/material-kit-react/components/headerLinksStyle.jsx";

function HeaderLinks({ ...props }) {
  const { classes } = props;
  const [state, setState] = React.useState({
    top: false,
  });

  const toggleDrawer = (side, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [side]: open });
  };
  const fullList = (side) => (
    <div
      className={classes.fullList}
      role="presentation"
      onClick={toggleDrawer(side, false)}
      onKeyDown={toggleDrawer(side, false)}
    >
      <Typography variant="h6" color="primary" style={{ marginLeft: "15px" }}>
        Κατηγορία
      </Typography>
      <Divider />
      <Link to="/solution/business" className={classes.navLink}>
        <ListItemIcon>
          <Icon className="fa fa-building" />
        </ListItemIcon>
        <ListItemText primary="Επιχείρηση" />
      </Link>
      <Link to="/solution/startup" className={classes.navLink}>
        <ListItemIcon>
          <Icon className="fa fa-rocket" />
        </ListItemIcon>
        <ListItemText primary="Start-ups" />
      </Link>
      <Link to="/solution/individuals" className={classes.navLink}>
        <ListItemIcon>
          <Icon className="fa fa-home" />
        </ListItemIcon>
        <ListItemText primary="Οικίες - Ιδιώτες" />
      </Link>
      <Link to="/solution/callshop" className={classes.navLink}>
        <ListItemIcon>
          <Icon className="fa fa-industry" />
        </ListItemIcon>
        <ListItemText primary="Call Shop" />
      </Link>
      <Link to="/solution/cards" className={classes.navLink}>
        <ListItemIcon>
          <Icon className="fa fa-industry" />
        </ListItemIcon>
        <ListItemText primary="ΧΡΟΝΟ ΚΑΡΤΕΣ" />
      </Link>
      <Typography variant="h6" color="primary" style={{ marginLeft: "15px" }}>
        Eιδικές Κατηγορίες
      </Typography>
      <Divider />
      <Link to="/solution/shippingcomapnies" className={classes.navLink}>
        <ListItemIcon>
          <Icon className="fa fa-ship" />
        </ListItemIcon>
        <ListItemText primary="Ναυτιλιακές εταιρίες" />
      </Link>
      <Link to="/solution/callcenter" className={classes.navLink}>
        <ListItemIcon>
          <Icon className="fa fa-phone-volume" />
        </ListItemIcon>
        <ListItemText primary="Call Center" />
      </Link>
      <Link to="/solution/hotels" className={classes.navLink}>
        <ListItemIcon>
          <Icon className="fa fa-bed" />
        </ListItemIcon>
        <ListItemText primary="Ξενοδοχεία" />
      </Link>
      <Link to="/solution/taxi" className={classes.navLink}>
        <ListItemIcon>
          <Icon className="fa fa-taxi" />
        </ListItemIcon>
        <ListItemText primary="Ραδιοταξί" />
      </Link>

      <Typography variant="h6" color="primary" style={{ marginLeft: "15px" }}>
        ΣΕΝΑΡΙΟ ΧΡΗΣΗΣ
      </Typography>
      <Divider />
      <GridContainer style={{ margin: "0px" }}>
        <Grid item xs={12} sm={12} lg={3}>
          <Link to="/solution/telephonecenter" className={classes.navLink}>
            <ListItemIcon>
              <Icon className="fa fa-industry" />
            </ListItemIcon>
            <ListItemText primary="ΔΕΝ ΕΧΩ ΤΗΛΕΦΩΝΙΚΟ ΚΕΝΤΡΟ" />
          </Link>
        </Grid>
        <Grid item xs={12} sm={12} lg={4}>
          <Link to="/solution/remote" className={classes.navLink}>
            <ListItemIcon>
              <Icon className="fa fa-headphones" />
            </ListItemIcon>
            <ListItemText primary="Υποκαταστηματα / Απομακρυσμενη εργασια" />
          </Link>
          <Link to="/solution/videoconference" className={classes.navLink}>
            <ListItemIcon>
              <Icon className="fa fa-users" />
            </ListItemIcon>
            <ListItemText primary="Επιχειρησεις με αναγκες τηλεδιασκεψης" />
          </Link>
          <Link to="/solution/callnocost" className={classes.navLink}>
            <ListItemIcon>
              <Icon className="fa fa-microphone" />
            </ListItemIcon>
            <ListItemText primary="Καταγραφη κλησεων χωρις κοστος" />
          </Link>
        </Grid>
        <Grid item xs={12} sm={12} lg={3}>
          <Link
            to="/solution/internationalbusiness"
            className={classes.navLink}
          >
            <ListItemIcon>
              <Icon className="fa fa-chart-area" />
            </ListItemIcon>
            <ListItemText primary="International Business" />
          </Link>

          <Link to="/solution#12" className={classes.navLink}>
            <ListItemIcon>
              <Icon className="fa fa-euro-sign" />
            </ListItemIcon>
            <ListItemText primary="Δεν έχω τηλεφωνικo κεντρο" />
          </Link>
          <Link to="/solution/nocallcenter" className={classes.navLink}>
            <ListItemIcon>
              <Icon className="fa fa-window-maximize" />
            </ListItemIcon>
            <ListItemText primary="Υποστήριξη / online πωλήσεις" />
          </Link>
          <Link to="/solution/pcphones" className={classes.navLink}>
            <ListItemIcon>
              <Icon className="fa fa-database" />
            </ListItemIcon>
            <ListItemText primary="Τηλεφωνία με Η/Υ" />
          </Link>
        </Grid>
      </GridContainer>
    </div>
  );
  return (
    <List className={classes.list}>
      <ListItem className={classes.listItem}>
        <CustomDropdown
          noLiPadding
          buttonText="ΥΠΗΡΕΣΙΕΣ"
          buttonProps={{
            className: classes.navLink,
            color: "transparent",
          }}
          dropdownList={[
            <Link to="/retail" className={classes.dropdownLink}>
              Ιδιώτες
            </Link>,
            <Link to="/cloudpbx" className={classes.dropdownLink}>
              Τηλεφώνικά κέντρα
            </Link>,
            <Link to="/siptrunk" className={classes.dropdownLink}>
              Γραμμές Τηλεφώνου & SIP Trunk
            </Link>,
			 
          ]}
        />
      </ListItem>
      {/*
      <ListItem className={classes.listItem}>
        <Link onClick={toggleDrawer('top', true)} className={classes.navLink}>
          ΛΥΣΕΙΣ
        </Link>
        <Drawer anchor="top" open={state.top} onClose={toggleDrawer('top', false)}>
          {fullList('top')}
        </Drawer>
      </ListItem>
*/}
	<ListItem className={classes.listItem}>
        <CustomDropdown
          noLiPadding
          buttonText="ΚΙΝΗΤΗ"
          buttonProps={{
            className: classes.navLink,
            color: "transparent",
          }}
          dropdownList={[
            <Link to="/travelsim" className={classes.dropdownLink}>
              eSIM
            </Link>,
            <Link to="/iotsim" className={classes.dropdownLink}>
              IoT SIM card
            </Link>
          ]}
        />
 </ListItem>
      <ListItem className={classes.listItem}>
        <CustomDropdown
          noLiPadding
          buttonText="ΛΥΣΕΙΣ"
          buttonProps={{
            className: classes.navLink,
            color: "transparent",
          }}
          dropdownList={[
            <Link
              to="/solution/work_from_home"
              className={classes.dropdownLink}
            >
              Τηλεργασία - Τηλεδίασκεψη
            </Link>,
            <Link to="/solution/startup" className={classes.dropdownLink}>
              Start Up Επιχειρήσεις
            </Link>,
			<Link to="/solution/call-inmarsat" className={classes.dropdownLink}>
              Ναυτιλιακές Εταιρίες
            </Link>,
			<Link to="/solution/callcenter" className={classes.dropdownLink}>
              Call Centers
            </Link>,
			<Link to="/solution/eshop" className={classes.dropdownLink}>
              Ηλεκτρονικά Καταστήματα Ε-Shops
            </Link>,
			<Link to="/solution/hotel" className={classes.dropdownLink}>
              Ξενοδοχεία
            </Link>,
          ]}
        />
      </ListItem>
      <ListItem className={classes.listItem}>
      <CustomDropdown
          noLiPadding
          buttonText="ΤΙΜΟΚΑΤΑΛΟΓΟΙ"
          buttonProps={{
            className: classes.navLink,
            color: "transparent",
          }}
          dropdownList={[
            <Link to="/rates" className={classes.navLink}>
            ΧΡΟΝΟΧΡΕΩΣΗ 
        </Link> ,
            <Link to="/didnumbers" className={classes.dropdownLink}>
              Αριθμοί Τηλεφώνου
            </Link>
          ]}
        />
        
      </ListItem>
      <ListItem className={classes.listItem}>
        <CustomDropdown
          noLiPadding
          buttonText="Συχνές ερωτήσεις"
          buttonProps={{
            className: classes.navLink,
            color: "transparent",
          }}
          dropdownList={[
            <Link to="/faq" className={classes.dropdownLink}>
              Ιδιώτες
            </Link>,
            <Link to="/faqoffice" className={classes.dropdownLink}>
              Επιχείρηση - Εταιρίες
            </Link>
          ]}
        />
      </ListItem>
      <ListItem className={classes.listItem}>
        <Link to="/contact" className={classes.navLink}>
          ΕΠΙΚΟΙΝΩΝΙΑ
        </Link>
      </ListItem>
      <ListItem className={classes.listItem}>
        <CustomDropdown
          noLiPadding
          buttonText="MY ACCOUNT"
          buttonProps={{
            className: classes.navLink,
            color: "transparent",
          }}
          dropdownList={[
            <Link
              to=""
              className={classes.dropdownLink}
              onClick={() => {window.location.href = "https://myaccount.hellasfon.com/HF/"}}
            >
              Ιδιώτες
            </Link>,
            <Link
              to=""
              onClick={() => {window.location.href = "https://myaccount.hellasfon.com/vuc4/"}}
              className={classes.dropdownLink}
            >
              Τηλεφωνικά Κέντρα
            </Link>,
            <Link
              to=""
              onClick={() => {window.location.href = "https://myaccount.hellasfon.com/HF/"}}
              className={classes.dropdownLink}
            >
              Γραμμές τηλεφώνου & SIP Trunk
            </Link>,
			<Link
              to=""
              onClick={() => {window.location.href = "https://myaccount.easyclicksms.com/"}}
              className={classes.dropdownLink}
            >
              Αποστολή Mαζικών SMS
            </Link>,
          ]}
        />
      </ListItem>
    </List>
  );
}

export default withStyles(headerLinksStyle)(HeaderLinks);
