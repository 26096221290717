import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import withStyles from "@material-ui/core/styles/withStyles";
import { TinyButton as ScrollUpButton } from "react-scroll-up-button";
import Header from "components/Header/Header.jsx";
import Footer from "components/Footer/Footer.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import HeaderLinks from "components/Header/HeaderLinks.jsx";
import HeaderTopLinks from "components/Header/HeaderTopLinks.jsx";
import Parallax from "components/Parallax/Parallax.jsx";
import CircularProgress from "@material-ui/core/CircularProgress";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import PlansTable from "./Sections/PlansTableSection.jsx";
import Typography from "@material-ui/core/Typography";
import CookieConsent from "react-cookie-consent";
import ListItem from "@material-ui/core/ListItem";
import YouTube from "react-youtube";

import landingPageStyle from "assets/jss/material-kit-react/views/landingPage.jsx";

//import FAQSection from "./Sections/FAQSection.jsx";
import AppSection from "./Sections/AppSectionBusiness.jsx";
import VisaSection from "./Sections/VisaSection.jsx";
//import RateSection from "./Sections/RateSection.jsx";
import ImageSection from "./Sections/ImageSection.jsx";

import PriceTable from "./Sections/PriceTablePBXSection.jsx";

import { connect } from "react-redux";
import { getPBXPlanPacks, getPlans } from "actions/pbxActions";

import DialogAdvertisment from "./DialogAdvertisment";

class CloudPBXPage extends React.Component {
  constructor(props) {
    window.scrollTo(0, 0);
    super(props);
    const { classes } = props;
    this.state = {
      classSnackbar: classes.hideSnackbar,
      response: "",
      value_radio: "device",
      loading_plan_pack: true,
      open: false
    };
  }
  async componentDidMount() {
    await this.props.getPBXPlanPacks();
    this.props.getPlans();
    this.setState({
      loading_plan_pack: false
    });
    setTimeout(() => { this.setState({open: true}); }, 1000);
  }
  handleChange = async e => {
    const { classes } = this.props;
    this.setState({
      classSnackbar: classes.hideSnackbar,
      value_radio: e.target.value,
      loading_plan_pack: true
    });
    await this.props.getPBXPlanPacks(e.target.value);
    this.setState({
      loading_plan_pack: false
    });
    if (this.props.pbx_plan_packs.length === 0) {
      this.setState({
        classSnackbar: classes.infoSnackbar,
        response: "Δεν υπάρχουν προγράμματα προς το παρόν"
      });
    }
  };

  selectPlan = async () => {
    return;
  };
  handleClose = () => {
    this.setState({open: false})
  };

  render() {
    const { classes, pbx_plan_packs, plans, ...rest } = this.props;
    const { loading_plan_pack, classSnackbar, response, open } = this.state;
    return (
      <div>
        <Header
          color="transparent"
          logoUrl="/img/Hellasfon_logo.png"
          topLinks={<HeaderTopLinks />}
          rightLinks={<HeaderLinks />}
          fixed
          changeColorOnScroll={{
            height: 50,
            color: "white"
          }}
          {...rest}
        />
        <Parallax
          filter
          image={require("assets/img/hellasfon_business_header_0_0.jpg")}
        >
          <div className={classes.container}>
          <DialogAdvertisment open={open} handleClose={this.handleClose}/>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
              <h5>
                <strong>EVCOFFICE CLOUD Ψηφιακό Τηλεφωνικό κέντρο!</strong><br />
				<ul>
				  <li>Δωρεάν ψηφιακό τηλεφωνικό κέντρο με 3 άδειες χρήσης / 3 κανάλια φωνής</li>
				  <li><strong>Οικονομία</strong>, <strong>Ευελιξία</strong> και <strong>Aύξηση Παραγωγικότητας</strong>.</li>
                                  <li>Χωρίς έξοδα για συσκευές και εξοπλισμό.</li>
 				  <li>Αδιάλειπτη επικοινωνία με την εφαρμογή EVCOFFICE για το κινητό και τον Η/Υ.</li>
				  <li><strong>Plug and Play</strong> με ONLINE εγγραφή!</li>
				  </ul>
				  <strong>Έτοιμο σε λίγα λεπτά και 3 βήματα!</strong>
                </h5>
                <Button
                  color="primary"
                  size="lg"
                  href="#pricing"
                  rel="noopener noreferrer"
                >
                  Εγγραφή
                </Button>
              </GridItem>
            </GridContainer>
          </div>
        </Parallax>
        <div className={classNames(classes.main, classes.mainRaised)}>
          <h1
            style={{
              marginTop: "0px",
              marginBottom: "30px",
              textAlign: "center",
              backgroundColor: "#005FB4",
              color: "white"
            }}
          >
            HELLASFON Business Telephony
          </h1>
          <div className={classes.container}>
            <GridContainer id="info">
            <GridItem xs={12} sm={12} md={12}>

              </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <ListItem>
                <Typography align="center" component="h4" variant="h4" color="primary">
                Φτιάξε το δικό σου EVCOFFICE CLOUD Τηλεφωνικό κέντρο σε 5 λεπτά και 3 βήματα!
                </Typography>
              </ListItem>
              <ListItem>
                <YouTube className="youtube-video" videoId={"1m0Z58ijE5w"} />
              </ListItem>
              <ListItem>
                <Typography component="h5" variant="body1" color="textPrimary">
                <p></p><strong>Βήμα 1 - ΕΓΓΡΑΦΗ </strong> α) Διάλεξε το πακέτο που σου ταιριάζει.  Ολοκλήρωσε την συνδρομή σου επιλέγοντας οικονομικά προγράμματα χρόνου ομιλίας.<br></br> <strong>Ο χρόνος ομιλίας μοιράζεται μεταξύ ΟΛΩΝ των χρήστων</strong>. Με την ολοκλήρωση εγγραφής θα λάβεις email με τα στοιχεία σύνδεσης σου στο κέντρο.
                <br></br><p></p>
                <strong>Βήμα 2 – ΠΡΟΣΘΕΣΕ ΧΡΗΣΤΕΣ </strong>- Κάνε Login, στο MYACCOUNT και πρόσθεσε τους χρήστες με το email τους.<br></br> Κάθε νέος χρήστης θα λάβει ένα email με τα στοιχεία σύνδεσης του για τον Η/Υ και το κινητό του.<br></br><p></p>
                <strong>Βήμα 3 - ΚΑΤΕΒΑΣΕ την EVCOFFICE εφαρμογή </strong>- Κάθε χρήστης κατεβάζει την <strong>EVCOFFICE</strong> εφαρμογή στο κινητό και συνδέεται χρησιμοποιώντας το όνομα χρήστη και συνθηματικό που του έχει λάβει στο email του.<br></br>
                 <p></p>- Με αυτά τα στοιχεία ο χρήστης συνδέεται και στο MYACCOUNT για απομακρυσμένη εργασία από τον Η/Υ του.<br></br>
		- IP συσκευές γραφείου ? Διαλέξτε συσκευές απο το <a href='https://shop.hellasfon.com/'>HELLAFON E-SHOP</a> και σας τις στέλνουμε έτοιμες για χρήση, προγραμματισμένες για το κέντρο σας!<br></br>
                <br></br>
                
                <strong>
                Όλα τα προγράμματα της HELLASFON είναι ΧΩΡΙΣ ΔΕΣΜΕΥΣΗ και με
                  ΕΓΓΥΗΣΗ ΧΑΜΗΛΟΤΕΡΗΣ ΤΙΜΗΣ.
				  <p></p>
                  </strong>
                </Typography>
				
              </ListItem>
			  <Typography align="center" component="h5" variant="body1" color="textPrimary">
			  <Button align="center"
                  color="info"
                  size="lg"
                  href="/pbx-features"
                  rel="noopener noreferrer"
                > Δείτε όλα τα χαρακτηριστικά του ψηφιακού τηλεφωνικού κέντρου EVCOFFICE
                </Button>
				</Typography>
              </GridItem>
            </GridContainer>
          </div>

          <div className={classes.container}>
            <SnackbarContent
              className={classSnackbar}
              aria-describedby="client-snackbar"
              message={response}
            />
            <div id="pricing">
              {loading_plan_pack ? (
                <GridContainer  justify="center">
                  <CircularProgress size={50} />
                </GridContainer>
              ) : (
                <React.Fragment>
                  <GridContainer style={{marginTop: "0"}}>
                    <GridItem xs={12} sm={12} md={12}>
                      <PriceTable
                        url={"/regbusiness/"}
                        plan_packs={pbx_plan_packs}
                      />
                    </GridItem>

                  </GridContainer>
                <Typography component="h4" variant="h4" color="primary" style={{ marginBottom: "10px"}}>Πρόσθετα Προγράμματα Χρόνου Ομιλίας</Typography>
                <GridContainer spacing={2}>
                  {plans.map(plan => (
                    <PlansTable
                      plan={plan}
                      key={plan.id_plan}
                      selectPlan={this.selectPlan}
                    />
                  ))}
                </GridContainer>

            </React.Fragment>
              )}
            </div>
            <div className={classes.container} style={{ marginTop: "40px" }}>
              <ImageSection
                media="img/hellasfon_evcoffice_app_0.png"
                headText=" Δωρεάν εφαρμογές για τα κινητά ( Android & IOS ) και Η/Υ, για την επιχείρηση !"
                text1={[
                  <strong>1 Γραμμή για κάθε Χρήστη</strong>,
                 " - Δωρεάν κανάλια φωνής και γραμμές για όλους. Όλοι οι χρήστες έχουν την δική τους γραμμή. Εξασφαλίζοντας 100% διαθεσιμότητα σε όλα τα κέντρα EVCOFFICE. 100 χρήστες; 100 γραμμές!",<br/>,
                  <strong>EVCOFFICE APP</strong>,
            " -  Απεριόριστες ενδοεταιρικές ΚΛΗΣΕΙΣ, VIDEO και CHAT για ΠΑΝΤΑ. Μειώστε τα έξοδα κινητής, χρησιμοποιώντας την EVCOFFICE εφαρμογή ακόμα και εκτός γραφείου.",<br/>,
            <strong>Απομακρυσμένη εργασία: Τηλεδιάσκεψη/κανάλια συνεργασίας</strong>,
				    "- Κάθε χρήστης έχει επικοινωνία με όλη την εταιρεία χρησιμοποιώντας βίντεο, μηνύματα και τηλέφωνο απευθείας από τον Η/Υ στο MYACCOUNT. Συνομιλείστε ταυτόχρονα με τρεις ή περισσότερους συνεργάτες και πελάτες σας.",<br/>,
                <strong>Δωρεάν εργαλεία παραγωγικότητας</strong>,
            " -  Click2Talk (C2T), μεταφορά αρχείων, web chat, fax to email, δωρεάν πλατφόρμα καταγραφής κλήσεων, και πολλά άλλα για να βοηθήσουν την επιχείρηση σας να πετύχει στόχους παραγωγικότητας, και οικονομίας.",<br/>,
				]}
              />
            </div>
          </div>
          <AppSection />
          <VisaSection />
          <Footer />
        </div>
        <ScrollUpButton />
        <CookieConsent
            location="bottom"
            buttonText="ΟΚ συμφωνώ"
            cookieName="hellasfonCookie"
            style={{ background: "rgb(1, 120, 226)" }}
            buttonStyle={{ background: "#f50057", color: "white", fontSize: "15px", marginRight: "130px" }}
            expires={150}
        >
            Χρησιμοποιούμε cookies σε αυτόν τον ιστότοπο για να βελτιώσουμε την εμπειρία των χρηστών.{" "}
            <span style={{ fontSize: "12px" }}>
            Κάνοντας κλικ σε οποιονδήποτε σύνδεσμο σε αυτή τη σελίδα δίνετε τη συγκατάθεσή σας για να ορίσουμε cookies.{" "}
            <a style={{color: "white", textDecoration: "underline"}} href="/privacy">Περισσότερες πληροφορίες</a>
            </span>
        </CookieConsent>
      </div>
    );
  }
}

CloudPBXPage.propTypes = {
  classes: PropTypes.object,
  pbx_plan_packs: PropTypes.array.isRequired,
  getPBXPlanPacks: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  pbx_plan_packs: state.pbx_reducer.pbx_plan_packs,
  plans: state.pbx_reducer.plans
});

export default connect(
  mapStateToProps,
  { getPBXPlanPacks, getPlans }
)(withStyles(landingPageStyle)(CloudPBXPage));
