/*!

=========================================================
* Material Kit React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import { container, title } from "assets/jss/material-kit-react.jsx";
import green from "@material-ui/core/colors/green";
import red from "@material-ui/core/colors/red";
import blue from "@material-ui/core/colors/blue";

const landingPageStyle = {
  linkblue: {
    color: "#d8d8d8",
    "&:hover": {
      color: "#ffffff",
    },
  },
  container: {
    zIndex: "12",
    color: "#FFFFFF",
    ...container,
    marginBottom: "20px",
  },
  title: {
    ...title,
    display: "inline-block",
    position: "relative",
    marginTop: "30px",
    minHeight: "32px",
    color: "#FFFFFF",
    textDecoration: "none",
  },
  subtitle: {
    fontSize: "1.313rem",
    maxWidth: "500px",
    margin: "10px auto 0",
  },
  main: {
    background: "#FFFFFF",
    position: "relative",
    zIndex: "3",
  },
  mainRaised: {
    margin: "0px",
    boxShadow:
      "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
  },
  successSnackbar: {
    backgroundColor: green[600],
  },
  infoSnackbar: {
    backgroundColor: blue[600],
  },
  errorSnackbar: {
    backgroundColor: red[600],
  },
  hideSnackbar: {
    display: "none",
  },
};

export default landingPageStyle;
