import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import withStyles from "@material-ui/core/styles/withStyles";
import { TinyButton as ScrollUpButton } from "react-scroll-up-button";
import Typography from "@material-ui/core/Typography";
import Header from "components/Header/Header.jsx";
import Footer from "components/Footer/Footer.jsx";
import HeaderLinks from "components/Header/HeaderLinks.jsx";
import HeaderTopLinks from "components/Header/HeaderTopLinks.jsx";
import landingPageStyle from "assets/jss/material-kit-react/views/landingPage.jsx";
import Parallax from "components/Parallax/Parallax.jsx";

import AppSection from "./Sections/AppSection.jsx";
import VisaSection from "./Sections/VisaSection.jsx";
import CookieConsent from "react-cookie-consent";

class EulaPage extends React.Component {
  constructor(props) {
    window.scrollTo(0, 0);
    super(props);
  }
  render() {
    const { classes, ...rest } = this.props;
    return (
      <div>
        <Header
          topLinks={<HeaderTopLinks />}
          rightLinks={<HeaderLinks />}
          fixed
          logoUrl="/img/hellasfon_logo_white.jpg"
          color="primary"
          changeColorOnScroll={{
            height: 50,
            color: "white"
          }}
          {...rest}
        />
        <Parallax color small />
        <div className={classNames(classes.main, classes.mainRaised)}>
          <h1
            style={{
              textAlign: "center",
              backgroundColor: "#005FB4",
              color: "white"
            }}
          >
            {"Όροι Χρήσης Υπηρεσιών"}
          </h1>
          <div className={classes.container}>
            <h2 style={{ color: "#3f51b5" }}>
              <b>Γενικοί Όροι Χρήσης Υπηρεσιών</b>
            </h2>

            <h2 style={{ color: "#3f51b5" }}>Hellasfon</h2>
            <br />
            <Typography variant="body1" color="textPrimary">
              Οι παρόντες όροι χρήσης περιγράφουν τους γενικούς κανόνες και
              προϋποθέσεις χρήσης των υπηρεσιών που σας προσφέρει η Hellasfon.
              Οι
              <br /> κανόνες αυτοί αφορούν όλες τις υπηρεσίες της Hellasfon ενώ
              οι υπηρεσίες που έχουν ανάγκη από διαφοροποιήσεις ή επιπλέον
              κανόνες έχουν πρόσθετους Ειδικούς Όρους Χρήσης.
              <br />
              <br />
              Η Hellasfon Networks A.E. με Α.Φ.Μ. 094394346 και έδρα Ιδομενέως
              26, Τ.Κ. 71202 Ηράκλειο , Κρήτης. Η Hellasfon είναι αδειοδοτημένος
              <br /> τηλεπικοινωνιακός πάροχος και διατηρεί τις απαραίτητες
              υποδομές και σχετικές διασυνδέσεις με άλλα τηλεπικοινωνιακά δίκτυα
              και παρόχους σταθερής τηλεφωνίας και Internet στην Ελλάδα και στο
              εξωτερικό, για την παροχή των υπηρεσιών Hellasfon.
              <br />
            </Typography>
            <h2 style={{ color: "#3f51b5" }}>
              Πολιτική Ορθής Χρήσης
            </h2>
            <br />
            <Typography variant="body1" color="textPrimary">
            Με σκοπό την αποφυγή συμφόρησης του δικτύου της και την προστασία της ποιότητας των παρεχομένων υπηρεσιών στους πελάτες, 
			η HELLASFON εφαρμόζει Πολιτική Ορθής Χρήσης, η οποία προνοεί ότι τα Προγράμματα, τα οποία περιλαμβάνουν απεριόριστα λεπτά ομιλίας, 
			προσφέρονται μόνο για διαπροσωπική επικοινωνία και μοναδικό σκοπό την ιδιωτική, προσωπική χρήση της υπηρεσίας. 
			Σε καμία περίπτωση δεν επιτρέπεται η χρήση τους με σκοπό το κέρδος ή τη μεταπώληση της υπηρεσίας. Διαπροσωπική επικοινωνία έχει την 
			έννοια της τηλεφωνικής επικοινωνίας ανθρώπου προς άνθρωπο. Επομένως, η απεριόριστη χρήση προσφέρει μέχρι 1500 λεπτά/μήνα ομιλίας 
			και για το πακέτο Business SIP Trunk 10 - 10000 λεπτά/μήνα ομιλίας. Σε περίπτωση παραβίασης, η HELLASFON έχει το δικαίωμα, να εφαρμόσει 
			χρεώσεις βασικού τιμοκαταλόγου για κλήσης που πραγματοποιούνται πέραν του 1500 λεπτά όριου, μετά από σχετική προειδοποίηση του πελάτη, 
			να μεταφέρει τον πελάτη σε άλλο πρόγραμμα, ή/και να αποσυνδέσει προσωρινά ή/και να τερματίσει οριστικά την παρεχόμενη υπηρεσία, χωρίς άλλη προειδοποίηση.
              <br />
            </Typography>
            <h2 style={{ color: "#3f51b5" }}>Αντικείμενο</h2>
            <br />
            <Typography variant="body1" color="textPrimary">
              Οι παρόντες όροι περιγράφουν τη σχέση σας με την Hellasfon για
              όλες τις υπηρεσίες, ενώ η κάθε υπηρεσία που προσφέρεται από την
              Hellasfon μπορεί να έχει επιπλέον Ειδικούς Όρους Χρήσης.
              <br />
            </Typography>

            <h2 style={{ color: "#3f51b5" }}>Υπηρεσίες Hellasfon</h2>
            <br />
            <Typography variant="body1" color="textPrimary">
              Η Hellasfon μπορεί να αλλάζει τα τεχνικά χαρακτηριστικά των
              υπηρεσιών που προσφέρει χωρίς προηγούμενη ενημέρωση σας. Οι
              αλλαγές αυτές γίνονται ώστε οι υπηρεσίες της Hellasfon να
              συμβαδίζουν με τις εκάστοτε τεχνολογικές εξελίξεις ή να
              συμμορφώνονται με τις αποφάσεις των ρυθμιστικών αρχών. Αντικείμενο
              της Hellasfon είναι η παροχή ενός μεγάλου συνόλου καινοτόμων
              τηλεπικοινωνιακών υπηρεσιών και υπηρεσιών περιεχομένου. Οι
              υπηρεσίες Hellasfon περιγράφονται αναλυτικά στο διαδικτυακό τόπο{" "}
              <a href="https://www.hellasfon.com">www.hellasfon.com</a>. Η
              Hellasfon μπορεί επίσης να διορθώσει, βελτιώσει ή και να
              αναβαθμίσει τις υπηρεσίες της και αυτό μπορεί να οδηγήσει στο
              περιορισμό ή ακόμα και στην διακοπή κάποιων υπηρεσιών.
              <br />
            </Typography>

            <h2 style={{ color: "#3f51b5" }}>Τιμοκατάλογοι</h2>
            <br />
            <Typography variant="body1" color="textPrimary">
              Η Hellasfon έχει το δικαίωμα να αλλάζει τους τιμοκαταλόγους της
              χωρίς προειδοποίηση και χωρίς προγενέστερη ενημέρωση σας. Η
              Hellasfon διαθέτει ένα σύνολο από τιμοκαταλόγους οι οποίοι
              εμφανίζονται στο δικτυακό μας τόπο (
              <a href="https://www.Hellasfon.com">www.Hellasfon.com</a>). Η
              Hellasfon έχει την υποχρέωση να αναρτά κατά την εφαρμογή τους,
              όλους τους τιμοκαταλόγους τους στο διαδικτυακό μας τόπο (
              <a href="https://www.Hellasfon.com">www.Hellasfon.com</a>), και να
              χρεώνει με τον εκάστοτε ισχύοντα τιμοκατάλογο της.
              <br />
            </Typography>

            <h2 style={{ color: "#3f51b5" }}>Χρεώσεις και πληρωμές</h2>
            <br />
            <Typography variant="body1" color="textPrimary">
              Για πληρωμές με πιστωτική κάρτα, με paypal, ή με κατάθεση σε
              τραπεζικό λογαριασμό τα νόμιμα παραστατικά εκδίδονται από την
              Hellasfon. Χρεώσεις και πληρωμές υπάρχουν στην ενεργοποίηση του
              επιλεγμένου πακέτου και κατά το «γέμισμα» (top up) του
              λογαριασμού. Η εξόφληση των λογαριασμών του Χρήστη μέσω πιστωτικής
              κάρτας μπορεί να γίνεται είτε εφάπαξ είτε με πάγια εντολή πληρωμής
              με πιστωτική κάρτα. Για την πληρωμή των λογαριασμών του χρήστη με
              πιστωτική κάρτα απαιτείται η συμπλήρωση της σχετικής αίτησης
              παραγγελίας. Ο χρήστης είναι ο μόνος υπεύθυνος για τη σωστή
              καταγραφή των στοιχείων της πιστωτικής του κάρτας. Για τις πάγιες
              εντολές πληρωμής μέσω πιστωτικής κάρτας ο χρήστης χρειάζεται να
              δηλώσει εφάπαξ τα στοιχεία της πιστωτικής κάρτας που θέλει να
              χρεώνεται εφεξής για την εξόφληση των μελλοντικών του λογαριασμών.{" "}
              <br />
              Σε περίπτωση εξόφλησης του λογαριασμού του Χρήστη με πιστωτική
              κάρτα, η εξόφληση θα πραγματοποιείται αυτόματα την ημέρα λήξης του
              με χρέωση της κάρτας που μας έχει δηλώσει.
              <br />
              Σε περίπτωση που για οποιοδήποτε λόγο η Hellasfon πιθανολογεί τη
              μη ορθή χρέωση του συμφωνημένου αντιτίμου σε νόμισμα για κάθε
              υπηρεσία που απαιτείται τέτοιου είδους πληρωμή, Η Hellasfon
              διατηρεί το δικαίωμα να σας ζητήσει να του προσκομίσετε
              αποδεικτικά χρέωσης/εξόφλησης του αντιτίμου αυτού προκειμένου να
              αποδειχθεί η εν λόγω χρέωση ή και καταβολή. Δεν υποστηρίζεται η
              εξαργύρωση του λογαριασμού σας σε νόμιμο χρήμα. Λόγω των
              ιδιαιτεροτήτων που παρουσιάζουν κάποιες υπηρεσίες κατά τη χρήση
              τους, είναι πιθανό να βρεθείτε με χρεωστικό (αρνητικό) υπόλοιπο
              στο λογαριασμό σας. Σε αυτές τις περιπτώσεις την επόμενη φορά που
              θα κάνετε top up το λογαριασμό σας, αυτόματα θα συμψηφιστεί κατά
              αναλογία το χρεωστικό σας υπόλοιπο. Σε περίπτωση που διαπιστώσετε
              οποιοδήποτε λάθος στις χρεώσεις της Hellasfon, θα πρέπει να
              ενημερώσετε το τμήμα εξυπηρέτησης πελατών εντός 30 ημερών. Η
              Hellasfon θα διορθώσει όλα τα λάθη που θα διαπιστώσει ότι
              οφείλονται σε σφάλματα των συστημάτων της.
              <br />
              Με την αποδοχή των όρων Hellasfon αποδέχεστε πέραν των άλλων, να
              σας αποστέλλονται με ηλεκτρονικά μέσα, τιμολόγια, λογαριασμοί και
              αποδείξεις στην δηλωθείσα ηλεκτρονική διεύθυνση όπως ορίζεται από
              την παράγραφο 5 και 6 του άρθρου 18α του Π.Δ. 186/2002
              <br />
            </Typography>

            <h2 style={{ color: "#3f51b5" }}>Αυτόματη Επέκταση Πακέτων</h2>
            <br />
            <Typography variant="body1" color="textPrimary">
              Τα πακέτα απόκτησης υπηρεσιών Hellasfon για αρκετές υπηρεσίες
              λειτουργούν συνδρομητικά και χρεώνονται μηνιαίως. Με αυτόματη
              επέκταση χρεώσης κατά τη λήξη τους. Αυτό σημαίνει ότι τα συστήματα
              της Hellasfon θα επεκτείνουν τα πακέτα αυτά αυτόματα και σύμφωνα
              με την επιλογή σας. Προεπιλεγμένη είναι πάντα η επέκταση στο
              τρέχον πακέτο της υπηρεσίας.
              <br />
            </Typography>

            <h2 style={{ color: "#3f51b5" }}>
              Εμπιστευτικότητα των επικοινωνιών και προστασία προσωπικών
              δεδομένων
            </h2>
            <br />
            <Typography variant="body1" color="textPrimary">
              Η e-mail διεύθυνση που δηλώνετε κατά την εγγραφή σας στο
              διαδικτυακό τόπου της Hellasfon (
              <a href="https://www.Hellasfon.com">www.Hellasfon.com</a>) είναι
              το επίσημο e-mail επικοινωνίας μαζί σας, στο οποίο η Hellasfon θα
              σας αποστέλλει οποιαδήποτε στοιχεία, πληροφορίες, ενημερώσεις
              σχετικά με τις υπηρεσίες που λαμβάνετε από την Hellasfon. Έχετε
              την υποχρέωση να δηλώσετε τα πραγματικά σας στοιχεία κατά την
              εγγραφή σας στις υπηρεσίες της Hellasfon. Τα στοιχεία πρόσβασης
              στις υπηρεσίες της Hellasfon, είτε αυτά έχουν επιλεχθεί από εσάς,
              είτε έχουν αυτόματα δημιουργηθεί από τα συστήματα της Hellasfon
              και σας έχουν κοινοποιηθεί, είναι αυστηρά προσωπικά. Η Hellasfon
              δεν φέρει καμιά ευθύνη για τη χρήση των στοιχείων αυτών από τρίτο
              πρόσωπο, εξουσιοδοτημένο ή μη. Η Hellasfon διατηρεί αρχείο και
              επεξεργάζεται τα προσωπικά σας στοιχεία σύμφωνα με την ισχύουσα
              νομοθεσία. Ανάλογα με την υπηρεσία που χρησιμοποιείτε, μέρος των
              προσωπικών στοιχείων μπορεί να δοθούν σε τρίτους, συνεργάτες της
              Hellasfon, στο βαθμό που αυτό απαιτείται για την παροχή της εν
              λόγω υπηρεσίας. Σε οποιαδήποτε χρονική στιγµή, διατηρείτε το
              δικαίωµα πρόσβασης στα προσωπικά σας δεδομένα για ενημέρωση σας, ή
              και έκφρασης αντίρρησης στην περαιτέρω επεξεργασία των στοιχείων
              σας, σύµφωνα µε την εκάστοτε ισχύουσα νοµοθεσία. Η Hellasfon
              λαμβάνει όλα τα κατάλληλα μέτρα για την ασφάλεια του απορρήτου των
              επικοινωνιών σας και για την προστασία των Προσωπικών σας
              Δεδομένων που έχετε δηλώσει κατά την εγγραφή σας, σύμφωνα με τη
              σχετική νομοθεσία. Η Hellasfon δεν ελέγχει και δεν παρεμβαίνει στο
              περιεχόμενο των επικοινωνιών που λαμβάνουν χώρα μέσω των υπηρεσιών
              του. Το περιεχόμενο της επικοινωνίας είναι αποκλειστικά στην
              ευθύνη των χρηστών.
              <br />
              Η Hellasfon δεν εγγυάται την ασφάλεια δεδομένων που μεταδίδονται
              μέσω δικτύων, στο βαθμό που η προστασία αυτών δεν επιτυγχάνεται με
              τη λήψη πρόσφορων μέτρων ασφάλειας που επιβάλλονται από τη
              νομοθεσία. Η Hellasfon ουδεμία ευθύνη φέρει για:
              <br />
            </Typography>
            <ol>
              <li>
                {" "}
                <Typography variant="body1" color="textPrimary">
                  τη γνησιότητα, ακρίβεια, εμπιστευτικότητα, το θεμιτό ή αθέμιτο
                  χαρακτήρα των δεδομένων (data) που λαμβάνετε ή αποστέλλετε σε
                  τρίτους ή αποθηκεύετε μέσω της
                  <br />
                  πρόσβασής σας στις υπηρεσίες του.
                </Typography>
              </li>
              <li>
                {" "}
                <Typography variant="body1" color="textPrimary">
                  την οποιαδήποτε άμεση ή έμμεση, θετική ή αποθετική, υλική ή μη
                  ζημία ενδεχομένως υποστείτε από την χρήση των υπηρεσιών της
                  Hellasfon
                </Typography>
              </li>
              <li>
                {" "}
                <Typography variant="body1" color="textPrimary">
                  για τυχόν διαφορές που θα προκύψουν μεταξύ χρηστών της
                  Hellasfon ή ανάμεσα σε εσάς και οποιονδήποτε τρίτο και
                  οφείλονται σε μηνύματα, δεδομένα, στοιχεία ή <br />
                  πληροφορίες (data) που διακινούνται μέσω των υπηρεσιών της
                  Hellasfon
                </Typography>
              </li>
            </ol>
            <Typography variant="body1" color="textPrimary">
              Η Hellasfon ενδέχεται να εφαρμόσει άρση του απορρήτου των
              επικοινωνιών σας μόνον εφόσον και στο μέτρο που καλείται να
              εκπληρώσει υποχρέωσή του σύμφωνα με την κείμενη νομοθεσία.
              <br />
            </Typography>

            <h2 style={{ color: "#3f51b5" }}>Ανωτέρα Βία</h2>
            <br />
            <Typography variant="body1" color="textPrimary">
              Η Hellasfon δεν είναι υπεύθυνη για οποιαδήποτε διακοπή,
              καθυστέρηση ή χειροτέρευση της ποιότητας των Υπηρεσιών εξαιτίας
              λόγων, την επέλευση των οποίων δεν μπορούν να ελέγξει, εξαιτίας
              δηλαδή λόγων ανωτέρας βίας. Ως λόγοι ανωτέρας βίας αναφέρονται
              ενδεικτικά και όχι περιοριστικά πόλεμοι (κηρυγμένοι ή μη),
              απεργίες, ατυχήματα, πυρκαγιές, πλημμύρες, καταιγίδες, σεισμοί ή
              άλλα φυσικά φαινόμενα, τρομοκρατικές ενέργειες, δολιοφθορές,
              κυβερνητικές απαγορεύσεις, πράξεις ελληνικών ή κοινοτικών ή άλλων
              αρχών, εμπορικός αποκλεισμός, διακοπή ή βλάβη στο σταθερό δημόσιο
              τηλεπικοινωνιακό δίκτυο ή σε τηλεπικοινωνιακά δίκτυα τρίτων,
              δικαστικές αποφάσεις, εισαγγελικές παραγγελίες, κ.λπ.
              <br />
            </Typography>

            <h2 style={{ color: "#3f51b5" }}>Ποιότητα παρεχόμενων υπηρεσιών</h2>
            <br />
            <Typography variant="body1" color="textPrimary">
              Η ποιότητα των υπηρεσιών της Hellasfon μπορεί να διαφοροποιείται
              ανάλογα με το είδος πρόσβασης στο Internet, με την ταχύτητα της
              σύνδεσης που διαθέτετε και πιθανά με άλλες παραμέτρους εκτός της
              επιρροής της Hellasfon Για την χρήση των υπηρεσιών της Hellasfon
              απαιτείται να έχετε πρόσβαση στο διαδίκτυο. Η Hellasfon καταβάλει
              κάθε δυνατή προσπάθεια ώστε οι υπηρεσίες του να προσφέρονται στη
              μέγιστη ποιότητα, εντούτοις καμιά εγγύηση δεν μπορεί να δοθεί.
              <br />
            </Typography>

            <h2 style={{ color: "#3f51b5" }}>Ισχύς και διάρκεια</h2>
            <br />
            <Typography variant="body1" color="textPrimary">
              Αν για οποιοδήποτε λόγο διαφωνείτε με τους παρόντες όρους, δεν θα
              πρέπει να χρησιμοποιήσετε τις υπηρεσίες της Hellasfon. Οι παρόντες
              όροι χρήσης ισχύουν από τη πρώτη στιγμή χρήσης και για όλη τη
              διάρκεια που χρησιμοποιείτε τις υπηρεσίες της Hellasfon.
              <br />
            </Typography>

            <h2 style={{ color: "#3f51b5" }}>Διακοπή Υπηρεσιών</h2>
            <br />
            <Typography variant="body1" color="textPrimary">
              Σε περίπτωση μη επαρκούς υπολοίπου στο λογαριασμό σας για τη xρήση
              κάποιας από τις υπηρεσίες Hellasfon δεν θα έχετε τη δυνατότητα
              χρήσης της υπηρεσίας έως τη στιγμή που θα «γεμίσετε» το λογαριασμό
              σας.
              <br />
            </Typography>

            <h2 style={{ color: "#3f51b5" }}>Προσωρινή Διακοπή</h2>
            <br />
            <Typography variant="body1" color="textPrimary">
              Η Προσωρινή Διακοπή γίνεται κατόπιν σχετικής γραπτής ειδοποίησης
              προς εσάς, και σύμφωνα με τα προβλεπόμενα στην κείμενη νομοθεσία.
              Η Hellasfon θα διακόψει προσωρινά την πρόσβαση σας στις υπηρεσίες
              σε περίπτωση που έχετε ληξιπρόθεσμες οφειλές (χρεωστικό/αρνητικό
              υπόλοιπο στο λογαριασμό σας): Είναι στη διακριτική ευχέρεια της
              Hellasfon να αποφασίσει μονομερώς σε ποιες από τις μη χρεώσιμες
              υπηρεσίες και για ποιο διάστημα θα συνεχίσετε να έχετε πρόσβαση,
              όταν βρίσκεστε σε Προσωρινή Διακοπή.
              <br />
            </Typography>

            <h2 style={{ color: "#3f51b5" }}>Οριστική Διακοπή</h2>
            <br />

            <ol>
              <li>
                <Typography variant="body1" color="textPrimary">
                  Γίνεται χρήση των υπηρεσιών της Hellasfon για κακόβουλους ή
                  παράνομους σκοπούς Με την επιφύλαξη κάθε νόμιμου δικαιώματός
                  του, Η Hellasfon δικαιούται να προβεί σε μονομερή οριστική
                  διακοπή της παροχής του συνόλου των υπηρεσιών σας σε
                  <br />
                  περίπτωση που:
                </Typography>
              </li>
              <li>
                <Typography variant="body1" color="textPrimary">
                  Έχει διαπιστωθεί απάτη
                </Typography>
              </li>
              <li>
                <Typography variant="body1" color="textPrimary">
                  Παραβιάζονται όροι του παρόντος
                </Typography>
              </li>
              <li>
                <Typography variant="body1" color="textPrimary">
                  Έχετε ληξιπρόθεσμη οφειλή, μετά την πάροδο εξήντα (60) ημερών
                  από την προσωρινή διακοπή της παροχής των υπηρεσιών.
                </Typography>
              </li>
            </ol>
            <br />

            <h2 style={{ color: "#3f51b5" }}>Πρόσθετες υποχρεώσεις</h2>
            <br />

            <ol>
              <li>
                <Typography variant="body1" color="textPrimary">
                  Δεν επιτρέπεται η μεταπώληση των υπηρεσιών της Hellasfon,
                  χωρίς την προηγούμενη έγγραφη άδειά της.
                </Typography>
              </li>
              <li>
                <Typography variant="body1" color="textPrimary">
                  Οφείλετε να ειδοποιείτε άμεσα την Hellasfon σε περίπτωση που
                  αντιληφθείτε:
                  <br />
                  την απώλεια, κλοπή, διαρροή των μέσων που σας επιτρέπουν τη
                  χρήση των υπηρεσιών Hellasfon. <br />
                  τη χρέωση στον προσωπικό σας λογαριασμό που διατηρείτε στην
                  Hellasfon οποιασδήποτε συναλλαγής που έγινε παρά την βούλησή
                  σας τυχόν σφάλμα στην τήρηση του λογαριασμού σας από τη
                  Hellasfon
                </Typography>
              </li>
            </ol>
            <br />

            <h2 style={{ color: "#3f51b5" }}>Πνευματικά Δικαιώματα</h2>
            <br />
            <Typography variant="body1" color="textPrimary">
              Ειδικότερα, το σήμα Hellasfon (λεκτική και οπτική απεικόνιση) όπως
              και τα σήματα των επιμέρους υπηρεσιών της Hellasfon (λεκτικές και
              οπτικές απεικονίσεις) αποτελούν ιδιοκτησία της εταιρείας Hellasfon
              Networks A.E., και η οποία έχει τη νόμιμη χρήση αυτών. Τα σήματα
              αυτά προστατεύονται στο σύνολό τους από τη νομοθεσία περί
              εμπορικών σημάτων. Η Εταιρεία απαγορεύει ρητώς την χρήση των ως
              άνω για οποιονδήποτε λόγο πέραν την απλής και απευθείας ανάγνωσης.
              Το περιεχόμενο του διαδικτυακού τόπου{" "}
              <a href="https://www.Hellasfon.com">www.Hellasfon.com</a> στο
              σύνολό του (πχ πληροφορίες, φωτογραφίες, οπτικές απεικονίσεις,
              λογισμικό κ.λ.π.) αποτελεί πνευματική και βιομηχανική ιδιοκτησία
              που ανήκει αποκλειστικά στην Εταιρεία ή στους προμηθευτές του
              περιεχομένου που διατίθεται από το{" "}
              <a href="https://www.Hellasfon.com">www.Hellasfon.com</a>, και
              υπόκεινται στις εθνικές και διεθνείς διατάξεις περί Πνευματικής
              ιδιοκτησίας. Συνεπώς, κανένα εκ των ανωτέρω δε δύναται να
              αποτελέσει ολικά ή μερικά, αντικείμενο πώλησης, αντιγραφής,
              τροποποίησης, αναπαραγωγής, αναδημοσίευσης μετάδοσης ή διανομής με
              οποιονδήποτε τρόπο.
              <br />
            </Typography>

            <h2 style={{ color: "#3f51b5" }}>
              Εφαρμοστέο Δίκαιο /Επίλυση Διαφορών
            </h2>
            <br />
            <Typography variant="body1" color="textPrimary">
              Οι διαφορές που προκύπτουν κατά την λειτουργία και την ερμηνεία
              του παρόντος, εφόσον δεν επιλύονται φιλικά, θα υπάγονται στην
              αποκλειστική αρμοδιότητα των δικαστηρίων Ηρακλείου , Κρήτης, και
              εφαρμοστέο θα είναι το ελληνικό δίκαιο.
              <br />
            </Typography>

            <h2 style={{ color: "#3f51b5" }}>Ειδικοί Όροι Χρήσης</h2>
            <br />
            <Typography variant="body1" color="textPrimary">
              Η υπηρεσία Hellasfon DID – ( Local numbers ) σας επιτρέπει να
              χρησιμοποιήσετε τηλεφωνικούς αριθμούς του Εθνικού Σχεδίου
              Αριθμοδότησης (ΕΣΑ) σε ένα σύνολο από υπηρεσίες, μεταξύ των
              οποίων: τηλεφωνία VoIP, αποστολή και λήψη fax, κ.α. Hellasfon DID.
              Οι υπηρεσίες αυτές περιγράφονται αναλυτικά στον διαδικτυακό τόπο
              της Hellasfon{" "}
              <a href="https://www.Hellasfon.com">www.Hellasfon.com</a>.
              Παρακάτω θα βρείτε τους ειδικούς όρους που ισχύουν πρόσθετα των
              Γενικών Όρων Χρήσης της Hellasfon, για τα DID ( γεωγραφικός
              αριθμός ).
              <br />
            </Typography>

            <h2 style={{ color: "#3f51b5" }}>Εκχώρηση Αριθμών</h2>
            <br />
            <Typography variant="body1" color="textPrimary">
              Οι τηλεφωνικοί αριθμοί Hellasfon σας εκχωρούνται και πρέπει να
              χρησιμοποιούνται σύμφωνα με τους ισχύοντες κανονισμούς που έχουν
              εκδοθεί από την Ε.Ε.Τ.Τ. (Εθνική Επιτροπή Τηλεπικοινωνιών &
              Ταχυδρομείων). Όταν Η Hellasfon σας εκχωρεί έναν τηλεφωνικό
              αριθμό, έχετε το αποκλειστικό δικαίωμα χρήσης του για όσο διάστημα
              τον χρησιμοποιείτε. Η Hellasfon δεσμεύει τον τηλεφωνικό αριθμό που
              σας έχει εκχωρήσει για μέχρι και έξι (6) μήνες από την διακοπή των
              υπηρεσιών σας, πριν εκχωρήσει τον αριθμό αυτό εκ νέου σε άλλο
              χρήστη. Η παροχή υπηρεσιών με χρήση των αριθμοδοτικών σειρών που
              δεν έχουν ενεργοποιηθεί από το σύνολο των παρόχων
              τηλεπικοινωνιακών δικτύων ή/και υπηρεσιών (π.χ. 801, 800, 825 κοκ)
              εξαρτάται από την ολοκλήρωση των σχετικών διαδικασιών
              ενεργοποίησης από τους εν λόγω παρόχους. Η Hellasfon θα υποβάλει
              εκάστοτε σχετικά αιτήματα διασύνδεσης σύμφωνα με τους όρους και τη
              διαδικασία που προβλέπονται στην υπ΄αρ. 506/37/09 απόφαση της ΕΕΤΤ
              όπως εκάστοτε ισχύει. Η διευκόλυνση της φορητότητας για τη
              μεταφορά ePhone Number σε άλλο δίκτυο, προϋποθέτει να έχετε
              ολοκληρώσει την διαδικασία ταυτοποίησης στοιχείων όπως αυτή
              ορίζεται από τη Hellasfon.
              <br />
            </Typography>

            <h2 style={{ color: "#3f51b5" }}>Γεωγραφικοί Αριθμοί</h2>
            <br />
            <Typography variant="body1" color="textPrimary">
              Ειδικότερα σε ότι αφορά τα Hellasfon DID που είναι γεωγραφικοί
              αριθμοί σύμφωνα με το Ε.Σ.Α., οφείλετε να χρησιμοποιείτε τους
              γεωγραφικούς αριθμούς εντός των γεωγραφικών ορίων που ορίζει ο
              κάθε αριθμός με βάση το αρχικό του πρόθεμα. Δεν επιτρέπεται να
              κάνετε μόνιμη χρήση των αριθμών αυτών έξω από την γεωγραφική
              περιοχή που ανήκουν.
              <br />
            </Typography>

            <h2 style={{ color: "#3f51b5" }}>Φορητότητα Αριθμών</h2>
            <br />
            <Typography variant="body1" color="textPrimary">
              Υποβάλλοντας αίτημα φορητότητας στην Hellasfon, μας παρέχετε
              εξουσιοδότηση ώστε να προβούμε σε όλες τις απαραίτητες ενέργειες
              για την ολοκλήρωση της διαδικασίας για λογαριασµό και επ’ονόµατί
              σας. Το χρονικό διάστηµα για την ολοκλήρωση της διαδικασίας είναι
              αυτό που ορίζεται από την ισχύουσα νοµοθεσία. Μπορείτε να
              αιτηθείτε στην Hellasfon την μεταφορά αριθμού που ήδη έχετε από
              άλλο τηλεπικοινωνιακό πάροχο, προς Η Hellasfon. Τα αιτήματα
              φορητότητας χρεώνονται από το λογαριασμό σας, σύμφωνα με τον
              ισχύοντα τιμοκατάλογο. Μετά την επιτυχημένη ολοκλήρωση της
              φορητότητας του αριθμού σας, αυτός υπόκεινται στους ίδιους κανόνες
              που ισχύουν για τα Hellasfon DID. Σε περίπτωση φορητότητας αριθμού
              προς την Hellasfon θα λαμβάνετε τις υπηρεσίες Hellasfon που έχετε
              επιλέξει και με τα χαρακτηριστικά που περιγράφονται στους ΓΕΝΙΚΟΥΣ
              και ΕΙΔΙΚΟΥΣ ΟΡΟΥΣ που έχετε αποδεχθεί.
              <br />
            </Typography>

            <h2 style={{ color: "#3f51b5" }}>
              Καταχώρηση σε Δημόσια Διατιθέμενους Τηλεφωνικούς Καταλόγους
            </h2>
            <br />
            <Typography variant="body1" color="textPrimary">
              Επιπρόσθετα έχετε το δικαίωμα να ελέγχετε και, αν είναι
              απαραίτητο, να διορθώνετε ή και να ζητάτε τη διαγραφή της σχετικής
              καταχώρησης. Είναι στη δική σας ευχέρεια να καθορίσετε αν θέλετε
              να καταχωρηθεί μέρος ή το σύνολο των αριθμών σας (Hellasfon
              Numbers) καθώς και ποια από τα προσωπικά σας στοιχεία επιθυμείτε
              να συμπεριληφθούν στον δημόσια διατιθέμενο τηλεφωνικό κατάλογο.
              Οποτεδήποτε θελήσετε, διατηρείτε το δικαίωμα να αιτηθείτε στην
              Hellasfon την καταχώρηση του αριθμού σας (Hellasfon Number) σε
              δημόσια διατιθέμενους τηλεφωνικούς καταλόγους. Η διεκπεραίωση
              αιτήματός σας προς Η Hellasfon για την καταχώρηση σας σε Δημόσια
              Διατιθέμενους Τηλεφωνικούς Καταλόγους, προϋποθέτει να έχετε
              ολοκληρώσει την διαδικασία ταυτοποίησης στοιχείων όπως αυτή
              ορίζεται από την Hellasfon και περιγράφεται στο διαδικτυακό μας
              τόπο (<a href="https://www.Hellasfon.com">www.Hellasfon.com</a>)
              <br />
            </Typography>

            <h2 style={{ color: "#3f51b5" }}>Hellasfon VoIP</h2>
            <br />
            <Typography variant="body1" color="textPrimary">
              ΣΗΜΑΝΤΙΚΗ ΠΛΗΡΟΦΟΡΙΑ: Η Hellasfon VoIP είναι υπηρεσία τηλεφωνίας
              μέσω διαδικτύου και συνεπώς η χρήση του εξαρτάται από την σύνδεση
              σας στο διαδίκτυο. Ανάλογα με τον εξοπλισμό σας, η υπηρεσία μπορεί
              να μην λειτουργεί χωρίς ρεύμα. Η υπηρεσία παρέχει την δυνατότητα
              διεκπεραίωσης κλήσεων προς αριθμούς έκτακτης ανάγκης μεταξύ αυτών
              και του αριθμού 112. Λόγω της νομαδικότητας που χαρακτηρίζει την
              υπηρεσία Hellasfon VoIP (δηλαδή μπορείτε να την χρησιμοποιείτε
              περιστασιακά και εκτός της διεύθυνσης από την οποία την
              χρησιμοποιείται μόνιμα), προκειμένου να γίνεται σωστά η
              διεκπεραίωση των κλήσεων προς αριθμούς εκτάκτου ανάγκης οφείλετε
              να ορίζετε κάθε φορά που αλλάζετε θέση την περιοχή στην οποία
              βρίσκεστε. Ειδικότερα σε ότι αφορά εξερχόμενες κλήσεις προς τον
              αριθμό εκτάκτου ανάγκης 112 Η Hellasfon δεν παρέχει την δυνατότητα
              αποστολής για τα στοιχεία θέσης του καλούντος στην Αρμόδια Αρχή.
              <br />
            </Typography>

            <h2 style={{ color: "#3f51b5" }}>Προωθητικές Ενέργειες</h2>
            <br />
            <Typography variant="body1" color="textPrimary">
              Τα δώρα παρέχονται σύμφωνα με τα ειδικά χαρακτηριστικά ή /και
              περιορισμούς που θα περιγράφονται κάθε φορά στο διαδικτυακό τόπο{" "}
              <a href="www.Hellasfon.com">www.Hellasfon.com</a> ή και στο όποιο
              άλλο υλικό προώθησης ( πχ έντυπο κοκ) . Σε καμία περίπτωση το δώρο
              δεν εξαργυρώνεται με χρήματα. Ανά τακτά χρονικά διαστήματα Η
              Hellasfon πραγματοποιεί προωθητικές ενέργειες . Στο πλαίσιο των
              ενεργειών αυτών Η Hellasfon δύναται να διαθέτει υπό μορφή δώρου,
              και για συγκεκριμένο χρονικό διάστημα, τη δυνατότητα δωρεάν
              πρόσβασης σε συγκεκριμένες υπηρεσίες του. Η Hellasfon δύναται να
              αλλάξει κατά την κρίση της τα ειδικά χαρακτηριστικά και όρους με
              τους οποίους παρέχει τα δώρα, χωρίς προηγούμενη ενημέρωσή σας. H
              παροχή οποιουδήποτε δώρου εξαρτάται από τη διαθεσιμότητά του. Η
              Hellasfon μπορεί να μεταθέσει χρονικά τη δυνατότητα απόκτησης του
              δώρου σε περίπτωση εξάντλησης/μη διαθεσιμότητάς του.
              <br />
            </Typography>

            <h2 style={{ color: "#3f51b5" }}>
              Ειδικοί Όροι Χρήσης μη γεωγραφικών αριθμών σειρών
              806,812,825,850,875 παροχής Υπηρεσιών Πολυμεσικής Πληροφόρησης
              (ΥΠΠ)
            </h2>
            <Typography variant="body1" color="textPrimary">
              Απαραίτητη προϋπόθεση για να παράσχετε υπηρεσίες μέσω αυτών των
              αριθμών, είναι να είστε Κάτοχος Γενικής ‘Αδειας από την Εθνική
              Επιτροπή Τηλεπικοινωνιών και Ταχυδρομείων (ΕΕΤΤ) για την παροχή
              ΥΠΠ. Παράλληλα αναλαμβάνετε την ευθύνη να τηρείτε όλες τις
              υποχρεώσεις που προβλέπονται σχετικά στον Κανονισμό Γενικών Αδειών
              της ΕΕΤΤ , στο Εθνικό Σχέδιο Αριθμοδότησης, στον Κανονισμό
              Διαχείρισης Αριθμοδοτικών Πόρων του Εθνικού Σχεδίου Αριθμοδότησης,
              και στον Κανονισμό Παροχής ΥΠΠ όπως ισχύουν εκάστοτε.
              <br />
              Οι αριθμοί αυτοί σας εκχωρούνται προκειμένου να μπορείτε να
              παρέχετε Υπηρεσίες Πολυμεσικής Πληροφόρησης (ΥΠΠ) και να λαμβάνετε
              προμήθεια από τις εισερχόμενες κλήσεις. Διευκρινίζεται ότι μέσω
              των αριθμών αυτών δεν μπορείτε να πραγματοποιήσετε εξερχόμενες
              κλήσεις.
              <br />
              Η Hellasfon διασφαλίζει με κάθε δυνατό τρόπο ότι χρησιμοποιείτε
              τους αριθμούς αυτούς για την παροχή ΥΠΠ σύμφωνα με τα οριζόμενα
              στην κείμενη νομοθεσία καθώς και να παρέχει στην ΕΕΤΤ οποιαδήποτε
              πληροφορία ζητηθεί για τον έλεγχο της υπηρεσίας σας. Στο πλαίσιο
              του ελεγκτικού του ρόλου Η Hellasfon δύναται να σας ζητήσει να
              προσκομίζετε σχετικά στοιχεία. Εφόσον δεν προσκομίσετε τα στοιχεία
              αυτά εντός των χρονικών ορίων που θα σας ζητηθεί ή/και εφόσον
              διαπιστωθεί μη συμμόρφωσή σας με τις υποχρεώσεις που προβλέπει η
              κείμενη νομοθεσία, τότε Η Hellasfon προβαίνει αυτόματα σε διακοπή
              του αριθμού από την οποία παρέχεται η ΥΠΠ.
              <br />
              Απόδοση προμηθειών: Η απόδοση προμηθειών γίνεται σύμφωνα με τους
              όρους και προϋποθέσεις που περιγράφονται στον παρόντα διαδικτυακό
              τόπο (δείτε σχετική ιστοσελίδα εδώ). Στην περίπτωση έκδοσης
              τιμολογίου σύμφωνα με δημοσιευμένους όρους και προϋποθέσεις, Η
              Hellasfon οφείλει να προβεί σε εξόφλησή του εντός 60 ημερών από
              την ημερομηνία έκδοσης του τιμολογίου. Ρητά διευκρινίζεται ότι Η
              Hellasfon δύναται να παρατείνει περεταίρω το συγκεκριμένο χρόνο
              εξόφλησης σε περίπτωση που εκκρεμεί από πλευράς σας η προσκόμιση
              στοιχείων που ενδεχομένως σας έχουν ζητηθεί σχετικά ( όπως πχ
              στοιχεία που να πιστοποιούν ότι πληρείτε τις προϋποθέσεις για την
              παροχή ΥΠΠ ή/και τη συμμόρφωσή σας με τις υποχρεώσεις που
              προβλέπει η κείμενη νομοθεσία για την παροχή ΥΠΠ).
              <br />
            </Typography>

            <h2 style={{ color: "#3f51b5" }}>Προσωπικά δεδομένα</h2>
            <br />
            <Typography variant="body1" color="textPrimary">
              Η εταιρεία έχει το δικαίωμα να διατηρεί αρχείο και να
              επεξεργάζεται τυχόν προσωπικά δεδομένα των επισκεπτών του παρόντος
              δικτυακού τόπου στο μέτρο που αυτό απαιτείται για την σωστή
              λειτουργία και ανάπτυξή της. Ο επισκέπτης διατηρεί το δικαίωμα
              ενημέρωσης ή και αντίρρησης / ανάκλησης στην περαιτέρω επεξεργασία
              σύμφωνα με το άρθρο 13 του Ν. 2472/97 για την προστασία προσωπικών
              δεδομένων.
              <br />
              <br />
              Για περαιτέρω ενημέρωση ή διευκρινίσεις επικοινωνείτε με το Τμήμα
              Υποστήριξης Πελατών. Τηλεφωνικά στο 2811300308, μέσω Εmail στη
              διεύθυνση:
              <a href="mailto:support@hellasfon.com">support@hellasfon.com</a>.
              ΑΣΦΑΛΕΙΑ ΣΥΝΑΛΛΑΓΩΝ
            </Typography>
            <VisaSection />
            <Typography variant="body1" color="textPrimary">
              Όλες οι πληρωμές που πραγματοποιούνται με χρήση κάρτας
              διεκπεραιώνονται μέσω της πλατφόρμας ηλεκτρονικών πληρωμών "Alpha
              e-Commerce" της Alpha Bank και χρησιμοποιεί κρυπτογράφηση TLS 1.2
              με πρωτόκολλο κρυπτογράφησης 128-bit (Secure Sockets Layer - SSL).
              Η κρυπτογράφηση είναι ένας τρόπος κωδικοποίησης της πληροφορίας
              μέχρι αυτή να φτάσει στον ορισμένο αποδέκτη της, ο οποίος θα
              μπορέσει να την αποκωδικοποιήσει με χρήση του κατάλληλου κλειδιού.
              <br />
              <br />
              All payments made using the card are processed through the
              electronic payment platform of "Alpha e-Commerce" of Alpha Bank
              and uses TLS 1.2 encryption protocol encryption with 128-bit
              (Secure Sockets Layer - SSL). Encryption is a way of coding the
              information until it reaches its recipient, who will be able to
              decode it using the appropriate key.
              <br />
            </Typography>
          </div>
          <AppSection />
          <VisaSection />
          <Footer />
        </div>
        <ScrollUpButton />
        <CookieConsent
            location="bottom"
            buttonText="ΟΚ συμφωνώ"
            cookieName="hellasfonCookie"
            style={{ background: "rgb(1, 120, 226)" }}
            buttonStyle={{ background: "#f50057", color: "white", fontSize: "15px", marginRight: "130px" }}
            expires={150}
        >
            Χρησιμοποιούμε cookies σε αυτόν τον ιστότοπο για να βελτιώσουμε την εμπειρία των χρηστών σας.{" "}
            <span style={{ fontSize: "12px" }}>
            Κάνοντας κλικ σε οποιονδήποτε σύνδεσμο σε αυτή τη σελίδα δίνετε τη συγκατάθεσή σας για να ορίσουμε cookies.{" "}
            <a style={{color: "white", textDecoration: "underline"}} href="/privacy">Περισσότερες πληροφορίες</a>
            </span>

        </CookieConsent>
      </div>
    );
  }
}

EulaPage.propTypes = {
  classes: PropTypes.object
};

export default withStyles(landingPageStyle)(EulaPage);
